const storageService = {
  getLocalToken: () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
  },
  getUser: () => {
    return JSON.parse(localStorage.getItem("user"));
  },
  setUser: (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  },
  removeUser: () => {
    localStorage.removeItem("user");
  },
  getUserName: () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.username;
  },
  getUserType: () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.user_type;
  },
};
export default storageService;
