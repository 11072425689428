import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <p align="center">PRIVACY STATEMENT</p>
      <p align="center">----</p>
      <p align="left">SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</p>
      <p align="left">
        When you purchase something from our website, as part of the buying and
        selling process, we collect the personal information you give us such as
        your name, address and email address.
      </p>
      <p align="left">
        When you browse our store, we also automatically receive your computer’s
        internet protocol (IP) address in order to provide us with information
        that helps us learn about your browser and operating system.
      </p>
      <p align="left">
        Email marketing (if applicable): With your permission, we may send you
        emails about our website, new events, newsletters and other updates.
      </p>
      <p align="left">SECTION 2 - CONSENT</p>
      <p align="left">How do you get my consent?</p>
      <p align="left">
        When you provide us with personal information to complete a transaction,
        verify your credit card, place a purchase, we imply that you consent to
        our collecting it and using it for that specific reason only.
      </p>
      <p align="left">
        If we ask for your personal information for a secondary reason, like
        marketing, we will either ask you directly for your expressed consent,
        or provide you with an opportunity to say no.
      </p>
      <p align="left">How do I withdraw my consent?</p>
      <p align="left">
        If after you opt-in, you change your mind, you may withdraw your consent
        for us to contact you, for the continued collection, use or disclosure
        of your information, at anytime, by contacting us at{" "}
        <strong>
          {" "}
          Old Students Association of WMO College, Muttil, Wayanad
        </strong>
      </p>
      <p align="left">
        or mailing us at:&nbsp;
        <a href="mailto:alumni@wmocollege.ac.in">
          <u>
            <strong>alumni@wmocollege.ac.in</strong>
          </u>
        </a>
        <strong> .</strong>
      </p>
      <p align="left">SECTION 3 - DISCLOSURE</p>
      <p align="left">
        We may disclose your personal information if we are required by law to
        do so or if you violate our Terms of Service.
      </p>
      <p align="left">SECTION 4 - PAYMENT</p>
      <p align="left">
        We use Razorpay for processing payments. We/Razorpay do not store your
        card data on their servers. The data is encrypted through the Payment
        Card Industry Data Security Standard (PCI-DSS) when processing payment.
        Your purchase transaction data is only used as long as is necessary to
        complete your purchase transaction. After that is complete, your
        purchase transaction information is not saved.
      </p>
      <p align="left">
        Our payment gateway adheres to the standards set by PCI-DSS as managed
        by the PCI Security Standards Council, which is a joint effort of brands
        like Visa, MasterCard, American Express and Discover.
      </p>
      <p align="left">
        PCI-DSS requirements help ensure the secure handling of credit card
        information by our store and its service providers.
      </p>
      <p align="left">
        For more insight, you may also want to read terms and conditions of
        razorpay on{" "}
        <a href="https://razorpay.com/">
          <u>https://razorpay.com</u>
        </a>
      </p>
      <p align="left">SECTION 5 - THIRD-PARTY SERVICES</p>
      <p align="left">
        In general, the third-party providers used by us will only collect, use
        and disclose your information to the extent necessary to allow them to
        perform the services they provide to us.
      </p>
      <p align="left">
        However, certain third-party service providers, such as payment gateways
        and other payment transaction processors, have their own privacy
        policies in respect to the information we are required to provide to
        them for your purchase-related transactions.
      </p>
      <p align="left">
        For these providers, we recommend that you read their privacy policies
        so you can understand the manner in which your personal information will
        be handled by these providers.
      </p>
      <p align="left">
        In particular, remember that certain providers may be located in or have
        facilities that are located in a different jurisdiction than either you
        or us. So if you elect to proceed with a transaction that involves the
        services of a third-party service provider, then your information may
        become subject to the laws of the jurisdiction(s) in which that service
        provider or its facilities are located.
      </p>
      <p align="left">
        Once you leave our website or are redirected to a third-party website or
        application, you are no longer governed by this Privacy Policy or our
        website’s Terms of Service.
      </p>
      <p align="left">Links</p>
      <p align="left">
        When you click on links on our website, they may direct you away from
        our site. We are not responsible for the privacy practices of other
        sites and encourage you to read their privacy statements.
      </p>
      <p align="left">SECTION 6 - SECURITY</p>
      <p align="left">
        To protect your personal information, we take reasonable precautions and
        follow industry best practices to make sure it is not inappropriately
        lost, misused, accessed, disclosed, altered or destroyed.
      </p>
      <p align="left">SECTION 7 - COOKIES</p>
      <p align="left">
        We use cookies to maintain the session of your user. It is not used to
        personally identify you on other websites.
      </p>
      <p align="left">SECTION 8 - AGE OF CONSENT</p>
      <p align="left">
        By using this site, you represent that you are at least the age of
        majority in your state or province of residence, or that you are the age
        of majority in your state or province of residence and you have given us
        your consent to allow any of your minor dependents to use this site.
      </p>
      <p align="left">SECTION 9 - CHANGES TO THIS PRIVACY POLICY</p>
      <p align="left">
        We reserve the right to modify this privacy policy at any time, so
        please review it frequently. Changes and clarifications will take effect
        immediately upon their posting on the website. If we make material
        changes to this policy, we will notify you here that it has been
        updated, so that you are aware of what information we collect, how we
        use it, and under what circumstances, if any, we use and/or disclose it.
      </p>
      <p align="left">
        If our website is acquired or merged with another organisation, your
        information may be transferred to the new owners so that we may continue
        our service to you.
      </p>
      <p align="left">QUESTIONS AND CONTACT INFORMATION</p>
      <p align="left">
        If you would like to: access, correct, amend or delete any personal
        information we have about you, register a complaint, or simply want more
        information contact our Privacy Compliance Officer at{" "}
        <strong>
          {" "}
          Old Students Association of WMO College, Muttil, Wayanad
        </strong>
      </p>
      <p align="left">
        or mailing us at:&nbsp;
        <a href="mailto:alumni@wmocollege.ac.in">
          <u>
            <strong>alumni@wmocollege.ac.in</strong>
          </u>
        </a>
        <strong> .</strong>
      </p>
      <p align="left">[Re: Privacy Compliance Officer]</p>
      <p align="left">
        [Alumni Association, WMO Arts &amp; Science College, Muttil, Wayanad]
      </p>
      <p align="center">----</p>
    </>
  );
}

export default PrivacyPolicy;
