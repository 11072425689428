import React from "react";
import { Button, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { FilterList as FilterIcon } from "@mui/icons-material";
import adminService from "../../../../service/adminService";
import dataService from "../../../../service/dataService";

export default function TableFilter({ setTableData, filter, setFilter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [departments, setDepartments] = React.useState([]);
  const [programmes, setProgrammes] = React.useState([]);
  const [batches, setBatches] = React.useState([]);
  const [membershipTypes, setMembershipTypes] = React.useState([]);

  React.useEffect(() => {
    dataService.getDepartments().then((res) => {
      if (res.data.status === "success") setDepartments(res.data.data);
    });
    dataService.getCourses().then((res) => {
      if (res.data.status === "success") setProgrammes(res.data.data);
    });
    dataService.getMembershipTypes().then((res) => {
      if (res.data.status === "success")
        setMembershipTypes(res.data.data.filter((value, index) => index !== 0));
    });
  }, []);

  //Function to control open and clos event of filter
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Function to handle changes on selection filed
  const handleFetch = (event) => {
    handleClose();
    setTableData(filter);
  };

  const handleChange = (event) => {
    const name = event.target.name,
      value = event.target.value;
    if (name === "department_id") {
      setFilter((prevVal) => ({
        ...prevVal,
        [name]: value,
        course_id: 0,
        batch_id: 0,
      }));
      setBatches([]);
      adminService.getProgrammes(value).then((res) => {
        if (res.data.status === "success") {
          setProgrammes(res.data.data);
        }
      });
    }
    if (name === "course_id") {
      setFilter((prevVal) => ({ ...prevVal, [name]: value, batch_id: 0 }));
      adminService.getBatches(value).then((res) => {
        if (res.data.status === "success") {
          setBatches(res.data.data);
        }
      });
    }
    if (name === "batch_id") {
      setFilter((prevVal) => ({ ...prevVal, [name]: value }));
    }
    if (name === "membership_type_id") {
      setFilter((prevVal) => ({ ...prevVal, [name]: value }));
    }
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <FilterIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disableRipple>
          <TextField
            id="department_id"
            name="department_id"
            select
            label="Department"
            value={filter.department_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="0">All</MenuItem>
            {departments.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <TextField
            id="course_id"
            name="course_id"
            defaultValue={0}
            select
            label="Course"
            value={filter.course_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="0" selected>
              All
            </MenuItem>
            {programmes.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <TextField
            id="batch_id"
            name="batch_id"
            select
            label="Batch"
            value={filter.batch_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="0">All</MenuItem>
            {batches.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <TextField
            id="membership_type_id"
            name="membership_type_id"
            select
            label="Membership Type"
            defaultValue="0"
            value={filter.membership_type_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="0" selected>
              {" "}
              All
            </MenuItem>
            {membershipTypes.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <Button
            variant="contained"
            onClick={handleFetch}
            color="primary"
            fullWidth
          >
            Apply
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
