import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import adminService from "../../../service/adminService";
import ToastContext from "../../../context/ToastContext";
import LoadingContext from "../../../context/LoadingContext";

export default function DepartmentAddDialog({ handleDepartmentAdd }) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState([]);

  const toast = React.useContext(ToastContext);
  const loading = React.useContext(LoadingContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loading.incrementLoading();
    handleClose();
    adminService.addDepartment(name).then((res) => {
      console.log(res.data);
      res.data.status === "success"
        ? toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          })
        : toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
      setName("");
      loading.decrementLoading();
      handleDepartmentAdd();
    });
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <AddCircleOutlineRoundedIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add department</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required={true}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              name="name"
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Add</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
