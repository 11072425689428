import React from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, IconButton, Chip, Box, Typography } from "@mui/material";
import { Delete as DeleteIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import MemberDetailsModel from "../member-details-model/MemberDetailsModel";
import adminService from "../../../service/adminService";
import LoadingContext from "../../../context/LoadingContext";
import AlertContext from "../../../context/AlertContext";
import ToastContext from "../../../context/ToastContext";
import { getComparator, stableSort } from "./Helper";
import { membershipTableStyle } from "./Style";
import EnhancedTableToolbar from "./table-head/ToolBar";
import EnhancedTableHead from "./table-head/TableHead";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly


export default function MembershipTable() {
  const [originalRows, setOriginalRows] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const loading = React.useContext(LoadingContext);
  const alert = React.useContext(AlertContext);
  const toast = React.useContext(ToastContext);

  //state and functions to handle verification modal

  const [memberDetailsModel, setMemberDetailsModel] = React.useState(false);
  const [alumniId, setAlumniId] = React.useState(0);
  const handleModalOpen = () => setMemberDetailsModel(true);
  const handleModalClose = () => setMemberDetailsModel(false);

  const [filter, setFilter] = React.useState({
    department_id: 0,
    course_id: 0,
    batch_id: 0,
    membership_type_id: 0,
  });

  const requestSearch = (searchedVal) => {
    searchedVal = searchedVal.toUpperCase();
    let filteredRows = originalRows.filter((row) => {
      for (let x in row) {
        if (row[x] !== null && row[x] !== undefined) {
          let row_value = row[x].toUpperCase();
          let result = row_value.includes(searchedVal);
          if (result) return result;
        }
      }
      return false;
    });
    setRows(filteredRows);
  };

  const setTableData = (filter = {}) => {
    loading.incrementLoading();
    adminService.getAlumniList(filter).then((response) => {
      if (response.data.status === "success") {
        setOriginalRows(response.data.data);
        setRows(response.data.data);
      } else {
        toast.setValue({ open: true, ...response.data });
      }
      loading.decrementLoading();
    });
  };

  React.useEffect(() => { setTableData(filter); }, []);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  let prop = {
    requestSearch: requestSearch,
    setTableData: setTableData,
    filter: filter,
    setFilter: setFilter,
    rows: rows,
  };

  const handleDeleteActionClick = (alumniId) => {
    alert.handleClose();
    loading.incrementLoading();
    adminService.deleteAlumni(alumniId).then((response) => {
      if (response.data.status === "success") {
        toast.setValue({
          open: true,
          message: response.data.message,
          status: "success",
        });
        adminService.getAlumniList(filter).then((res) => {
          setOriginalRows(res.data.data);
          setRows(res.data.data);
          loading.decrementLoading();
        });
      } else {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: response.data.message,
          status: "error",
        });
      }
    });
  };
  return (
    <>
      <MemberDetailsModel open={memberDetailsModel} alumniId={alumniId} close={handleModalClose} />
      <Paper sx={membershipTableStyle.tableWrapper}>
        <EnhancedTableToolbar {...prop} />
        <TableContainer>
          <Table sx={membershipTableStyle.table} size="medium">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const dob = new Date(row.dob).toLocaleDateString("en-GB");
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="left" id={labelId}>{row.admission_no}</TableCell>
                      <TableCell align="left" id={labelId}>{row.name}</TableCell>
                      <TableCell align="left">{row.gender}</TableCell>
                      <TableCell align="left">{dob}</TableCell>
                      <TableCell align="left">{row.course}</TableCell>
                      <TableCell align="left"><Box sx={membershipTableStyle.batchCell}> {row.batch}</Box></TableCell>
                      <TableCell align="left">{row.mobile}</TableCell>
                      <TableCell align="left">{row.membership_type}</TableCell>
                      <TableCell align="left">
                      {row.membership_expiry_date !== null ? (new Date(row.membership_expiry_date) >= new Date() ? (<Chip label="Active" size="small" color="success"/>) : (<Chip label="Expired" size="small" color="error" />)) : (<Chip label="Active" size="small" color="success" />)}
                      </TableCell>
                      <TableCell align="center" sx={{ padding: 1 }}>
                        <IconButton onClick={() => { setAlumniId(row.id); handleModalOpen()}}><VisibilityIcon /></IconButton>
                        <IconButton
                          onClick={() => {
                            alert.setValue({
                              open: true,
                              status: "warning",
                              message:
                                "Are you sure you want to delete this request?",
                              response: () => {
                                handleDeleteActionClick(row.id);
                              },
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (<TableRow style={{ height: 53 * emptyRows}}><TableCell colSpan={6} /></TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ marginTop: "20px" }}
        />
      </Paper>
    </>
  );
}

