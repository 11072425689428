import React from "react";
import { Routes, Route } from "react-router-dom";
import PageAdminLogin from "./pages/admin/Login/PageAdminLogin";
import PageAdminHome from "./pages/admin/home/PageAdminHome";
import PageTemporaryRegistration from "./pages/public/temp-registration/PageTemporaryRegistration";
import Admin from "./pages/admin/Admin";
import FormControl from "./pages/admin/form-control/FormControl";
import AlumniViewList from "./pages/admin/alumni-view-list/AlumniViewList";
import TransactionList from "./pages/admin/transaction-list/TransactionList";
import AlertContext from "./context/AlertContext";
import LoadingContext from "./context/LoadingContext";
import ToastContext from "./context/ToastContext";
import AlertDialog from "./components/alert-dialog/AlertDialog";
import Toast from "./components/toast/Toast";
import MembershipRequests from "./pages/admin/membership-requests/MembershipRequests";
import ReturnForPayment from "./pages/admin/membership-requests/ReturnForPayment";
import UpgradeMembership from "./pages/public/upgrade-membership/UpgradeMembership";
import Home from "./pages/public/home/Home";
import "./App.css";
import RegistrationForm from "./pages/public/registration/RegistrationForm";
import PageAboutUs from "./pages/public/about-us/PageAboutUs";
import ContactUs from './pages/public/contact-us/ContactUs'
import PageContactEdit from "./pages/admin/contact-us-edit/PageContactEdit";
import pages from "./constants/pages";
import Album from "./pages/public/album/Album";
import Public from "./pages/public/Public";
import EditAlumniProfile from "./pages/alumni/edit-profile/EditAlumniProfile";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import NotFound from "./pages/404";
import Splash from "./components/splash-screen/Splash";


function App() {
  //To handle alert
  const [alert, setAlert] = React.useState({
    open: false,
    title: "",
    status: "",
    message: "",
    response: () => { },
    isCancellable: true
  });
  // To handle close
  const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      status: "",
      message: "",
      response: () => { },
      isCancellable: true
    });
  };
  //To set data
  const handleAlertStateChange = (value) => {
    if (!value.hasOwnProperty('isCancellable'))
      value.isCancellable = true;
    setAlert({ ...value });
  };

  //To set loading modal
  const [loading, setLoading] = React.useState(false);
  const [, setLoadingState] = React.useState(0);

  //To handle toast
  const [toast, setToast] = React.useState({
    open: false,
    message: "",
    status: "info",
  });

  const closeToast = () => {
    setToast((value) => ({ ...value, open: false }));
  };

  const handleToastStateChange = (value) => {
    setToast((preVal) => ({ ...preVal, ...value }));
  };

  const incrementLoading = () => {
    setLoadingState((value) => {
      value++;
      if (value > 0) setLoading(true);
      return value;
    });
  };

  const decrementLoading = () => {
    setLoadingState((value) => {
      value--;
      if (value === 0) setLoading(false);
      return value;
    });
  };

  //To check online
  const setOnline = () => {
    var x = document.getElementById("snackbar");
    x.innerHTML = "connecting...";
    setTimeout(() => (x.innerHTML = "online"), 1000);
    setTimeout(function () {
      x.className = x.className.replace("show", "hide");
    }, 3000);
  };
  const setOffline = () => {
    var x = document.getElementById("snackbar");
    x.innerHTML = "You'r Offline";
    x.className = x.className.replace("hide", "show");
  };

  // Register the event listeners
  React.useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);
  }, []);

  // splash screen
  const [splash, setSplash] = React.useState(true);
  const RemoveSplash = () => {
    setSplash(false);
  };
  React.useEffect(() => {
    if (
      document.readyState === "complete" ||
      document.readyState === "loaded" ||
      document.readyState === "interactive"
    ) {
      setTimeout(RemoveSplash, 5000);
    }
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: ".5rem",
          marginLeft: "-.5rem",
        }}
      >
        <div id="snackbar" className="hide"></div>
      </div>
      <div>
        <AlertContext.Provider
          value={{ value: alert, setValue: handleAlertStateChange, handleClose: handleAlertClose }}
        >
          <LoadingContext.Provider
            value={{
              isLoading: loading,
              setLoading,
              incrementLoading,
              decrementLoading,
            }}
          >
            <ToastContext.Provider
              value={{
                value: toast,
                setValue: handleToastStateChange,
                handleClose: closeToast,
              }}
            >
              <ScrollToTop />
              <Routes>
                <Route path={pages.HOME} element={splash ? <Splash /> : <Public />}>
                  <Route path="" element={<Home />} />
                  <Route path={pages.GALLERY} element={<Album />} />
                  <Route path={pages.ABOUT_US} element={<PageAboutUs />} />
                  <Route path={pages.CONTACT_US} element={<ContactUs/>}/>
                  <Route path={pages.NEW_REGISTRATION} element={<RegistrationForm />}/>
                  <Route path={pages.UPGRADE_MEMBERSHIP} element={<UpgradeMembership />}/>
                </Route>
                <Route path={pages.ADMIN} element={<Admin />}>
                  <Route path="" element={<PageAdminHome />} />
                  <Route path={pages.admin.MEMBER_REQUESTS} element={<MembershipRequests />}/>
                  <Route path={pages.admin.RETURN_FOR_PAYMENT} element={<ReturnForPayment />}/>
                  <Route path={pages.admin.FORM_CONTROL} element={<FormControl />}/>
                  <Route path={pages.admin.ALUMNI_LIST} element={<AlumniViewList />}/>
                  <Route path={pages.admin.TRANSACTIONS_LIST} element={<TransactionList />}/>
                  <Route path={pages.admin.ALUMNI_PROFILE} element={<PageContactEdit />}/>
                </Route>
                <Route path={pages.ADMIN_LOGIN} element={<PageAdminLogin />} />
                <Route path={pages.TEMP_REGISTRATION} element={<PageTemporaryRegistration />}/>
                <Route path={pages.EDIT_ALUMNI_PROFILE} element={<EditAlumniProfile />}/>
                <Route path={"*"} element={<NotFound />} />
              </Routes>
              <AlertDialog
                open={alert.open}
                title={alert.title}
                status={alert.status}
                message={alert.message}
                response={alert.response}
                close={handleAlertClose}
                isCancellable={alert.isCancellable}
              />
              <Toast
                open={toast.open}
                message={toast.message}
                status={toast.status}
                close={closeToast}
              />
            </ToastContext.Provider>
          </LoadingContext.Provider>
        </AlertContext.Provider>
      </div>
    </>
  );
}

export default App;
