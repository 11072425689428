import "./PageTemporaryRegistration.css";
import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import registrationService from "../../../service/registrationService";

import AchievementDialog from "../../../components/temporary-registration/achievements/achievement-popup/AchievementDialog";
import OccupationDialog from "../../../components/temporary-registration/occupation/occupation-popup/OccupationDialog";

import MandatoryCard from "../../../components/temporary-registration/mandatory/Mandatory.js";
// import CommunicationCards from "../../../components/temporary-registration/communication/Communication.js";

import AlreadyMemberCard from "../../../components/membership-cards/AlreadyMemberCard";
import BasicCard from "../../../components/membership-cards/BasicCard";
import PremiumCard from "../../../components/membership-cards/PremiumCard";
import LifeTimeCard from "../../../components/membership-cards/LifeTimeCard";

import ToastContext from "../../../context/ToastContext";
import AlertContext from "../../../context/AlertContext";

import PaymentPrompt from "../../../components/payment-prompt/PaymentPrompt";
import Contact from "../../../components/temporary-registration/contacts/Contact";
// import Educations from "../../../components/temporary-registration/education/Educations";
// import Achievements from "../../../components/temporary-registration/achievements/Achievements";
// import Occupation from "../../../components/temporary-registration/occupation/Occupation";
import EducationDialog from "../../../components/temporary-registration/education/education-popup/EducationDialog";
import TermsAndPolicy from "../../../components/terms-and-condition/TermsAndPolicy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Backdrop, Checkbox, CircularProgress, Grid, Typography } from "@mui/material";
export default function PageTemporaryRegistration() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    admission_no: "",
    name: "",
    dob: null,
    gender: "",
    email: "",
    mobile: "",
    whatsapp_no: "",
    batch_id: "",
    course_id: "",
    address_line_1: "",
    address_line_2: "",
    place: "",
    postoffice: "",
    pincode: "",
    district: "",
    state: "",
    country: "",
    communication_address_line_1: "",
    communication_address_line_2: "",
    communication_place: "",
    communication_postoffice: "",
    communication_pincode: "",
    communication_district: "",
    communication_state: "",
    communication_country: "",
    applicant_education: [],
    applicant_achievements: [],
    applicant_occupation: [],
    primary_membership: "",
    secondary_membership: "",
  });

  const [educationOpen, setEducationOpen] = React.useState(false);
  const [achievementOpen, setAchievementOpen] = React.useState(false);
  const [occupationOpen, setOccupationOpen] = React.useState(false);
  const clearFromData = () => {
    setFormData({
      admission_no: "",
      name: "",
      dob: null,
      viewDob:null,
      gender: "",
      email: "",
      mobile: "",
      whatsapp_no: "",
      batch_id: "",
      course_id: "",
      address_line_1: "",
      address_line_2: "",
      place: "",
      postoffice: "",
      pincode: "",
      district: "",
      state: "",
      country: "",
      communication_address_line_1: "",
      communication_address_line_2: "",
      communication_place: "",
      communication_postoffice: "",
      communication_pincode: "",
      communication_district: "",
      communication_state: "",
      communication_country: "",
      applicant_education: [],
      applicant_achievements: [],
      applicant_occupation: [],
      primary_membership: "",
      secondary_membership: "",
    });
    setPrimaryMembershipState({ alreadyMember: false, basicMember: false });
    setSecondaryMembershipState({
      premiumMember: false,
      lifeTimeMember: false,
    });
    const isCommunicationAddressSame = document.getElementById("is_communication_address_same")
    if (isCommunicationAddressSame !== null)
      isCommunicationAddressSame.checked = false;
  };

  const [loading, setLoading] = useState(false);

  const [paymentPrompt, setPaymentPrompt] = useState({
    open: false,
    paymentInfo: {},
    paymentFailureHandler: () => { },
  });

  const [primaryMembershipState, setPrimaryMembershipState] = useState({
    alreadyMember: false,
    basicMember: false,
  });

  const [secondaryMembershipState, setSecondaryMembershipState] = useState({
    premiumMember: false,
    lifeTimeMember: false,
  });

  const handleEducationDialogOpen = (state = true) => {
    setEducationOpen(state);
  };
  const handleAchievementDialogOpen = () => {
    setAchievementOpen(true);
  };
  const handleOccupationDialogOpen = () => {
    setOccupationOpen(true);
  };

  //use context import
  const toast = React.useContext(ToastContext);
  const alert = React.useContext(AlertContext);

  const paymentSuccessHandler = (paymentRes) => {
    paymentRes.email = formData.email;
    registrationService
      .verifyPayment(paymentRes)
      .then((res) => {
        if (res.data.status === "success") {
          alert.setValue({
            open: true,
            message:
              "Payment verified successfully, please wait for admin approval",
            status: res.data.status,
            title: res.data.message,
            response: () => {
              alert.handleClose();
              setLoading(false);
            },
            isCancellable: false
          });
          clearFromData();
        } else {
          alert.setValue({
            open: true,
            message: `Something went wrong,${res.data.data}`,
            status: "error",
            title: res.data.message,
            response: () => {
              alert.handleClose();
              setLoading(false);
            },
          });
        }
      })
      .catch((err) => {
        alert.setValue({
          open: true,
          message: `something went wrong,${err}`,
          status: "error",
          title: "Payment verification failed",
          response: () => {
            alert.handleClose();
          },
        });
        setLoading(false);
      });
    setPaymentPrompt({
      open: false,
      paymentInfo: {},
      paymentFailureHandler: () => { },
    });
  };

  const paymentFailureHandler = (paymentRes) => {
    setPaymentPrompt({
      open: false,
      paymentInfo: {},
      paymentFailureHandler: () => { },
    });
    alert.setValue({
      open: true,
      message: `${paymentRes.error.description}, Please try again`,
      status: "error",
      title: "Payment Failed",
      response: () => {
        alert.handleClose();
        setLoading(false);
      },
      isCancellable: false
    });
  };
  const paymentModalHandler = {
    ondismiss: () => {
      setPaymentPrompt({
        open: false,
        paymentInfo: {},
        paymentFailureHandler: () => { },
      });
      alert.setValue({
        open: true,
        message: "Please try again",
        status: "error",
        title: "Payment Failed",
        response: () => {
          alert.handleClose();
          setLoading(false);
        },
        isCancellable: false
      });
    },
    handleback: true,
    confirm_close: true,
  };

  const handleFromSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !primaryMembershipState.alreadyMember &&
      !primaryMembershipState.basicMember
    ) {
      toast.setValue({
        open: true,
        message: "Select any Membership Plan",
        status: "error",
      });
      setLoading(false);
      return;
    }
    registrationService
      .submitRegistrationForm(formData)
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.data !== null && res.data.data.payment_info) {
            let paymentInfo = res.data.data.payment_info;
            paymentInfo.handler = paymentSuccessHandler;
            paymentInfo.modal = paymentModalHandler;
            setPaymentPrompt({
              open: true,
              paymentInfo: res.data.data.payment_info,
              paymentFailureHandler: paymentFailureHandler,
            });
          } else {
            alert.setValue({
              open: true,
              message: "Please wait for admin approval",
              status: res.data.status,
              title: res.data.message,
              response: () => {
                alert.handleClose();
                setLoading(false);
              },
              isCancellable: false
            });
            clearFromData();
          }
        } else {
          if (res.data.data !== null && res.data.data.payment_info) {
            let paymentInfo = res.data.data.payment_info;
            paymentInfo.handler = paymentSuccessHandler;
            paymentInfo.modal = paymentModalHandler;
            setPaymentPrompt({
              open: true,
              paymentInfo: res.data.data.payment_info,
              paymentFailureHandler: paymentFailureHandler,
            });
          } else {
            toast.setValue({
              open: true,
              message: res.data.message,
              status: res.data.status,
            });
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          message: "Something went wrong, please try again",
          status: "error",
        });
        setLoading(false);
      });
  };

  const alreadyMemberCardClick = (event) => {
    setPrimaryMembershipState((preVal) => {
      setFormData((formData) => ({
        ...formData,
        primary_membership: "already_member",
      }));
      return {
        alreadyMember: true,
        basicMember: false,
      };
    });
  };

  const basicMemberCardClick = (event) => {
    setPrimaryMembershipState((preVal) => {
      setFormData((formData) => ({
        ...formData,
        primary_membership: "basic_member",
      }));
      return {
        alreadyMember: false,
        basicMember: true,
      };
    });
  };

  const premiumMemberCardClick = (event) => {
    setSecondaryMembershipState((value) => {
      if (value.premiumMember) {
        setFormData((formData) => ({
          ...formData,
          secondary_membership: "",
        }));
        return { ...value, premiumMember: false };
      }
      setFormData((formData) => ({
        ...formData,
        secondary_membership: "premium_member",
      }));
      return {
        premiumMember: true,
        lifeTimeMember: false,
      };
    });
  };

  const lifeTimeMemberCardClick = (event) => {
    setSecondaryMembershipState((value) => {
      if (value.lifeTimeMember) {
        setFormData((formData) => ({
          ...formData,
          secondary_membership: "",
        }));
        return { ...value, lifeTimeMember: false };
      }
      setFormData((formData) => ({
        ...formData,
        secondary_membership: "lifetime_member",
      }));
      return {
        premiumMember: false,
        lifeTimeMember: true,
      };
    });
  };
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="caption">Please do not press the reload button or back button</Typography>
        <Typography variant="caption">when the transaction process has been started.</Typography>
      </Backdrop>
      {paymentPrompt.open ? (
        <PaymentPrompt
          paymentInfo={paymentPrompt.paymentInfo}
          paymentFailureHandler={paymentFailureHandler}
        />
      ) : null}
      <EducationDialog
        setData={{ formData, setFormData }}
        open={educationOpen}
        close={handleEducationDialogOpen}
      />
      <AchievementDialog
        setData={{ formData, setFormData }}
        open={achievementOpen}
        close={setAchievementOpen}
      />
      <OccupationDialog
        setData={{ formData, setFormData }}
        open={occupationOpen}
        close={setOccupationOpen}
      />
      <div className="container-fluid p-md-5 p-3">
        <div className="row d-flex aligns-items-center justify-content-center">
          <div className="col-md-12 col-12">
            <div className="mt-0">
              <button className="tempForm-back-button" onClick={() => {
                navigate('/');
                window.scrollTo(0, 0)
              }}>
                <span>
                  <ArrowBackIcon fontSize="small" />
                </span>
              </button>
              <Typography style={{ textAlign: "center" }} variant="h4">
                NEW MEMBERSHIP
              </Typography>
            </div>
            <form className="" onSubmit={handleFromSubmit}>
              <MandatoryCard setData={{ formData, setFormData }} />
              {/* <CommunicationCards setData={{ formData, setFormData }} /> */}
              <Contact setData={{ formData, setFormData }} />
              {/* <Educations
                setData={{ formData, setFormData }}
                handleEducationDialogOpen={handleEducationDialogOpen}
              />
              <Achievements
                setData={{ formData, setFormData }}
                handleAchievementDialogOpen={handleAchievementDialogOpen}
              />
              <Occupation
                setData={{ formData, setFormData }}
                handleOccupationDialogOpen={handleOccupationDialogOpen}
              /> */}

              {/* Upgrade */}
              <div className="row border-1 text-center">
                <div className="col-12 col-md-6 pt-2">
                  <b>Select your membership *</b>
                  <Grid container spacing={2} paddingTop={1}>
                    <Grid item xs={6} md={6}>
                      <AlreadyMemberCard
                        state={primaryMembershipState.alreadyMember}
                        onClick={alreadyMemberCardClick}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <BasicCard
                        state={primaryMembershipState.basicMember}
                        onClick={basicMemberCardClick}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="col-12 col-md-6 pt-2">
                  <b>Upgrade your membership</b>
                  <Grid container spacing={2} paddingTop={1}>
                    <Grid item xs={6} md={6}>
                      <PremiumCard
                        state={secondaryMembershipState.premiumMember}
                        onClick={premiumMemberCardClick}
                      />
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <LifeTimeCard
                        state={secondaryMembershipState.lifeTimeMember}
                        onClick={lifeTimeMemberCardClick}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div>
                <Checkbox required />
                By checking, I read and agree to the
                <TermsAndPolicy />
              </div>
              <div className="col-md-12 ms-3 me-3 note d-flex aligns-items-center justify-content-center">
                <b>* </b>Basic Membership: Regular Alumni membership. <br />
              </div>
              <div className="col-md-12 ms-3 me-3 note d-flex aligns-items-center justify-content-center">
                <b>* </b>Premium Membership: provides voting power, Expires in
                three years. <br />
              </div>
              <div className="col-md-12 ms-3 me-3 note d-flex aligns-items-center justify-content-center">
                <b>* </b>Lifetime Membership: provides lifelong alumni
                membership with voting power.
              </div>
              <div className="d-flex aligns-items-center justify-content-center mt-4 mb-4">
                <LoadingButton
                  loading={loading}
                  variant="outlined"
                  color="success"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
