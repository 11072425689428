import { Grid, Typography } from "@mui/material";
import React from "react";
import dataService from "../../../service/dataService";
import "./ContactUs.css";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CallIcon from "@mui/icons-material/Call";

export default function ContactUs() {
  // style for contact us card
  const cardStyle = {
    background: "#ffffff",
    border: "1px solid #e0e0e0",
    height: "384px",
  };
  const uniqueTitle = "WMO Arts and Science College Map Location";
  // use state to store the data from the api
  const [alumniInfoData, setAlumniInfoData] = React.useState({
    email: null,
    phone: null,
    name: null,
    address: null,
  });

  // use effect to fetch the data from the api
  React.useEffect(() => {
    dataService.getAlumniContact().then((res) => {
      setAlumniInfoData(res.data.data);
    });
  }, []);

  return (
    <Grid container marginTop={5} marginBottom={5} justifyContent="center">
      <Grid item md={5} xs={12}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.7140874195193!2d76.11391841480868!3d11.643711291736587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba60b63a4ba721d%3A0x7b99ef53e2a38f52!2sWMO%20Arts%20and%20Science%20College!5e0!3m2!1sen!2sin!4v1657812680701!5m2!1sen!2sin"
          style={{ border: "0", width: "100%", height: "384px" }}
          allowFullScreen
          title={uniqueTitle}
        ></iframe>
      </Grid>
      <Grid item md={5} xs={12} style={cardStyle}>
        <Grid
          container
          justifyContent="center"
          marginTop={5}
          flexDirection={"column"}
        >
          <Grid item>
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="center"
              letterSpacing={3}
            >
              CONTACT US
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              rowGap: ".8rem",
            }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: ".8rem",
                alignItems: "center",
                height: "4rem",
                background: "#f5f5f5",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "15%",
                }}
              >
                <PinDropIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "70%",
                }}
              >
                {alumniInfoData.address ? alumniInfoData.address : ""}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRadius: ".8rem",
                alignItems: "center",
                height: "4rem",
                background: "#f5f5f5",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "15%",
                }}
              >
                <AlternateEmailIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "70%",
                }}
              >
                {alumniInfoData.email ? alumniInfoData.email : ""}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderRadius: ".8rem",
                alignItems: "center",
                height: "4rem",
                background: "#f5f5f5",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "15%",
                }}
              >
                <CallIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "70%",
                }}
              >
                {alumniInfoData.mobile ? "+91 " + alumniInfoData.mobile : ""}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
