import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Stack } from "@mui/material";
import React from "react";
import AchievementDialog from "../temporary-registration/achievements/achievement-popup/AchievementDialog";
function UpdateAchievements(props) {
  const { alumni, setAlumni, setUpdateData } = props;
  const [achievementsOpen, setAchievementsOpen] = React.useState(false);
  const [newAchievements, setNewAchievements] = React.useState({
    applicant_achievements: [],
  });
  const [deleteAchievements, setDeleteAchievements] = React.useState([]);

  React.useEffect(() => {
    setUpdateData({
      type: "add",
      sub_type: "alumni_achievements",
      payload: newAchievements.applicant_achievements,
    });
  }, [newAchievements]);

  const handelDeleteAchievements = (achievements_id) => {
    setAlumni((alumni) => {
      const newList = alumni.alumni_achievements.filter((value, index) => {
        return value.id !== achievements_id;
      });
      return {
        ...alumni,
        alumni_achievements: newList,
      };
    });
    setDeleteAchievements((preVal) => [...preVal, achievements_id]);
  };

  const handleRemoveAchievements = (arrayIndex) => {
    const newList = newAchievements.applicant_achievements.filter(
      (value, index) => {
        return index !== arrayIndex;
      }
    );
    setNewAchievements((preVal) => ({
      ...preVal,
      applicant_achievements: newList,
    }));
  };

  React.useEffect(() => {
    setUpdateData({
      type: "delete",
      sub_type: "alumni_achievements",
      payload: deleteAchievements,
    });
  }, [deleteAchievements]);

  const handleAddButton = () => {
    setAchievementsOpen(true);
  };
  const handleAchievementsDialogClose = () => {
    setAchievementsOpen(false);
  };
  return (
    <>
      <AchievementDialog
        open={achievementsOpen}
        close={handleAchievementsDialogClose}
        setData={{ formData: newAchievements, setFormData: setNewAchievements }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={700}>Achievements Details</Typography>
        <Button onClick={handleAddButton}>Add New</Button>
      </div>
      <Grid container spacing={2}>
        {alumni.alumni_achievements.map((row, index) => {
          let date = new Date(row.date);
          date = date.toLocaleDateString("en-US");
          return (
            <Grid item key={index} xs={12} sm={6}>
              <Card>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Avatar variant="rounded" sx={{ width: 60, height: 60 }}>
                    <EmojiEventsIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Stack spacing={0.5} width={"100%"}>
                    <Typography fontWeight={400}>{row.achievement}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {date}
                    </Typography>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      handelDeleteAchievements(row.id);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
        {newAchievements.applicant_achievements.map((row, index) => {
          let date = new Date(row.date);
          date = date.toLocaleDateString("en-US");
          return (
            <Grid item key={index} xs={12} sm={6}>
              <Card>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Avatar variant="rounded" sx={{ width: 60, height: 60 }}>
                    <EmojiEventsIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Stack spacing={0.5} width={"100%"}>
                    <Typography fontWeight={400}>{row.achievement}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {row.date}
                    </Typography>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      handleRemoveAchievements(index);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default UpdateAchievements;
