export const membershipTableStyle = {
  toolBar: {
    pl: { sm: 0 },
    pr: { xs: 0, sm: 0 },
    gap: "1rem",
  },
  tableTitle: { flex: "1 1 100%" },
  printButton: { background: "#3b5998", pl: 3, pr: 3 },
  searchArea: {
    width: "10rem",
    height: "2.5rem",
    paddingLeft: "1rem",
  },
  tableWrapper: { width: "100%", mb: 2, padding: 3 },
  table: { minWidth: 750 },
  batchCell: {width: '7rem'}
};
