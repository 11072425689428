import api from "./api";
import storageService from "./storageService";

const login = (username, password, user_type = "admin") => {
  return api.post(
    "/auth/login",
    JSON.stringify({
      username,
      password,
      user_type,
    })
  );
};

const logout = () => {
  storageService.removeUser();
  window.location.reload();
};

const authService = {
  login,
  logout,
};

export default authService;
