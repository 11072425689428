import React from 'react'
import './CardDashboard.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
function CardDashboard({ title, description, disable, path, icon }) {
    const navigate = useNavigate()
    function handleClick() {
        navigate('/admin/' + path)
    }
    return (
        <Card sx={{ backgroundColor: '#161F37', color: 'white' }} className={`${disable && "dashboardCardDisabled"}`}>
            <CardActionArea disabled={disable} onClick={handleClick}>
                {/* <CardMedia
                    component="img"
                    height="140"
                    image={white}
                    alt={title}
                /> */}
                <Box justifyContent={'center'} width={'100%'} display={'flex'}>
                    {icon}
                </Box>
                <CardContent sx={{ padding: 0, paddingX: 2, paddingBottom: 1.5 }}>
                    <Typography gutterBottom variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="white">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default CardDashboard