import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Phone } from "@mui/icons-material";
import dataService from "../../service/dataService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function NeedHelpModel() {
  const [open, setOpen] = React.useState(false);
  const [phone, setPhone] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    dataService
      .getAlumniContact()
      .then((res) => {
        setPhone(res.data.data.mobile);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Button variant="text" onClick={handleOpen}>
        Need Help ?
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontStyle: "bold" }}
          >
            Contact
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Phone /> Phone:{phone != null ? phone : "+910000000000"}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
