import React from "react";
import "./HomeGallery.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";

export default function HomeGallery({ displayImages, home }) {
  const navigate = useNavigate();

  //Function to load jquery cdn for working image light box
  function loadTextScript(text) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.innerHTML = text;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  //useEffect to recall the load script for cdn while updating the image array on button click
  React.useEffect(() => {
    const script = `$('.popup-btn').magnificPopup({
      type: "image",
      gallery: {
        enabled: true,
      }
    });`;
    loadTextScript(script);
  }, [displayImages]);

  // custom style for more button
  const moreButtonStyle = {
    marginTop: "1rem",
    backgroundColor: "#3b5998",
  };

  // if home is become true the gallery component return with heading and light gray background otherwise return gallery grid only
  if (home) {
    return (
      <section id="gallery-area" className="section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title mt-md-5 mt-0">
                <h2>Our gallery</h2>
              </div>
            </div>
          </div>
          <ul className="ul" id="album">
            {displayImages.map((photos, index) => {
              return (
                <li key={index} className="li">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    href={photos.thumbnail}
                    className="fancylight popup-btn"
                    data-fancybox-group="light"
                  >
                    <img src={photos.src} className="img" />
                  </a>
                </li>
              );
            })}
          </ul>
          <center>
            <Link to="/albums">
              <Button
                style={moreButtonStyle}
                variant="contained"
                onClick={() => navigate("/albums")}
                className="mb-4"
              >
                More &gt;&gt;
              </Button>
            </Link>
          </center>
        </div>
      </section>
    );
  } else {
    return (
      <ul className="ul" id="album">
        {displayImages.map((photos, index) => {
          return (
            <li key={index} className="li">
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
                href={photos.thumbnail}
                className="fancylight popup-btn"
                data-fancybox-group="light"
              >
                <img src={photos.src} className="img" />
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
}

//================================= Animation function =====================================================
function visible() {
  const element = document.getElementById("album");
  if (element != null) {
    let height = element.getBoundingClientRect().height;
    let top = element.getBoundingClientRect().top;
    let bottom = element.getBoundingClientRect().bottom;
    if (top + height >= 0 && height + window.innerHeight >= bottom) {
      element.classList.add("album");
    } else if (top + height <= 0 && height + window.innerHeight <= bottom) {
      element.classList.remove("album");
    }
  }
}

setTimeout(visible, 1000);
window.addEventListener("scroll", visible);
