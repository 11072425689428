import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import authService from "../../service/authService";
import storageService from "../../service/storageService";
import "./AdminNavbar.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/transparent_logo.png";
import LinearProgress from "@mui/material/LinearProgress";
import LoadingContext from "../../context/LoadingContext";
import "./AdminNavbar.css";
import pages from "../../constants/pages";

function AdminNavbar() {
  const navigate = useNavigate();
  const loading = React.useContext(LoadingContext);
  const routes = [
    {
      name: "Dashboard",
      path: pages.ADMIN,
      icon: "",
    },
    {
      name: "Alumni",
      path: "/admin/" + pages.admin.ALUMNI_LIST,
      icon: "",
    },
    {
      name: "Applications",
      path: "/admin/" + pages.admin.MEMBER_REQUESTS,
      icon: "",
    },
    {
      name: "Return for payment",
      path: "/admin/" + pages.admin.RETURN_FOR_PAYMENT,
      icon: "",
    },
    {
      name: " Form control",
      path: "/admin/" + pages.admin.FORM_CONTROL,
      icon: "",
    },
    {
      name: "Edit Profile",
      path: "/admin/" + pages.admin.ALUMNI_PROFILE,
      icon: "",
    },
    {
      name: "Transactions",
      path: "/admin/" + pages.admin.TRANSACTIONS_LIST,
      icon: "",
    },
  ];

  const [linkStyle, setLinkStyle] = useState({
    textDecoration: "none",
    color: "black",
  });
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const user = storageService.getUser();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar position="fixed" className="bg-light">
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ minHeight: "3rem" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <img
              style={{ width: "3rem", padding: ".24rem" }}
              src={Logo}
              alt="LOGO"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="small"
              sx={{ ml: "-.5rem", color: "black" }}
              aria-label="account of current user"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                mt: "15px",
              }}
            >
              {routes.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.path}
                    style={linkStyle}
                    onClick={() => {
                      setLinkStyle({ textDecoration: "none", color: "blue" });
                    }}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            ></Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {routes.map((page, index) => (
              <Link key={index} className="link" to={page.path}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 0, color: "black", display: "block" }}
                >
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, my: 1 }}>
                <Avatar
                  sx={{ height: "2rem", width: "2rem" }}
                  src="/static/images/avatar/1.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "40px" }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Typography textAlign="center" id="name">
                  {user.name}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/");
                }}
              >
                <Typography textAlign="center" id="name">
                  Back To Home
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  authService.logout();
                  navigate(pages.ADMIN_LOGIN);
                }}
              >
                <Typography textAlign="center" id="logout">
                  Log out
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {loading.isLoading ? <LinearProgress /> : null}
    </AppBar>
  );
}

export default AdminNavbar;
