// function for working animation on scroll event

function reveal(name, h) {
  // console.log(name);
  var reveals = document.querySelectorAll(name);
  // console.log(name,reveals)
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top - h;
    var elementVisible = 1;
    // console.log(windowHeight, elementTop);
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

const animation_functions = { reveal };

export default animation_functions;
