import React from "react";
import pic from "../../../assets/img/responsibility/01.png";
import './OurResponsibilities.css'
import animation_functions from '../../../assets/animation-function/OnScrollFunction';

// Responsibility card component 
export default function OurResponsibilities() {
  return (
    <section id="responsibility-area" className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="section-title">
              <h2 className="m-0 mt-md-5">Our Responsibility</h2>
            </div>
          </div>
        </div>
        </div>
        <div
          className="row text-center text-sm-left m-0 d-flex gap-4 p-0"
          style={{justifyContent: "center"}}
        >
          <div className="col-lg-3 col-sm-5 responsibility-card m-0" id='responsibility-card'>
            <div className="single-responsibility" >
              <img src={pic} alt="Responsibility" />
              <h4><b>Scholarship</b></h4>
              <p>
              Alumni of WMO are motivated to help deprived students in the college. Application and criteria of 
              scholarship have been precisely listed out. 
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-5 responsibility-card m-0" id='responsibility-card'>
            <div className="single-responsibility">
              <img src={pic} alt="Responsibility" />
              <h4><b>Help Our College</b></h4>
              <p>
              Innovative ideas and creative plans hail to update the college. Meticulous financial plans and advanced 
              infrastructure need to be ensured
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-5 responsibility-card m-0" id='responsibility-card'>
            <div className="single-responsibility">
              <img src={pic} alt="Responsibility" />
              <h4><b>Build Our Community</b></h4>
              <p>
              It extends to the various governmental and private sectors of society. Alumni association conducts their 
              meetings mostly in the college campus itself. Their studious attempt to organize and execute a community will be 
              internationally acclaimed.
              </p>
            </div>
          </div>
        </div>
      
    </section>
  );
}
;
function toRev(){
  animation_functions.reveal('.responsibility-card',-10)
}
window.addEventListener('scroll',toRev);

