import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dataService from "../../service/dataService";
import adminService from "../../service/adminService";
import { Grid, Card, Typography, Button, Tooltip } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import NeedHelpModel from "../../components/new-registration/NeedHelpModel";
import ButtonAlreadyMember from "./ButtonAlreadyMember";
function PrimaryInfo({ setPage, setAcademicDetails, academicDetails }) {
  const [batches, setBatches] = React.useState([]);
  const [courses, setCourses] = React.useState([]);

  React.useEffect(() => {
    dataService.getCourses().then((res) => {
      setCourses(res.data.data);
    });
    if (academicDetails.course_id) {
      adminService.getBatches(academicDetails.course_id).then((res) => {
        setBatches(res.data.data);
      });
    }
  }, []);

  const handleChange = (event) => {
    let name = event.target.name;
    if (name === "course_id") {
      adminService.getBatches(event.target.value).then((res) => {
        setBatches(res.data.data);
      });
      setAcademicDetails({
        ...academicDetails,
        course_id: event.target.value,
        course: courses.find((n) => n.id === event.target.value).name,
      });
    }
    if (name === "batch_id") {
      setAcademicDetails({
        ...academicDetails,
        batch_id: event.target.value,
        batch: batches.find((item) => item.id === event.target.value).name,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPage(1);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        height={"80vh"}
      // marginTop={10}
      >
        <Card sx={{ padding: "3rem" }} className="shadow">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            rowSpacing={0}
            gap={1}
          >
            <AccountCircleIcon fontSize="large" />
            <Typography variant="h5">Registration</Typography>
            <form onSubmit={handleSubmit}>
              <Grid item>
                <FormControl
                  variant="standard"
                  size="small"
                  sx={{ minWidth: 230 }}
                  required
                >
                  <InputLabel id="demo-simple-select-label">Course</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={academicDetails.course_id}
                    label="Course"
                    name="course_id"
                    onChange={handleChange}
                    sx={{ width: '15rem' }}
                  >
                    {courses.map((course, index) => {
                      return (
                        <MenuItem key={index} value={course.id}>
                          {course.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl
                  variant="standard"
                  size="small"
                  sx={{ minWidth: 230, marginTop: ".5rem" }}
                  required
                >
                  <InputLabel id="demo-simple-select-label">Batch</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={academicDetails.batch_id}
                    label="Batch"
                    name="batch_id"
                    onChange={handleChange}
                    sx={{ width: '15rem' }}
                  >
                    {batches.map((batch, index) => {
                      return (
                        <MenuItem key={index} value={batch.id}>
                          {batch.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginTop={2}
              >
                <Grid item>
                  <Tooltip title="next">
                    <Button type="submit" sx={{ color: "black" }}>
                      Next
                      <DoubleArrowIcon fontSize="large" />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Card>
        <Grid sx={{
          marginTop: "1rem",
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <NeedHelpModel />
          <ButtonAlreadyMember />
        </Grid>
      </Grid>
    </>
  );
}

export default PrimaryInfo;
