import React from "react";
import RequestTable from "../../../components/membership-requests/request-table/RequestTable";

export default function MembershipRequests() {
  return (
    <div className="container-fluid p-4">
        <RequestTable />
    </div>
  );
}
