import React, { useEffect, useState } from 'react'
import './HistoryArea.css'
import HistoryCardLeft from '../history-card-left/HistoryCardLeft';
import HistoryCardRight from '../history-card-right/HistoryCardRight';
import alumniMeet from '../../../assets/img/about-us/alumni-meet.jpg';
import specialAlumniMeet from '../../../assets/img/about-us/special-alumni-meet.jpg';
import alumniContributions from '../../../assets/img/about-us/alumni-contributions.jpg';
import honouringFunctions from '../../../assets/img/about-us/honouring-functions.jpg';
import otherPrograms from '../../../assets/img/about-us/other-programs.jpg';

function HistoryArea() {
  const [data, setData] = useState();
  useEffect(() => {
    setData([
      {
        id: 1,
        picture: alumniMeet,
        title: 'Alumni Meet',
        story: "Each departments conducts alumni meet in college. They gather together under the department alumni coordinators. They find a space to recollect their memories of college. They plan and execute various beneficial program for deprived students in the college. Highly enthusiastic to maintain cordial relationship among teachers and non teaching staff. Family alumni meets also conduct in outdoor space. Students gather here with family and enhance their friendship each other. They develop a sense of unity and integrity among themselves which beneficial to society as a whole. Each department encourages old students to interact with ongoing batches of students. It ushers the bondage of students to the college and department as well.",
      },
      {
        id: 2,
        picture: specialAlumniMeet,
        title: 'Special Alumni Meet',
        story: "There are some special alumni meets conducted in college. These gatherings aim to colour the bondage of various professions and careers. College Alumni association initiated Police alumni meet on 29- September 2022. Alumnus from the police department gather and recollect the delightful days in the college. Alumni association honoured police medal holders for their uncompromised service to the nation.\nThere was a Union Alumni meet conducted by college alumni association on 14-12-2016. The program was inaugurated by former chief minister of Kerala, Shri Oomman Chandy. It was a marvellous session in terms of students’ involvement. Union members from different batches made their way to convene different ideas of old students. They planned to execute societal beneficial program. It has been also decided to serve different scholarship to the deserved students in the college.",
      },
      {
        id: 3,
        picture: alumniContributions,
        title: 'Alumni Contributions',
        story: <ol>
          <li>Distribution of study aid kit to needy students through edu support</li>
          <li>Alumni association contributed water purifier on 27-08-2015</li>
          <li>Help Desk for students inaugurated by district panchayath president Mr. N. K Rasheed</li>
          <li>Donated podium and Television the Arabic department</li>
          <li>Distribution of food kit to flood affected victims</li>
          <li>Donated books to the department of English</li>
          <li>Honoured students achievers from the selected batches</li>
          <li>Honoured senior teachers and teachers archivers</li>
        </ol>,
      },
      {
        id: 4,
        picture: honouringFunctions,
        title: 'Honouring functions',
        story: <ol>
          <li>Honoured rank holders and toppers on 27-10-2014 by honourable MLA Mr K.M Shaji</li>
          <li>Farewell functions of retired teachers Dr. U. Saidalavi, Ms M. Bishara and Dr Saidlavai </li>
          <li>Honoring Alumnies who made their bright career like T Sharafudheen, P A Kasim (Winners of chief minister medal)</li>
          <li>Honoured Rahul Gandhi (Member of Parliament, Wayanad Constituency) on 08-06-2019 at Wayanad collectorate  </li>
        </ol>,
      },
      {
        id: 5,
        picture: otherPrograms,
        title: 'Other Programs',
        story: <ol>
          <li>Inauguration of Alumni office and help desk by Balakrishnan MLA</li>
          <li>Awareness program “she-2014” in association with women cell inaugurated by Rosakutty teacher (President, Kerala women)</li>
          <li>Bus Stop inauguration in association with PTA</li>
          <li>Honoured senior teachers in connection with teachers day on 05-09-2021.</li>
          <li>Alumni volunteers involved flood rehabilitation activities</li>
          <li>Awareness program of civil services examination</li>
        </ol>,
      }
    ])
  }, []);
  return (
    <section id='history-area'>
      <div className="container" style={{ whiteSpace: 'break-spaces' }}>
        {data && data.map((item, index) => {
          if (index % 2 === 0) {
            return <HistoryCardLeft
              key={index}
              year={item.year}
              picture={item.picture}
              title={item.title}
              story={item.story}
            />
          } else {
            return <HistoryCardRight key={index}
              year={item.year}
              picture={item.picture}
              title={item.title}
              story={item.story}
            />
          }

        })}
      </div>
    </section>
  )
}

export default HistoryArea