import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import adminService from "../../../service/adminService";
import MenuItem from "@mui/material/MenuItem";
import ToastContext from "../../../context/ToastContext";

function ProgrammeAddDialog({ departmentId, handleProgrammeAdd }) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState([]);
  const [programmeType, setProgrammeType] = React.useState("");
  const toast=React.useContext(ToastContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    adminService.addProgramme(name, departmentId, programmeType).then((res) => {
      console.log(res.data);
      res.data.status === "success"
        ? toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          })
        : toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
      setName("");
      setProgrammeType("");
      handleClose();
      handleProgrammeAdd();
    });
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <AddCircleOutlineRoundedIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Programme</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required={true}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              name="name"
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
            <TextField
              id="outlined-select-currency"
              select
              required
              label="Select"
              fullWidth
              size="small"
              margin="dense"
              variant="outlined"
              value={programmeType}
              onChange={(event) => {
                setProgrammeType(event.target.value);
              }}
            >
              <MenuItem value="Aided">Aided</MenuItem>
              <MenuItem value="SF">SF</MenuItem>
            </TextField>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Add</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ProgrammeAddDialog;
