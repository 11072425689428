import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { CardMembership, Collections, } from "@mui/icons-material";
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import wmoTransparentLogo from "../../../assets/logos/transparent_logo.png";
import { Grid, Skeleton } from "@mui/material";
import constants from "../../../constants/pages";
import "./Navbar.css";
import React from "react";

// app bar component for user
export default function Navbar() {
  const [currentTab, setCurrentTab] = React.useState(window.location.pathname);
  const [logo, setLogo] = React.useState({ complete: false });
  const navigate = useNavigate();

  //To select active Tab
  const loadSetTab = () => setCurrentTab(window.location.pathname);

  React.useEffect(() => {
    loadSetTab();
  });
  React.useEffect(() => {
    if (currentTab === constants.HOME) {
      const tab1 = window.document.getElementById("item1");
      const tab2 = window.document.getElementById("item2");
      const tab3 = window.document.getElementById("item3");
      const tab4 = window.document.getElementById("item4");
      const tab5 = window.document.getElementById("item5");
      tab1.classList.add("active");
      tab2.classList.remove("active");
      tab3.classList.remove("active");
      tab4.classList.remove("active");
      tab5.classList.remove("active");
    } else if (currentTab === constants.GALLERY) {
      const tab1 = window.document.getElementById("item1");
      const tab2 = window.document.getElementById("item2");
      const tab3 = window.document.getElementById("item3");
      const tab4 = window.document.getElementById("item4");
      const tab5 = window.document.getElementById("item5");
      tab2.classList.add("active");
      tab1.classList.remove("active");
      tab3.classList.remove("active");
      tab4.classList.remove("active");
      tab5.classList.remove("active");
    } else if (currentTab === constants.ABOUT_US) {
      const tab1 = window.document.getElementById("item1");
      const tab2 = window.document.getElementById("item2");
      const tab3 = window.document.getElementById("item3");
      const tab4 = window.document.getElementById("item4");
      const tab5 = window.document.getElementById("item5");
      tab3.classList.add("active");
      tab1.classList.remove("active");
      tab2.classList.remove("active");
      tab4.classList.remove("active");
      tab5.classList.remove("active");
    } else if (currentTab === constants.NEW_REGISTRATION) {
      const tab1 = window.document.getElementById("item1");
      const tab2 = window.document.getElementById("item2");
      const tab3 = window.document.getElementById("item3");
      const tab4 = window.document.getElementById("item4");
      const tab5 = window.document.getElementById("item5");
      tab4.classList.add("active");
      tab1.classList.remove("active");
      tab2.classList.remove("active");
      tab3.classList.remove("active");
      tab5.classList.remove("active");
    }
    else if (currentTab === constants.CONTACT_US) {
      const tab1 = window.document.getElementById("item1");
      const tab2 = window.document.getElementById("item2");
      const tab3 = window.document.getElementById("item3");
      const tab4 = window.document.getElementById("item4");
      const tab5 = window.document.getElementById("item5");
      tab5.classList.add("active");
      tab1.classList.remove("active");
      tab2.classList.remove("active");
      tab3.classList.remove("active");
      tab4.classList.remove("active");
    }
  }, [currentTab]);

  async function loadImage(url) {
    let elem = new Image();
    return new Promise((resolve, reject) => {
      elem.onload = () => resolve(elem);
      elem.onerror = reject;
      elem.src = url;
    });
  }

  React.useEffect(() => {
    loadImage(wmoTransparentLogo).then((ele) => {
      setLogo(ele);
    });
  }, []);

  // Function to close expanded navbar
  const closeNavToggle = () => {
    const element = document.getElementById("navbarSupportedContent");
    const navToggleButton = document.getElementById("navToggleButton");
    if (element != null) {
      navToggleButton.classList.add("collapsed");
      navToggleButton.ariaExpanded = false;
      element.classList.remove("show");
    }
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light p-0 sticky-top ">
      <div className="container-fluid ">
        <div
          className="navbar-brand ms-md-1 ps-md-3 ms-0 "
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          {logo.complete ? (
            <img
              src={logo.src}
              alt="Logo"
              className="wmo_logo"
              id="wmo_logo"
            />
          ) : (
            <Skeleton
              animation="wave"
              variant="circular"
              className="wmo_logo"
              sx={{ height: "4rem" }}
            />
          )}
          <h5 className="nav_title mt-4 ms-2">ALUMNI</h5>
          <h5 className="nav_title mt-4" id="nav_title_sub">
            &nbsp;WMOC
          </h5>
        </div>
        <button
          id="navToggleButton"
          className="navbar-toggler pe-0 nav-toggle-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto" id="nav-items">
            <li className="item" id="item1">
              <Link
                className="nav-link active"
                aria-current="page"
                to={constants.HOME}
                onClick={closeNavToggle}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <HomeIcon className="menu-icons" />
                  </Grid>
                  <Grid item>Home</Grid>
                </Grid>
              </Link>
            </li>
            <li className="item" id="item2">
              <Link
                className="nav-link active"
                aria-current="page"
                to={constants.GALLERY}
                onClick={closeNavToggle}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Collections className="menu-icons" />
                  </Grid>
                  <Grid item>Gallery</Grid>
                </Grid>
              </Link>
            </li>
            <li className="item" id="item3">
              <Link
                className="nav-link active "
                aria-current="page"
                to="/about-us"
                onClick={closeNavToggle}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <PeopleAltIcon className="menu-icons" />
                  </Grid>
                  <Grid item>AboutUs</Grid>
                </Grid>
              </Link>
            </li>

            <li className="item" id="item4">
              <Link
                className="nav-link active"
                aria-current="page"
                to={constants.NEW_REGISTRATION}
                onClick={closeNavToggle}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <CardMembership className="menu-icons" />
                  </Grid>
                  <Grid item>Membership</Grid>
                </Grid>
              </Link>
            </li>
            <li className="item" id="item5">
              <Link
                className="nav-link active"
                aria-current="page"
                to={constants.CONTACT_US}
                onClick={closeNavToggle}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <PermPhoneMsgIcon className="menu-icons" />
                  </Grid>
                  <Grid item>ContactUs</Grid>
                </Grid>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
