import React from "react";
import Avatar from "@mui/material/Avatar";
import "./Card.css";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
export default function LifeTimeCard({ state, onClick }) {
  React.useEffect(() => {
    if (state) {
        document.getElementById("lifeTimeCard").classList.add("activateCard");
    } else {
        document.getElementById("lifeTimeCard").classList.remove("activateCard");
    }
    return () => {
    };
  }, [state]);
  return (
    <div className="" onClick={onClick}>
      <div id="lifeTimeCard" className="card col-md-12 col-12 ms-md-1">
        <div className="d-flex aligns-items-center justify-content-center">
          <Avatar sx={{ bgcolor: "gold" }}>
            <CurrencyRupeeRoundedIcon />
          </Avatar>
        </div>
        <p className="membership-type d-flex aligns-items-center justify-content-center">
          Life Time{" "}
        </p>
        <div className="d-flex aligns-items-center justify-content-center">
          <i>2500</i>
        </div>
      </div>
    </div>
  );
}
