import api from "./api";

const registrationService = {
  submitRegistrationForm: (data) => {
    return api.post(
      "/registration/add_alumni_application",
      JSON.stringify(data)
    );
  },
  sendPaymentLink: (email) => {
    return api.post(
      "/registration/send_payment_link",
      JSON.stringify({ email: email })
    );
  },
  verifyPayment: (data) => {
    return api.post(
      "/registration/verify_alumni_application_payment",
      JSON.stringify(data)
    );
  },
  updateStudentContactDetails: (contactDetails) => {
    return api.post(
      "/registration/update_student_contact_details",
      JSON.stringify(contactDetails)
    );
  },
  createRegistrationPayment: (student_id, membership) => {
    return api.post(
      `/registration/create_registration_payment/${student_id}`,
      JSON.stringify(membership)
    );
  },
  alumniRegistration: (student_id, payment) => {
    return api.post(
      `registration/alumni_registration/${student_id}`,
      JSON.stringify(payment)
    );
  },
};

export default registrationService;
