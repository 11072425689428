import api from "./api";

const dataService = {
  getCourses: () => {
    return api.get("/main/get_courses");
  },
  getBatches: (course_id) => {
    return api.get(`/main/get_batches_by_course_id/${course_id}`);
  },
  getDepartments: () => {
    return api.get("/main/get_departments");
  },
  newsLetter: (email) => {
    return api.post(
      "/main/get_applicant_id_and_name_by_email_and_mobile",
      JSON.stringify({ email })
    );
  },
  getStudentList: (batch_id) => {
    return api.get(`/main/get_student_list_by_batch_id/${batch_id}`);
  },
  getAlumniContact: () => {
    return api.get("/home/get_alumni_info");
  },
  getCommitteeList: () => {
    return api.get("/home/get_alumni_association_committee");
  },
  getMembershipTypes: () => {
    return api.get('/main/get_membership_types')
  }
};

export default dataService;
