import { Grid, TextField } from "@mui/material";
import React from "react";

export default function Contact({ setData }) {
  return (
    <div className=" col-12 col-md-12 d-flex communication-card">
      <div className="col-md-12">
        <div className="mb-2">
          <b>Contacts</b>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              required
              fullWidth
              id="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              size="small"
              name="email"
              value={setData.formData.email}
              onChange={(newVal) => {
                setData.setFormData({
                  ...setData.formData,
                  email: newVal.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              required
              fullWidth
              type="number"
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              size="small"
              name="mobile"
              value={setData.formData.mobile}
              onChange={(newVal) => {
                setData.setFormData({
                  ...setData.formData,
                  mobile: newVal.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              type="number"
              id="outlined-basic"
              label="Whatsapp No"
              variant="outlined"
              size="small"
              name="whatsapp_no"
              // placeholder="123-45-67-890"
              // inputProps={{
              //   pattern: "[0-9]{3}-[0-9]{4}-[0-9]{2}-[0-9]{3}",
              // }}
              value={setData.formData.whatsapp_no}
              onChange={(newVal) => {
                setData.setFormData({
                  ...setData.formData,
                  whatsapp_no: newVal.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
