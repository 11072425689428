import React from 'react'
import './HistoryCardLeft.css'

function HistoryCardLeft({ year, picture, title, story }) {
    return (
        <div className="row history-padding">
            <div className="history-card shadow">
                <div className="col-md-3 text-md-end text-center float-end" style={{ height: '2rem' }}>
                    <h1 className='history-year' style={{
                        paddingRight: '4rem',
                    }}>{year}</h1>
                </div>
                <div className="img-div-left col-md-5 float-start">
                    <img className='history-image shadow-lg' src={picture} alt="" style={{
                        left: '-5rem',
                    }} />
                </div>
                <div className="history-content pt-4 p-md-5">
                    <h2 className='history-title' style={{
                        textAlign: 'center'
                    }}>
                        {title}
                    </h2>
                    <p>{story}</p>
                </div>
            </div>

        </div>
    )
}

export default HistoryCardLeft