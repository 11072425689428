import React from "react";
import "./Banner.css";
import banner_images from "./Images.js";
import { Link } from "react-router-dom";
import constants from "../../../constants/pages";

// Banner component
export default function Banner() {
  const [sliderImage, setSliderImage] = React.useState("");
  React.useEffect(() => {
    // To set random banner image corresponding to device
    if (window.innerWidth <= 768 && banner_images[0].device === "mobile") {
      const no_of_images = banner_images[0].photos.length;
      const i = Math.floor(Math.random() * no_of_images);
      setSliderImage(banner_images[0].photos[i]);
    } else {
      const no_of_images = banner_images[1].photos.length;
      const i = Math.floor(Math.random() * no_of_images);
      setSliderImage(banner_images[1].photos[i]);
    }
  }, []);
  // Function to scroll on mission button click
  const MoveToMission = () => {
    const element = document.getElementById("about-area");
    if (element.offsetWidth >= 740 && element.offsetWidth <= 1000) {
      window.scrollBy(0, element.offsetTop - 100);
    } else if (element.offsetWidth >= 1000) {
      window.scrollBy(0, element.offsetTop);
    } else {
      window.scrollBy(0, element.offsetTop - 80);
    }
  };
  return (
    <section id="slider-area" className="mb-0 pb-0">
      <div className="slider-active-wrap  text-center text-md-left">
        <div
          className="single-slide-wrap slide-bg-1 img-fluid"
          style={{ backgroundImage: `url(${sliderImage})` }}
        >
          <div className="container">
            <div className="row col-lg-8">
              <div className=" mb-5">
                <div className="slider-content mb-md-5 me-md-5">
                  <h2>We Are Proud</h2>
                  <h3>
                    Students of <span>WMO Arts and Science College</span>
                  </h3>
                  <p>
                    Alumni Needs enables you to harness the power of your alumni
                    network.
                    <br /> Whatever may be the need (academic, relocation,
                    career, achievements etc).
                  </p>
                  <div className="slider-btn">
                    <a
                      className="btn btn-brand smooth-scroll"
                      onClick={MoveToMission}
                    >
                      our mission
                    </a>
                    <Link to={constants.ABOUT_US} className="btn btn-brand-rev">
                      our story
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
