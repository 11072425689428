import React from "react";
import "./Splash.css";
function Splash() {
  // Function to handle replace the text in the splash screen
  const toReplaceLoading = () => {
    const load1 = document.getElementById("load1");
    const load2 = document.getElementById("load2");
    if (load2 != null) {
      load1.style = "display: none";
      load2.style = "display: flex";
    }
  };
  // Initial text in the splash screen and replace function call
  React.useEffect(() => {
    const load1 = document.getElementById("load1");
    const load2 = document.getElementById("load2");
    if (load1 != null) {
      load2.style = "display:none";
    }
    setTimeout(toReplaceLoading, 2000);
  }, []);
  return (
    <div className="screen" id="screen">
      <div id="load1" className="position-absolute">
        <h4 className="title">ALUMNI WMOC</h4>
      </div>
      <div
        className="load-wrapper position-absolute justify-content-center align-items-center"
        id="load2"
      >
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-secondary" role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-success" role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-light" role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  );
}

export default Splash;
