import api from "./api";

const adminService = {
  getMembershipRequests: (filter) => {
    return api.post("/admin/get_alumni_applications", filter);
  },
  getDepartments: () => {
    return api.get("/main/get_departments");
  },
  getProgrammes: (department_id) => {
    return api.get(`/main/get_courses_by_department_id/${department_id}`);
  },
  getBatches: (course_id) => {
    return api.get(`/main/get_batches_by_course_id/${course_id}`);
  },
  addDepartment: (name) => {
    return api.post("/admin/add_department", JSON.stringify({ name }));
  },
  addProgramme: (name, department_id, course_type) => {
    return api.post(
      "/admin/add_course",
      JSON.stringify({ name, department_id, course_type })
    );
  },
  addBatch: (name, course_id, start_date, end_date) => {
    return api.post(
      "/admin/add_batch",
      JSON.stringify({ name, course_id, start_date, end_date })
    );
  },
  getBatch: (batch_id) => {
    return api.get(`/main/get_batch_by_id/${batch_id}`);
  },
  getProgramme: (course_id) => {
    return api.get(`/main/get_course_by_id/${course_id}`);
  },
  getDepartment: (department_id) => {
    return api.get(`/main/get_department_by_id/${department_id}`);
  },
  editDepartment: (department_id, name) => {
    return api.post(
      `/admin/edit_department/${department_id}`,
      JSON.stringify({ name })
    );
  },
  editProgramme: (course_id, name, course_type) => {
    return api.post(
      `/admin/edit_course/${course_id}`,
      JSON.stringify({ name, course_type })
    );
  },
  editBatch: (batch_id, name, start_date, end_date) => {
    return api.post(
      `/admin/edit_batch/${batch_id}`,
      JSON.stringify({ name, start_date, end_date })
    );
  },
  deleteDepartment: (department_id) => {
    return api.post(`/admin/delete_department/${department_id}`);
  },
  deleteProgramme: (programme_id) => {
    return api.post(`/admin/delete_course/${programme_id}`);
  },
  deleteBatch: (batch_id) => {
    return api.post(`/admin/delete_batch/${batch_id}`);
  },
  getAlumniApplicant: (applicant_id) => {
    return api.get("/admin/get_alumni_applicant_by_id/" + applicant_id);
  },
  getAlumniList: (filter = {}) => {
    return api.post("/admin/get_all_alumni", filter);
  },
  deleteMembershipRequest: (applicant_id) => {
    return api.post("/admin/delete_application/" + applicant_id);
  },
  editApplicant: (applicantId, data) => {
    return api.post(
      `/admin/edit_applicant/${applicantId}`,
      JSON.stringify(data)
    );
  },
  rejectApplicant: (applicantId, reason) => {
    return api.post(
      `/admin/reject_applicant/${applicantId}`,
      JSON.stringify({ reason })
    );
  },
  approveApplication: (applicantId) => {
    return api.post(`/admin/approve_application/${applicantId}`);
  },
  returnForPayment: (applicantId, reason) => {
    return api.post(
      `/admin/return_for_payment/${applicantId}`,
      JSON.stringify({ reason })
    );
  },
  updateAlumniInfo: (data) => {
    return api.post("/admin/update_alumni_info", JSON.stringify(data));
  },
  addCommitteeMember: (data) => {
    return api.post("/admin/add_alumni_committee_member", JSON.stringify(data));
  },
  updateCommitteeMember: (id, data) => {
    return api.post(
      "admin/edit_alumni_association_member/" + id,
      JSON.stringify(data)
    );
  },
  deleteCommitteeMember: (id) => {
    return api.post("admin/delete_alumni_committee_member/" + id);
  },
  updateAlumniCommitteeMember: (member_id, data) => {
    return api.post(
      `/admin/update_alumni_committee_member/${member_id}`,
      JSON.stringify(data)
    );
  },
  uploadAlumniCommitteeMemberImage: (member_id, data) => {
    return api.post(
      `/admin/upload_alumni_committee_member_image/${member_id}`,
      data
    );
  },
  getTransactionsList: () => {
    return api.get("admin/get_transaction_list");
  },
  approveTransaction: (order_id) => {
    return api.get(`/admin/approve_transaction/${order_id}`);
  },
  rejectTransaction: (order_id) => {
    return api.get(`/admin/reject_transaction/${order_id}`);
  },
  getAlumniById: (alumniId) => {
    return api.get(`/admin/get_alumni_by_id/${alumniId}`)
  },
  editAlumni: (alumniId, data) => {
    return api.post(`/admin/edit_alumni/${alumniId}`, JSON.stringify(data))
  },
  deleteAlumni: (alumniId) => {
    return api.post(`/admin/delete_alumni/${alumniId}`)
  },
  sendEditMail:(alumniId)=>{
    return api.get(`/admin/send_profile_edit_mail/${alumniId}`)
  }
};

export default adminService;
