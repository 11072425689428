import { Grid, Typography } from "@mui/material";
import React from "react";
import CommitteeEdit from "../../../components/admin-profile-edit/alumni-comm-edit/CommitteeEdit";
import AlumniContactEdit from "../../../components/admin-profile-edit/alumni-contact-edit/AlumniContactEdit";
import "./PageContactEdit.css";

function PageContactEdit() {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems="center"
      direction={"column"}
      gap={2}
      padding={2}
    >
      <Grid item>
        <Typography
          sx={{
            textShadow: "0px 2px 2px #000000",
          }}
          variant="h5"
        >
          Edit Alumni Profile
        </Typography>
      </Grid>
      <AlumniContactEdit />
      {/* alumni comm list management */}
      <CommitteeEdit />
    </Grid>
  );
}

export default PageContactEdit;
