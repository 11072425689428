import { Button, IconButton, TableCell, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import adminService from "../../../service/adminService";
import ToastContext from "../../../context/ToastContext";
import AlertContext from "../../../context/AlertContext";
import "./SingleRow.css";

function SingleRow({ item, handleReloadTable }) {
  const [newData, setNewData] = useState({});
  const [data, setData] = useState(item);
  const [saveDisable, setSaveDisable] = useState(true);

  const toast = React.useContext(ToastContext);
  const alert = React.useContext(AlertContext);

  React.useEffect(() => {
    setData(item);
  }, [item]);

  const handleDeleteClick = (id) => {
    alert.setValue({
      open: true,
      status: "error",
      title: "Delete",
      message: "Are you sure?",
      response: () => {
        adminService.deleteCommitteeMember(id).then((res) => {
          alert.handleClose();
          toast.setValue({
            open: true,
            message: res.data.message,
            status: res.data.status,
          });
          if (res.data.status === "success") {
            handleReloadTable({ type: "delete", member_id: id });
          }
        });
      },
    });
  };

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files.length !== 0) {
      const ext = event.target.files[0].name.split(".").at(-1);
      const fileName = `${item.id}_${data.name}_${data.position}.${ext}`;
      let fd = new FormData();
      fd.append("image", event.target.files[0]);
      fd.append("file_name", fileName);
      adminService.uploadAlumniCommitteeMemberImage(item.id, fd).then((res) => {
        toast.setValue({
          open: true,
          status: res.data.status,
          message: res.data.message,
        });
        if (res.data.status === "success") {
          const reader = new FileReader();
          reader.onload = function (e) {
            handleReloadTable({
              type: "edit",
              payload: { ...data, img_url: e.target.result },
            });
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      });
    }
  };

  const handleUploadClick = () => {
    document.getElementById(item.id).click();
  };

  const handleSaveClick = () => {
    adminService
      .updateAlumniCommitteeMember(item.id, newData)
      .then((res) => {
        toast.setValue({
          open: true,
          message: res.data.message,
          status: res.data.status,
        });
        if (res.data.status === "success") {
          setSaveDisable(true);
          handleReloadTable({ type: "edit", payload: data });
        }
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          message: "Something went wrong",
          status: "error",
        });
      });
  };

  const handleValueChange = (event) => {
    if (saveDisable) setSaveDisable(false);
    setNewData((prevVal) => ({
      ...prevVal,
      [event.target.name]: event.target.value,
    }));
    setData((prevVal) => ({
      ...prevVal,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <TableCell>
        <TextField
          required
          label="Priority"
          name="alignment_priority"
          value={data.alignment_priority}
          type="number"
          sx={{
            maxWidth: "fit-content",
          }}
          size="small"
          onChange={handleValueChange}
        />
      </TableCell>
      <TableCell sx={{ width: '8rem' }}>
        <img
          className="shadow image"
          src={
            data.img_url
              ? data.img_url
              : "https://alumni.wmocollege.ac.in/static/media/transparent_logo.c506225e794b39a63dd7.png"
          }
          alt=""
          style={{
            minWidth: "2rem",
            minHeight: "2rem",
            maxWidth: "2rem",
            maxHeight: "2rem",
            borderRadius: "10%",
          }}
        />
        <input
          id={data.id}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleFileUpload(e)}
          accept="image/*"
        />
        <Tooltip title='Picture Aspect Ratio should be 340:480' arrow followCursor>
          <IconButton onClick={handleUploadClick}>
            <UploadIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell>
        <TextField
          required
          fullWidth
          value={data.name}
          label={"Name"}
          name="name"
          size="small"
          onChange={handleValueChange}
        />
      </TableCell>
      <TableCell>
        <TextField
          required
          fullWidth
          name="position"
          label={"Position"}
          value={data.position}
          size="small"
          onChange={handleValueChange}
        />
      </TableCell>
      <TableCell>
        <Button
          disabled={saveDisable}
          variant="contained"
          color="success"
          size="small"
          onClick={handleSaveClick}
        >
          Save
        </Button>
        &nbsp;
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => {
            handleDeleteClick(data.id);
          }}
        >
          Delete
        </Button>
      </TableCell>
    </>
  );
}

export default SingleRow;
