import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import adminService from "../../../service/adminService";
import ToastContext from "../../../context/ToastContext";
import LoadingContext from "../../../context/LoadingContext";
import { LoadingButton } from "@mui/lab";

export default function DialogAddCommitteeMember({
  open,
  handleReloadTable,
  close,
}) {
  const [formData, setFormData] = React.useState({
    name: "",
    position: "",
    alignment_priority: "",
  });
  const toast = React.useContext(ToastContext);
  const loading = React.useContext(LoadingContext);

  const handleClose = () => {
    close();
    setFormData({
      name: "",
      position: "",
      alignment_priority: "",
    });
  };

  const handleValueChange = (event) => {
    setFormData((preVal) => ({
      ...preVal,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loading.incrementLoading();
    adminService
      .addCommitteeMember(formData)
      .then((res) => {
        loading.decrementLoading();
        handleClose();
        toast.setValue({
          open: true,
          message: res.data.message,
          status: res.data.status,
        });
        if (res.data.status === "success") {
          handleReloadTable({
            type: "add",
            payload: { ...formData, id: res.data.data },
          });
        }
      })
      .catch((err) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: "Something went wrong",
          status: "error",
        });
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle>Add Committee Member</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required={true}
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              size="small"
              name="name"
              value={formData.name}
              onChange={handleValueChange}
            />
            <TextField
              required={true}
              margin="dense"
              label="Position"
              type="text"
              fullWidth
              size="small"
              name="position"
              value={formData.position}
              onChange={handleValueChange}
            />
            <TextField
              required={true}
              margin="dense"
              fullWidth
              label="Priority"
              type="number"
              size="small"
              name="alignment_priority"
              value={formData.alignment_priority}
              onChange={handleValueChange}
            />
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton loading={loading.isLoading} type="submit">
                Add
              </LoadingButton>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
