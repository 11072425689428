import { Box, Button, IconButton, InputBase, Toolbar, Typography } from "@mui/material";
import { membershipTableStyle } from "../Style";
import { Print as PrintIcon, Search as SearchIcon } from '@mui/icons-material'
import { headCells } from "../Helper";
import TableFilter from "./Filter";
import printTable from "../../../../utils/printTable";
export default function EnhancedTableToolbar(props) {
    const { requestSearch, setTableData, filter, setFilter, rows } = props;
    const tableTitle= 'Alumni List'
    return (
      <Toolbar sx={membershipTableStyle.toolBar}>
        <Typography sx={membershipTableStyle.tableTitle} variant="h6" id="tableTitle" component="div"> {tableTitle}</Typography>
        <Button variant="contained" endIcon={<PrintIcon />} sx={membershipTableStyle.printButton} onClick={() => {printTable(rows, headCells, tableTitle);}}>Print</Button>
        <Box component='div' className="d-flex rounded border">
          <InputBase style={membershipTableStyle.searchArea} placeholder="Search Name here..." inputProps={{ "aria-label": "search" }} onChange={(e) => { requestSearch(e.target.value) }}/>
          <IconButton aria-label="search"> <SearchIcon /> </IconButton>
        </Box>
        <TableFilter setTableData={setTableData} filter={filter} setFilter={setFilter}/>
      </Toolbar>
    );
  };