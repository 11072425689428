export default (rows, headCells, tableName = '') => {
    const currentTitle = document.title
    document.title = tableName
    const table = document.createElement("table");
    table.style.width = "100%"
    table.style.border = "1px solid"
    const tableHead = document.createElement("thead");
    const tableBody = document.createElement("tbody");
    table.id = 'table'

    const thr = document.createElement("tr");
    for (const cell in headCells) {
        if (headCells[cell].id != 'action') {
            const th = document.createElement("th");
            th.style.border = "1px solid"
            th.style.textAlign = "center"
            const val = document.createTextNode(headCells[cell].label);
            th.appendChild(val)
            thr.append(th)
        }
    }
    tableHead.append(thr)
    for (const row in rows) {
        const tdr = document.createElement("tr");
        for (const cell in headCells) {
            if (headCells[cell].id != 'action') {
                const td = document.createElement("td");
                td.style.border = "1px solid"
                td.style.padding = "7px"
                const val = document.createTextNode(rows[row][headCells[cell].id]);
                td.appendChild(val)
                tdr.append(td)
            }
        }
        tableBody.append(tdr)
    }
    const originalPage = document.body;
    table.append(tableHead)
    table.append(tableBody)
    document.body.replaceWith(table);
    window.print()
    document.getElementById('table').replaceWith(originalPage)
    document.title = currentTitle
}