import React from 'react'

// setValue({
// open: false,
// title: "",
// status: "",
// message: "",
// response: () => { },
// })
const AlertContext = React.createContext({ value: {}, setValue: () => { }, handleClose: () => { } });

export default AlertContext;