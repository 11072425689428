import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import adminService from "../../../service/adminService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ToastContext from "../../../context/ToastContext";

function BatchEditDialog({ open, batchId, handleBatchEdit, close }) {
  const [name, setName] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const toast = React.useContext(ToastContext);

  React.useEffect(() => {
    if (open)
      adminService.getBatch(batchId).then((res) => {
        if (typeof res.data === "object") {
          setName(res.data.data.name);
          setStartDate(res.data.data.start_date);
          setEndDate(res.data.data.end_date);
        }
      });
  }, [batchId]);

  const handleClose = () => {
    close();
    setName("");
    setStartDate(null);
    setEndDate(null);
    handleBatchEdit();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    adminService.editBatch(batchId, name, startDate, endDate).then((res) => {
      res.data.status === "success"
        ? toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          })
        : toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
      if (typeof res.data === "object") {
        handleClose();
      }
    });
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle>Edit Batch</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required={true}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              name="name"
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={startDate}
                onChange={(value) => {
                  if (value !== null) value = value.toLocaleDateString("fr-CA");
                  setStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    fullWidth
                    required
                    size="small"
                    variant="outlined"
                  />
                )}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                inputFormat="dd/MM/yyyy"
                onChange={(value) => {
                  if (value !== null) value = value.toLocaleDateString("fr-CA");
                  setEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    fullWidth
                    required
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </LocalizationProvider>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default BatchEditDialog;
