import React from "react";
import "./AlumniContactEdit.css";
import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import LoadingContext from "../../../context/LoadingContext";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import dataService from "../../../service/dataService";
import adminService from "../../../service/adminService";
import ToastContext from "../../../context/ToastContext";

function AlumniContactEdit() {
  const loading = React.useContext(LoadingContext);
  const toast = React.useContext(ToastContext);
  const [alumniInfo, setAlumniInfo] = React.useState({
    name: "",
    mobile: "",
    email: "",
  });
  const [editInfo, setEditInfo] = React.useState([]);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    dataService.getAlumniContact().then((res) => {
      setAlumniInfo(res.data.data);
    });
  }, []);

  const handleValueChange = (event) => {
    if (disableSave) setDisableSave(false);
    setEditInfo((prevVal) => ({
      ...prevVal,
      [event.target.name]: event.target.value,
    }));
    setAlumniInfo((prevVal) => ({
      ...prevVal,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSaveContact = () => {
    loading.incrementLoading();
    adminService
      .updateAlumniInfo(editInfo)
      .then((res) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: res.data.message,
          status: res.data.status,
        });
        if (res.data.status === "success") {
          setDisableSave(true);
        }
      })
      .catch((err) => {
        loading.decrementLoading();
        console.log(err);
      });
  };
  return (
    <>
      <Card
        sx={{
          width: "95%",
          color: "#161F37",
          padding: 1,
        }}
        className="shadow"
      >
        <CardContent>
          <Grid item xs={12}>
            <Typography variant="h6" marginBottom={".7rem"}>
              Contact Details
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={"space-around"}
            alignItems="center"
            direction={"row"}
            gap={.1}
          >
            <Grid item xs={12} md={4}>
              <TextField
                label="Alumni Name"
                fullWidth
                name="name"
                size="small"
                variant="outlined"
                value={alumniInfo.name}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Mobile"
                name="mobile"
                fullWidth
                size="small"
                value={alumniInfo.mobile}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                size="small"
                value={alumniInfo.email}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <LoadingButton
                variant="contained"
                fullWidth
                onClick={handleSaveContact}
                disabled={disableSave}
                loading={loading.isLoading}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default AlumniContactEdit;
