import image1 from "../../../assets/img/banner/img-1.jpg";
import image2 from "../../../assets/img/banner/img-2.jpg";
import image3 from "../../../assets/img/banner/img-3.jpg";
import image4 from "../../../assets/img/banner/img-4.jpg";
import image5 from "../../../assets/img/banner/img-5.jpg";
import image6 from "../../../assets/img/banner/img-6.jpg";

const Images = [
  {
    device: "mobile",
    photos: [image3, image4, image5, image6],
  },
  {
    device: "desktop",
    photos: [image5, image2, image3, image1, image6],
  },
];

export default Images;
