import React from "react";
import {TextField,Select,FormControl,InputLabel,MenuItem,Grid} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dataService from "../../../service/dataService";
import "./Mandatory.css";

// Mandatory Card Component
export default function Mandatory({setData}) {

  const [programmes, setProgrammes] = React.useState([]);
  const [batches, setBatches] = React.useState([]);
  const genders = ["Male", "Female", "Other"];

  React.useEffect(() => {
    dataService.getCourses().then((res) => {
      setProgrammes(res.data.data);
    });
  },[]);

  return (
    <div className="d-flex col-12">
      <div className="mandatory-card col-md-12" style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              autoFocus
              fullWidth
              id="outlined-basic"
              label="Admission No"
              variant="outlined"
              size="small"
              name="admission_no"
              value={setData.formData.admission_no}
              onChange={(newVal) => {
                setData.setFormData({
                  ...setData.formData,
                  admission_no: newVal.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              required
              fullWidth
              id="outlined-basic"
              label="Name"
              type='text'
              variant="outlined"
              size="small"
              name="name"
              value={setData.formData.name}
              onChange={(newVal) => {
                setData.setFormData({
                  ...setData.formData,
                  name: newVal.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date of Birth"
                inputFormat="dd/MM/yyyy"
                value={setData.formData.viewDob}
                onChange={(newVal) => {
                  const viewDob=newVal;
                  if (newVal !== null){
                    newVal = newVal.toLocaleDateString("fr-CA");
                  }
                  setData.setFormData({
                    ...setData.formData,
                    dob: newVal,
                    viewDob
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth required size={"small"} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl required fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={setData.formData.gender}
                label="Gender"
                name="gender"
                required
                onChange={(newVal) => {
                  setData.setFormData({
                    ...setData.formData,
                    gender: newVal.target.value,
                  });
                }}
              >
                {genders.map((value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl required fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Programme</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={setData.formData.course_id}
                label="Programme"
                name="programme"
                onChange={(newVal) => {
                  setData.setFormData({
                    ...setData.formData,
                    course_id: newVal.target.value,
                  });
                  dataService.getBatches(newVal.target.value).then((res) => {
                    setBatches(res.data.data);
                  });
                }}
              >
                {programmes.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl required fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Batch</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={setData.formData.batch_id}
                label="Batch"
                name="batch_id"
                onChange={(newVal) => {
                  setData.setFormData({
                    ...setData.formData,
                    batch_id: newVal.target.value,
                  });
                }}
              >
                {batches.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

