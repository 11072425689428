import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WorkIcon from '@mui/icons-material/Work';
import { Stack } from "@mui/material";
import React from "react";
import OccupationDialog from "../temporary-registration/occupation/occupation-popup/OccupationDialog";
function UpdateOccupation(props) {
  const { alumni, setAlumni, setUpdateData } = props;
  const [occupationOpen, setOccupationOpen] = React.useState(false);
  const [newOccupation, setNewOccupation] = React.useState({
    applicant_occupation: [],
  });
  const [deleteOccupation, setDeleteOccupation] = React.useState([]);

  React.useEffect(() => {
    setUpdateData({
      type: "add",
      sub_type: "alumni_occupation",
      payload: newOccupation.applicant_occupation,
    });
  }, [newOccupation]);

  const handelDeleteOccupation = (occupation_id) => {
    setAlumni((alumni) => {
      const newList = alumni.alumni_occupation.filter((value, index) => {
        return value.id !== occupation_id;
      });
      return {
        ...alumni,
        alumni_occupation: newList,
      };
    });
    setDeleteOccupation((preVal) => [...preVal, occupation_id]);
  };

  const handleRemoveOccupation = (arrayIndex) => {
    const newList = newOccupation.applicant_occupation.filter(
      (value, index) => {
        return index !== arrayIndex;
      }
    );
    setNewOccupation((preVal) => ({
      ...preVal,
      applicant_occupation: newList,
    }));
  };

  React.useEffect(() => {
    setUpdateData({
      type: "delete",
      sub_type: "alumni_occupation",
      payload: deleteOccupation,
    });
  }, [deleteOccupation]);

  const handleAddButton = () => {
    setOccupationOpen(true);
  };
  const handleOccupationDialogClose = () => {
    setOccupationOpen(false);
  };
  return (
    <>
      <OccupationDialog
        open={occupationOpen}
        close={handleOccupationDialogClose}
        setData={{ formData: newOccupation, setFormData: setNewOccupation }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={700}>Occupation Details</Typography>
        <Button onClick={handleAddButton}>Add New</Button>
      </div>
      <Grid container spacing={2}>
        {alumni.alumni_occupation.map((row, index) => {
          let from = new Date(row.from);
          from = from.toLocaleDateString("en-US");
          let to;
          if (row.to !== null && row.to !== "null") {
            to = new Date(row.to);
            to = to.toLocaleDateString("en-US");
          } else to = "Present";
          return (
            <Grid item key={index} xs={12} sm={6}>
              <Card>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Avatar variant="rounded" sx={{ width: 60, height: 60 }}>
                    <WorkIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Stack spacing={0.5} width={"100%"}>
                    <Typography fontWeight={400}>
                      {row.position}
                      <Typography variant="caption" color="text.secondary">
                        {" " + from + " - " + to}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <AccountBalanceIcon /> {row.organization}
                    </Typography>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      handelDeleteOccupation(row.id);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
        {newOccupation.applicant_occupation.map((row, index) => {
          let from = new Date(row.from);
          from = from.toLocaleDateString("en-US");
          let to;
          if (row.to !== null && row.to !== "null") {
            to = new Date(row.to);
            to = to.toLocaleDateString("en-US");
          } else to = "Present";
          return (
            <Grid item key={index} xs={12} sm={6}>
              <Card>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Avatar variant="rounded" sx={{ width: 60, height: 60 }}>
                    <WorkIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Stack spacing={0.5} width={"100%"}>
                    <Typography fontWeight={400}>
                      {row.position}
                      <Typography variant="caption" color="text.secondary">
                        {" " + from + " - " + to}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <AccountBalanceIcon /> {row.organization}
                    </Typography>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      handleRemoveOccupation(index);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default UpdateOccupation;
