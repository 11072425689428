import React from 'react'
import './MemberCard.css'

function MemberCard({ picture, name, position }) {
    return (
        <div className='col-md-6 col-lg-4 col-xl-4 col-xxl-3 col-12 member-card p-0'>
            <img className='member-image' src={picture} alt="" />
            <div className="row align-items-center justify-content-center align-content-center">
                <div className="col-md-8 col-8 member-label">
                    <h6 className='member-name'>{name}</h6>
                    <p className='member-position'>{position}</p>
                </div>
            </div>
        </div>
    )
}

export default MemberCard