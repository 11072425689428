import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import authService from "../../service/authService";
import storageService from "../../service/storageService";
import "./AdminLogin.css";
import LoadingContext from "../../context/LoadingContext";
import ToastContext from "../../context/ToastContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function AdminLogin({ userType }) {
  const navigate = useNavigate();

  const loading = React.useContext(LoadingContext);
  const toast = React.useContext(ToastContext);

  React.useEffect(() => {
    const user = storageService.getUser();
    if (user) {
      navigate("/admin");
    }
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user_type, setUserType] = useState(userType);
  const [showPassword, setShowPassword] = useState(false);
  const [icon, setIcon] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseHover = () => setIcon(true);
  const handleMouseLeave = () => {
    //logic to display button when password is shown but mouse left the field
    if (!showPassword) {
      setIcon(false);
    }
    return;
  };
 //To handle the login button click event
  function handleLogin() {
    loading.incrementLoading();
    authService
      .login(username, password, user_type)
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.data !== null) {
            storageService.setUser(res.data.data);
          }
          loading.decrementLoading();
          navigate("/admin");
        } else {
          loading.decrementLoading();
          toast.setValue({
            open: true,
            message: res.data.message,
            status: res.data.status,
          });
        }
      })
      .catch((err) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: err.message,
          status: "error",
        });
      });
  }
  //To handle login on enter key press
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleLogin();
    }
  }

  return (
    <div className="col-9 col-sm-7 col-md-5 col-lg-4 col-xl-3">
      <div className="rounded-3 login-card shadow">
        <div className="card-body p-md-4 p-sm-4 text-center">
          <div className="mb-md-4 mt-md-4 mb-4">
            <h2 className="fw-bold text-uppercase mt-4 mb-4">
              {userType.toUpperCase()} Login
            </h2>
            <div className="form-outline form-white mb-2 p-2">
              <TextField
                label="Username"
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ color: "white" }}
                size="small"
              />
            </div>
            <div className="form-outline form-white mb-3 p-2">
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                // InputLabelProps={{ style: { color: 'white' } }}
                onKeyPress={handleKeyPress}
                onMouseOver={handleMouseHover}
                onMouseLeave={handleMouseLeave}
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {icon &&
                          (showPassword ? <Visibility /> : <VisibilityOff />)}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <LoadingButton
              loading={loading.isLoading}
              onClick={handleLogin}
              className="loginButton"
              variant="outlined"
              size="medium"
            >
              <Typography variant="button" fontSize="1rem">
                Login
              </Typography>
            </LoadingButton>
            {/* <br />{userType == 'admin' ? <u role='button' onClick={() => { navigate('/admin/dept-login') }}>Department Login  &gt;&gt;</u> :
                            <u role='button' onClick={() => { navigate('/admin/login') }}>Admin Login &gt;&gt;</u>} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
