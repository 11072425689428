import React from "react";

function RefundPolicy() {
  return (
    <>
      <p align="center">
        <strong>Refund Policy</strong>
        <br />
        <br />
        <br />
      </p>
      <p align="left">
        <strong>Returns</strong>
      </p>
      <p align="left">
        Our policy lasts 30 days. If 30 days have gone by since your purchase,
        unfortunately we can’t offer you a refund or exchange.
      </p>
      <p align="left">
        In order to be eligible for a refund, the membership registration must
        be redundant or a different membership type from the one you selected.
      </p>
      <p align="left">
        To complete your return, we require a receipt or proof of purchase.
      </p>
      <p align="left">
        Please do not send your purchase back to the manufacturer.
      </p>
      <p align="left">
        There are certain situations where only partial refunds are granted: (if
        applicable)
      </p>
      <p align="left">
        An unintentional selection of multiple memberships was made.
      </p>
      <p align="left">
        <strong>Refunds (if applicable)</strong>
      </p>
      <p align="left">
        Once your request for return is received and reviewed, we will send you
        an email to notify you that we have reviewed your request for return. We
        will also notify you of the approval or rejection of your refund.
      </p>
      <p align="left">
        If you are approved, then your refund will be processed, and a credit
        will automatically be applied to your credit card or original method of
        payment, within a certain amount of days.
      </p>
      <p align="left">
        <strong>Late or missing refunds (if applicable)</strong>
      </p>
      <p align="left">
        If you haven’t received a refund yet, first check your bank account
        again.
      </p>
      <p align="left">
        Then contact your credit card company, it may take some time before your
        refund is officially posted.
      </p>
      <p align="left">
        Next contact your bank. There is often some processing time before a
        refund is posted.
      </p>
      <p align="left">
        If you’ve done all of this and you still have not received your refund
        yet, please contact us at&nbsp;
        <a href="mailto:alumniwmoc@wmocollege.ac.in">
          <u>
            <strong>alumniwmoc@wmocollege.ac.in</strong>
          </u>
        </a>
        <strong> .</strong>
      </p>
      <p align="left">
        <strong>Exchanges (if applicable)</strong>
      </p>
      <p align="left">Exchanges of any kind are not accepted.</p>
      <p align="left">
        <strong>Gifts</strong>
      </p>
      <p align="left">The website does not support gifting.</p>
    </>
  );
}

export default RefundPolicy;
