import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dataService from "../../../service/dataService";
import DialogAddCommitteeMember from "../add-member-dialog/DialogAddCommitteeMember";
import SingleRow from "../single-row/SingleRow";
import LoadingContext from "../../../context/LoadingContext";

const dataReducer = (state, action) => {
  switch (action.type) {
    case "set":
      return action.payload;
    case "add":
      state.push(action.payload);
      state.sort((a, b) => {
        return parseInt(a.alignment_priority) - parseInt(b.alignment_priority);
      });
      return state;
    case "delete":
      state = state.filter((val) => val.id !== action.member_id);
      return state;
    case "edit":
      state = state.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.sort((a, b) => {
        return parseInt(a.alignment_priority) - parseInt(b.alignment_priority);
      });
      return state;
  }
};

function CommitteeEdit() {
  const [data, setData] = React.useReducer(dataReducer, []);
  const [openAddMemberDialog, setOpenAddMemberDialog] = React.useState(false);
  const loading = React.useContext(LoadingContext);
  useEffect(() => {
    loading.incrementLoading();
    dataService
      .getCommitteeList()
      .then((res) => {
        setData({ type: "set", payload: res.data.data });
        loading.decrementLoading();
      })
      .catch((err) => {
        loading.decrementLoading();
      });
  }, []);

  const tableHeads = {
    fontWeight: "bold",
  };

  const handleAddClick = () => {
    setOpenAddMemberDialog(true);
  };

  return (
    <Card
      sx={{
        width: "95%",
        color: "#161F37",
        padding: 1,
      }}
      className="shadow"
    >
      {/* {data.map((value) => {
        return value.id;
      })} */}
      <CardContent>
        <DialogAddCommitteeMember
          open={openAddMemberDialog}
          handleReloadTable={setData}
          close={() => setOpenAddMemberDialog(false)}
        />
        <Grid
          item
          xs={12}
          paddingTop={1}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Committee Members List</Typography>
          <Button variant="contained" onClick={handleAddClick}>
            Add
          </Button>
        </Grid>
        <Grid container>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeads}>Priority</TableCell>
                  <TableCell sx={tableHeads}>Picture</TableCell>
                  <TableCell sx={tableHeads}>Name</TableCell>
                  <TableCell sx={tableHeads}>Position</TableCell>
                  <TableCell sx={tableHeads}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <SingleRow item={item} handleReloadTable={setData} />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CommitteeEdit;
