import React from "react";
import pic from "../../../assets/img/mission/mission.jpg";
import "./MissionAndVision.css";
import animation_functions from "../../../assets/animation-function/OnScrollFunction";
import { Link } from "react-router-dom";

// Mission card component
export default function MissionAndVision() {
  return (
    <section id="about-area" className="section-padding">
      <div className="about-area-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 ms-auto">
              <div className="about-content-wrap">
                <div className="section-title text-center text-lg-left">
                  <h2>Our Mission</h2>
                </div>
                <div className="about-thumb">
                  <img src={pic} alt="" className="img-fluid" />
                </div>
                <div className="p-2">
                  <p>
                    The exuberant network of alumni systems in the WMO Arts and
                    Science College involved numerous educational and welfare
                    activities across the world. It serves a platform to bridge
                    the gap between the different batches of students. The prime
                    intention of this system is to maintain the cultural legacy
                    of the college. Alumni system attempts to exchange academic
                    and non-academic experiences among the students. It suggests
                    innovative ideas in order to the development of college.{" "}
                  </p>
                  <p>
                    It extends financial support to the deprived students.
                    Alumni association conducts their meetings mostly in the
                    college campus itself. All the activities of the Alumni
                    association follow the moral codes and conducts of the
                    college. It designs its action plans with the suggestions of
                    principal and management.
                  </p>
                  <Link
                    to="/about-us"
                    className="btn btn-brand about-btn mt-0 mt-md-3"
                  >
                    know more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function toRev() {
  animation_functions.reveal(".about-area-wrapper", 0);
}
window.addEventListener("scroll", toRev);
