import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Grid, TextField } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function AchievementDialog({ setData, open, close }) {
  const [achievement, setAchievement] = React.useState({
    achievement: "",
    date: null,
  });

  const handleClose = () => {
    close(false);
    setAchievement({
      achievement: "",
      date: null,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let currentAchievements = setData.formData.applicant_achievements;
    currentAchievements.push(achievement);
    setData.setFormData({
      ...setData.formData,
      applicant_achievements: currentAchievements,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
      <DialogTitle sx={{ mt: 1 }}>Add your Achievement</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container sx={{ p: 1 }} spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Achievement Title"
                id="achievement"
                required
                fullWidth
                size="small"
                name="achievement"
                // inputProps={{ pattern: "[a-zA-Z]*" }}
                value={achievement.achievement}
                onChange={(e) =>
                  setAchievement({
                    ...achievement,
                    achievement: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  name="date"
                  inputFormat="dd/MM/yyyy"
                  value={achievement.date}
                  onChange={(newVal) => {
                    if (newVal !== null)
                      newVal = newVal.toLocaleDateString("fr-CA");
                    setAchievement((achievement) => ({
                      ...achievement,
                      date: newVal,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      size="small"
                      name="date"
                      id="date"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button autoFocus type="submit">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
