import React from "react";
import ToastContext from "../../context/ToastContext";
import LoadingContext from "../../context/LoadingContext";
import AlertContext from "../../context/AlertContext";
import registrationService from "../../service/registrationService";
import PaymentPrompt from "../../components/payment-prompt/PaymentPrompt";
import DescriptionModel from "../../components/membership-cards/DescriptionModel";
import BasicCard from "../../components/membership-cards/BasicCard";
import PremiumCard from "../../components/membership-cards/PremiumCard";
import LifeTimeCard from "../../components/membership-cards/LifeTimeCard";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TermsAndPolicy from "../terms-and-condition/TermsAndPolicy";
import ButtonAlreadyMember from "./ButtonAlreadyMember";

function PaymentDetails({ studentData, setPage }) {
  const [description, setDescription] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  const toast = React.useContext(ToastContext);
  const loading = React.useContext(LoadingContext);
  const alert = React.useContext(AlertContext);

  const [paymentPrompt, setPaymentPrompt] = React.useState({
    open: false,
    paymentInfo: {},
    paymentFailureHandler: () => { },
  });

  const [membership, setMembership] = React.useState({
    primary_membership: "",
    secondary_membership: "",
  });

  const [basicMembershipState, setBasicMembershipState] = React.useState(false);

  const [secondaryMembershipState, setSecondaryMembershipState] =
    React.useState({
      premiumMember: false,
      lifeTimeMember: false,
    });

  const basicMemberCardClick = (event) => {
    setBasicMembershipState((prevVal) => {
      if (prevVal) {
        setMembership((membership) => ({
          ...membership,
          primary_membership: "",
        }));
      } else {
        setMembership((membership) => ({
          ...membership,
          primary_membership: "basic_member",
        }));
      }
      return !prevVal;
    });
  };

  const premiumMemberCardClick = (event) => {
    setSecondaryMembershipState((value) => {
      if (value.premiumMember) {
        setMembership((membership) => ({
          ...membership,
          secondary_membership: "",
        }));
        return { ...value, premiumMember: false };
      }
      setMembership((membership) => ({
        ...membership,
        secondary_membership: "premium_member",
      }));
      return {
        premiumMember: true,
        lifeTimeMember: false,
      };
    });
  };

  const lifeTimeMemberCardClick = (event) => {
    setSecondaryMembershipState((value) => {
      if (value.lifeTimeMember) {
        setMembership((membership) => ({
          ...membership,
          secondary_membership: "",
        }));
        return { ...value, lifeTimeMember: false };
      }
      setMembership((membership) => ({
        ...membership,
        secondary_membership: "lifetime_member",
      }));
      return {
        premiumMember: false,
        lifeTimeMember: true,
      };
    });
  };

  const paymentSuccessHandler = (paymentRes) => {
    registrationService
      .alumniRegistration(studentData.id, paymentRes)
      .then((res) => {
        if (res.data.status === "success") {
          alert.setValue({
            open: true,
            message: "Payment verified successfully.",
            status: res.data.status,
            title: res.data.message,
            response: () => {
              alert.handleClose();
              handleRedo(1);
            },
            isCancellable: false,
          });
          loading.decrementLoading();
        } else {
          alert.setValue({
            open: true,
            message: `Something went wrong,${res.data.data}`,
            status: "error",
            title: res.data.message,
            response: () => {
              alert.handleClose();
            },
          });
          loading.decrementLoading();
        }
      })
      .catch((err) => {
        alert.setValue({
          open: true,
          message: `something went wrong,${err}`,
          status: "error",
          title: "Payment verification failed",
          response: () => {
            alert.handleClose();
          },
        });
        loading.decrementLoading();
      });
    setPaymentPrompt({
      open: false,
      paymentInfo: {},
      paymentFailureHandler: () => { },
    });
  };

  const paymentFailureHandler = (paymentRes) => {
    setPaymentPrompt({
      open: false,
      paymentInfo: {},
      paymentFailureHandler: () => { },
    });
    alert.setValue({
      open: true,
      message: `${paymentRes.error.description}, Please try again`,
      status: "error",
      title: "Payment Failed",
      response: () => {
        alert.handleClose();
      },
    });
    loading.decrementLoading();
  };

  const handleContinue = () => {
    const isSelected =
      basicMembershipState ||
      secondaryMembershipState.lifeTimeMember ||
      secondaryMembershipState.premiumMember;
    if (!isSelected) {
      loading.decrementLoading();
      toast.setValue({
        open: true,
        status: "error",
        message: "Select any Membership Plan",
      });
      return;
    }
    if (!isChecked) {
      toast.setValue({
        open: true,
        message: "Please check T&C and Privacy Policy",
        status: "error",
      });
      return;
    }
    loading.incrementLoading();
    registrationService
      .createRegistrationPayment(studentData.id, membership)
      .then((res) => {
        if ((res.data.status = "success")) {
          let paymentInfo = res.data.data;
          paymentInfo.handler = paymentSuccessHandler;
          paymentInfo.modal = paymentModalHandler;
          console.log(paymentInfo);
          setPaymentPrompt({
            open: true,
            paymentInfo: res.data.data,
            paymentFailureHandler: paymentFailureHandler,
          });
        } else {
          loading.decrementLoading();
          toast.setValue({
            open: true,
            message: res.data.message,
            status: res.data.status,
          });
        }
      })
      .catch((err) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: "Something went wrong, please try again",
          status: "error",
        });
      });
  };
  const paymentModalHandler = {
    ondismiss: () => {
      setPaymentPrompt({
        open: false,
        paymentInfo: {},
        paymentFailureHandler: () => { },
      });
      alert.setValue({
        open: true,
        message: "Please try again",
        status: "error",
        title: "Payment Failed",
        response: () => {
          alert.handleClose();
        },
      });
      loading.decrementLoading();
    },
    handleback: true,
    confirm_close: true,
  };

  const basicDescription = {
    type: "Basic",
    title: "Basic Membership?",
    description: ["Life Time Membership", "No Voting Power"],
  };

  const premiumDescription = {
    type: "Premium",
    title: "Premium Membership?",
    description: ["Three year membership", "Voting power"],
  };

  const lifeTimeDescription = {
    type: "Life Time",
    title: "LifeTime Membership?",
    description: ["Life Time Membership", "Voting Power"],
  };

  const handleModalOpen = (event) => {
    const name = event.target.name;
    if (name === "basic") {
      setDescription(basicDescription);
    }
    if (name === "premium") {
      setDescription(premiumDescription);
    }
    if (name === "lifeTime") {
      setDescription(lifeTimeDescription);
    }
    setModal(true);
  };

  const handleModalClose = () => {
    setModal(false);
    setDescription(null);
  };

  const handleRedo = (pageNo) => {
    setPage(pageNo);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
        }}
        open={loading.isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="caption">
          Please do not press the reload button or back button
        </Typography>
        <Typography variant="caption">
          when the transaction process has been started.
        </Typography>
      </Backdrop>
      {paymentPrompt.open ? (
        <PaymentPrompt
          paymentInfo={paymentPrompt.paymentInfo}
          paymentFailureHandler={paymentFailureHandler}
        />
      ) : null}
      <DescriptionModel
        data={description}
        open={modal}
        close={handleModalClose}
      />

      <div className="container-fluid p-3 mb-3 text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3>Hello, {studentData.name}</h3>
        <h5 className="mt-2">Choose your Membership Plan</h5>
        <div className="row p-4 pb-1" style={{ width: '90%' }}>
          <div className="col-12 col-lg-4 mb-2">
            <BasicCard
              state={basicMembershipState}
              onClick={basicMemberCardClick}
            />
            <div className="pt-3 text-center mb-2">
              <Button variant="text" name="basic" onClick={handleModalOpen}>
                {basicDescription.title}
              </Button>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-2">
            <PremiumCard
              state={secondaryMembershipState.premiumMember}
              onClick={premiumMemberCardClick}
            />
            <div className="pt-3 text-center">
              <Button variant="text" name="premium" onClick={handleModalOpen}>
                {premiumDescription.title}
              </Button>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-2">
            <LifeTimeCard
              state={secondaryMembershipState.lifeTimeMember}
              onClick={lifeTimeMemberCardClick}
            />
            <div className="pt-3 text-center">
              <Button variant="text" name="lifeTime" onClick={handleModalOpen}>
                {lifeTimeDescription.title}
              </Button>
            </div>
          </div>
        </div>
        <ButtonAlreadyMember />
        <div style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px"
        }}>
          <Checkbox
            onChange={(e) => {
              setIsChecked(e.target.checked);
            }}
          />
          By checking, I read and agree to the &nbsp;<TermsAndPolicy />
        </div>
        <div>
          <Button
            variant="outlined"
            className="me-2"
            onClick={() => {
              handleRedo(2);
            }}
          >
            {" "}
            {"Previous <<"}
          </Button>
          <LoadingButton
            loading={loading.isLoading}
            onClick={handleContinue}
            variant="outlined"
          >
            Continue
          </LoadingButton>
        </div>
      </div>
    </>
  );
}

export default PaymentDetails;
