import { Skeleton } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import "./PreHeaderArea.css";

//Pre header component
export default function PreHeaderArea({ alumniInfoData }) {
  return (
    <div className="pre-header-area container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="pre-header-left navbar-expand px-md-1 ms-md-0 ps-md-2 pt-2 pb-2">
            <a
              href={
                "mailto:" +
                (alumniInfoData.email != null ? alumniInfoData.email : "")
              }
            >
              <EmailIcon sx={{ fontSize: "1rem" }} />
              &nbsp;
              {alumniInfoData.email != null ? (
                alumniInfoData.email
              ) : (
                <Skeleton variant="rectangular" width={215} height={0} />
              )}
            </a>
            <a>
              <CallIcon sx={{ fontSize: "1rem" }} />
              &nbsp;
              {alumniInfoData.phone != null ? (
                "+91 " + alumniInfoData.phone
              ) : (
                <Skeleton variant="rectangular" width={100} height={0} />
              )}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
