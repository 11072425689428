import React from "react";
import ToastContext from "../../context/ToastContext";
import dataService from "../../service/dataService";
import {
  Grid,
  Typography,
  Button,
  InputBase,
  Card,
  CardActionArea,
  Box,
  Avatar,
  Stack,
  Tooltip,
  Chip,
  Paper,
  Toolbar,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../context/LoadingContext";
import AlertContext from "../../context/AlertContext";
import pages from "../../constants/pages";

function StudentList({ setPage, academicDetails, setStudentData }) {
  const toast = React.useContext(ToastContext);

  const navigate = useNavigate();

  const loading = React.useContext(LoadingContext);
  const alert = React.useContext(AlertContext);

  const [studentList, setStudentList] = React.useState([]);
  const [searchedList, setSearchedList] = React.useState([]);

  const requestSearch = (searchedVal) => {
    searchedVal = searchedVal.toUpperCase();
    let filteredRows = studentList.filter((row) => {
      for (let x in row) {
        if (row[x] !== null && row[x] !== undefined) {
          let row_value = row[x].toUpperCase();
          let result = row_value.includes(searchedVal);
          if (result) return result;
        }
      }
      return false;
    });
    setSearchedList(filteredRows);
  };

  React.useEffect(() => {
    loading.incrementLoading();
    dataService
      .getStudentList(academicDetails.batch_id)
      .then((res) => {
        setStudentList(res.data.data);
        setSearchedList(res.data.data);
      })
      .catch((err) => {
        alert.setValue({
          open: true,
          message: "Something went wrong",
          status: "error",
        });
      })
      .finally((res) => {
        loading.decrementLoading();
      });
  }, []);

  const cantFindMeNote =
    "If your name is not listed above. Click on me and fill the form for confirmation";

  const handleCantFindMe = () => {
    navigate(pages.TEMP_REGISTRATION);
  };

  const handleSubmit = (value) => {
    setStudentData(value);
    setPage(2);
  };

  const prop = {
    academicDetails,
    requestSearch,
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "1rem", md: "2rem" },
          border: "none",
          boxShadow: "none",
        }}
      >
        <Paper
          elevation={0}
          sx={{ width: "100%", mb: 2, padding: { sm: 1, md: 3 } }}
        >
          <EnhancedTableToolbar {...prop} />
          {loading.isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "3rem",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={25} />
            </Box>
          ) : null}
          <Grid container justifyContent="center" padding={2} gap={1.5}>
            {searchedList.map((value, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    width: { md: "18rem", sm: "17rem", xs: "98%" },
                    cursor: "pointer",
                    flexGrow: { xs: 0, sm: 0 },
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      if (value.registered === "true")
                        toast.setValue({
                          open: true,
                          message: "Already Member",
                          status: "warning",
                          close: () => {},
                        });
                      else handleSubmit(value);
                    }}
                  >
                    <Box sx={{ display: "flex", p: 1 }}>
                      <Avatar
                        variant="rounded"
                        sx={{ width: "2.5rem" }}
                        src="avatar1.jpg"
                      />
                      <Stack width={`calc(95% - 2.5rem)`}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          marginLeft={1.5}
                        >
                          <Grid item sx={{ display: "flex" }}>
                            <Typography
                              sx={{ fontSize: ".7rem" }}
                              color="text.secondary"
                            >
                              Admission No:&nbsp;
                            </Typography>
                            <Typography
                              sx={{ fontSize: ".7rem" }}
                              fontWeight={700}
                            >
                              {value.admission_no}
                            </Typography>
                          </Grid>
                          <Grid item sx={{ display: "flex" }}>
                            {" "}
                            {value.registered === "true" ? (
                              <Tooltip title="Already Member">
                                <Chip
                                  label="Registered"
                                  sx={{
                                    color: "white",
                                    background: "green",
                                    fontSize: "10px",
                                    paddingTop: "0",
                                    height: "19px",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                        <Typography noWrap marginLeft={1.5} fontWeight={700}>
                          {value.name}
                        </Typography>
                      </Stack>
                    </Box>
                  </CardActionArea>
                </Card>
              );
            })}
          </Grid>
          <Grid container justifyContent={"flex-end"}>
            <Grid item>
              <Tooltip title={cantFindMeNote}>
                <Button onClick={handleCantFindMe}>Can't find me?</Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Pervious page">
                <Button
                  sx={{ color: "black" }}
                  onClick={() => {
                    setPage(0);
                  }}
                >
                  Previous
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

const EnhancedTableToolbar = (props) => {
  const { requestSearch, academicDetails } = props;

  return (
    <Toolbar
      sx={{
        display: "flex",
        height: "auto",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "1rem",
        justifyContent: { sm: "space-between", xs: "center" },
      }}
    >
      <Typography
        textAlign={"center"}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Student List
        <Typography>
          {academicDetails.course} {academicDetails.batch}
        </Typography>
      </Typography>
      <div className="rounded border" style={{boxShadow:"1px 1px 1px 1px #888888"}}>
        <InputBase
          style={{
            width: "12rem",
            height: "2.5rem",
            paddingLeft: "1rem",
            borderRadius: "5px",
          }}
          placeholder="Search here..."
          inputProps={{
            "aria-label": "search",
          }}
          onChange={(e) => {
            requestSearch(e.target.value);
          }}
        />
        <SearchIcon />
      </div>
    </Toolbar>
  );
};

export default StudentList;
