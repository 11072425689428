import React from "react";
import { TextField, Grid, Autocomplete } from "@mui/material";
import country_state_district from "country_state_district";
function UpdateAddressInfo(props) {
  const { alumni, setAlumni, setUpdateData } = props;

  const countries = country_state_district
    .getAllCountries()
    .map((options) => options.name);
  const states = country_state_district
    .getAllStates()
    .map((options) => options.name);
  const districts = country_state_district
    .getAllDistricts()
    .map((options) => options.name);

  const handleFormChange = (newVal) => {
    setAlumni({
      ...alumni,
      [newVal.target.name]: newVal.target.value,
    });
    setUpdateData({
      type: "alumni",
      payload: { [newVal.target.name]: newVal.target.value },
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <div className="current-address">Permanent Address</div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Address Line 1"
                variant="outlined"
                size="small"
                value={alumni.address_line_1}
                name="address_line_1"
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-basic"
                size="small"
                label="Address Line 2"
                name="address_line_2"
                value={alumni.address_line_2}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Place"
                name="place"
                variant="outlined"
                size="small"
                value={alumni.place}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Post Office"
                variant="outlined"
                size="small"
                name="postoffice"
                value={alumni.postoffice}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Pin Code"
                variant="outlined"
                size="small"
                name="pincode"
                value={alumni.pincode}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                name="country"
                size="small"
                value={alumni.country}
                id="combo-box-demo"
                options={countries}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="country"
                    value={alumni.country}
                    onChange={handleFormChange}
                    label="Country"
                  />
                )}
                onSelect={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                size="small"
                id="combo-box-demo"
                options={states}
                name="state"
                value={alumni.state}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="state"
                    label="State"
                    value={alumni.state}
                    onChange={handleFormChange}
                  />
                )}
                onSelect={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                name="district"
                size="small"
                id="combo-box-demo"
                value={alumni.district}
                options={districts.filter(function onlyUnique(
                  value,
                  index,
                  self
                ) {
                  return self.indexOf(value) === index;
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="district"
                    label="District"
                    value={alumni.district}
                    onChange={handleFormChange}
                  />
                )}
                onSelect={handleFormChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <div className="current-address">Communication Address</div>
          <div className="address2">
            <input
              type="checkbox"
              id="is_communication_address_same"
              name="is_communication_address_same"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                if (e.target.checked) {
                  const newVal = {
                    communication_address_line_1: alumni.address_line_1,
                    communication_address_line_2: alumni.address_line_2,
                    communication_place: alumni.place,
                    communication_postoffice: alumni.postoffice,
                    communication_pincode: alumni.pincode,
                    communication_country: alumni.country,
                    communication_state: alumni.state,
                    communication_district: alumni.district,
                  };
                  setAlumni((alumni) => ({
                    ...alumni,
                    ...newVal,
                  }));
                  setUpdateData({
                    type: "alumni",
                    payload: { ...newVal },
                  });
                } else {
                  const newVal = {
                    communication_address_line_1: "",
                    communication_address_line_2: "",
                    communication_place: "",
                    communication_postoffice: "",
                    communication_pincode: "",
                    communication_country: "",
                    communication_state: "",
                    communication_district: "",
                  };
                  setAlumni((alumni) => ({
                    ...alumni,
                    ...newVal,
                  }));
                  setUpdateData({
                    type: "alumni",
                    payload: { ...newVal },
                  });
                }
              }}
            />
            <label htmlFor="address2"> Same as permanent address</label>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Address Line 1"
                variant="outlined"
                size="small"
                name="communication_address_line_1"
                value={alumni.communication_address_line_1}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Address Line 2"
                variant="outlined"
                size="small"
                name="communication_address_line_2"
                value={alumni.communication_address_line_2}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Place"
                variant="outlined"
                size="small"
                name="communication_place"
                value={alumni.communication_place}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Post Office"
                variant="outlined"
                size="small"
                name="communication_postoffice"
                value={alumni.communication_postoffice}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Pin Code"
                variant="outlined"
                size="small"
                name="communication_pincode"
                value={alumni.communication_pincode}
                onChange={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                name="communication_country"
                size="small"
                id="combo-box-demo"
                options={countries}
                value={alumni.communication_country}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="communication_country"
                    value={alumni.communication_country}
                    onChange={handleFormChange}
                    label="Country"
                  />
                )}
                onSelect={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                name="communication_state"
                size="small"
                id="combo-box-demo"
                options={states}
                value={alumni.communication_state}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    name="communication_state"
                    value={alumni.communication_state}
                    onChange={handleFormChange}
                  />
                )}
                onSelect={handleFormChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                name="communication_district"
                size="small"
                id="combo-box-demo"
                value={alumni.communication_district}
                options={districts.filter(function onlyUnique(
                  value,
                  index,
                  self
                ) {
                  return self.indexOf(value) === index;
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="District"
                    name="communication_district"
                    value={alumni.communication_district}
                    onChange={handleFormChange}
                  />
                )}
                onSelect={handleFormChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateAddressInfo;
