import React from 'react'
import AdminDashboard from '../../../components/admin-dashboard/AdminDashboard'
import './PageAdminHome.css'

function PageAdminHome() {
  return (
    <div className='adminLogin vh-100'>
      <div className="container-fluid">
        <AdminDashboard />
      </div>
    </div>
  )
}

export default PageAdminHome