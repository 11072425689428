import api from "./api";

const alumniService = {
  getApplicantIdAndName: (email, mobile) => {
    return api.post(
      "/main/get_applicant_id_and_name_by_email_and_mobile",
      JSON.stringify({ email, mobile })
    );
  },
  createPaymentFroUpgradeMembership: (applicant_id, data) => {
    return api.post(
      `/main/create_payment_for_upgrade_membership/${applicant_id}`,
      JSON.stringify(data)
    );
  },
  getAlumniCount: () => {
    return api.get("/home/get_alumni_count");
  },
  postNewsLetterEmail: (email) => {
    return api.post("/home/add_email_to_news_letter", JSON.stringify(email))
  },
  getAlumniProfile: (token) => {
    return api.get(`/main/get_alumni_profile/${token}`)
  },
  updateAlumniProfile: (token, updateData) => {
    return api.post(`/main/edit_alumni_profile/${token}`, JSON.stringify(updateData))
  }
};

export default alumniService;
