// import { CheckCircleOutline } from '@mui/icons-material'
// import React from 'react'

// function DescriptionItem({ title }) {
//     console.log(title);
//     return (
//         <>
//             <CheckCircleOutline className='me-2' /><b>{title}</b>
//         </>

//     )
// }

// export default DescriptionItem

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
};

export default function DescriptionModel({ data, open, close }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {data != null ? <b>{data.type}</b> : ""}
          </Typography>
          <ul className="list">
          {data != null
            ? data.description.map((val, index) => {
                return (
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h4"
                    key={index}
                  >
                    <li>{val}</li>
                  </Typography>
                );
              })
            : ""}
            </ul>
        </Box>
      </Fade>
    </Modal>
  );
}
