const pages = {
    HOME: '/',
    GALLERY: '/albums',
    ABOUT_US: '/about-us',
    CONTACT_US:'/contact-us',
    NEW_REGISTRATION: '/new-registration',
    TEMP_REGISTRATION: '/new-membership',
    UPGRADE_MEMBERSHIP: '/upgrade-membership',
    ADMIN: '/admin',
    ADMIN_LOGIN: '/admin/login',
    admin: {
        ALUMNI_LIST: 'alumni-list',
        MEMBER_REQUESTS: 'membership-requests/request-list',
        FORM_CONTROL: 'form-control',
        ALUMNI_PROFILE: 'edit-alumni-profile',
        TRANSACTIONS_LIST: 'membership-requests/transaction-list',
        RETURN_FOR_PAYMENT: 'membership-requests/return-for-payment-list',
    },
    EDIT_ALUMNI_PROFILE: '/alumni/edit-profile'
}

export default pages;