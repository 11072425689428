import React from "react";
import member from "../../../assets/img/fun-fact/user.svg";
import pictures from "../../../assets/img/fun-fact/picture.svg";
// import imagesFolder from "../../../assets/animation-function/gallery_img_fetching";
import { images } from "../../../pages/public/album/photos";
import alumniService from "../../../service/alumniService";
import counting_service from "../../../assets/animation-function/counting_function";
import "./MemberStatistics.css";

// total members and pictures static card component
export default function MemberStatistics() {
  React.useEffect(() => {
    Reset();
  }, []);
  return (
    <section id="funfact-area">
      <div className="container-fluid">
        <div className="row text-center">
          <div className="col-lg-6 col-sm-6">
            <div className="single-funfact-wrap m-md-0 ">
              <div className="funfact-icon">
                <img src={member} alt="Funfact" />
              </div>
              <div
                className="funfact-info"
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                <h5
                  className="funfact-count"
                  id="members"
                  style={{
                    fontSize: "2.5rem",
                    textAlign: "center",
                    cursor: "pointer",
                    fontFamily: "Didone room",
                  }}
                  onClick={Reset}
                >
                  0
                </h5>
                <p>Members</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 ">
            <div className="single-funfact-wrap ml-0 m-0">
              <div className="funfact-icon">
                <img src={pictures} alt="Funfact" />
              </div>
              <div
                className="funfact-info"
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                <h5
                  className="funfact-count"
                  id="photos"
                  style={{
                    fontSize: "2.5rem",
                    textAlign: "center",
                    cursor: "pointer",
                    fontFamily: "Didone room",
                  }}
                  onClick={Reset}
                >
                  0
                </h5>
                <p className="bottom-limit">Photos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// ================================== Counting Function ========================================================

const Reval = async (photos, members) => {
  let totalMembers = 0;
  let totalPhotos = 0;
  await alumniService
    .getAlumniCount()
    .then(async (res) => {
      totalMembers = res.data.data;
      images.map((count) => {
        totalPhotos = totalPhotos + count.photos.length;
        return null
      });
    })
    .then(() => {
      counting_service.animate(members, 0, totalMembers, 1000);
      counting_service.animate(photos, 0, totalPhotos, 1000);
    });
};

// to count on initial loading
let number_of_count = 0;
window.addEventListener("scroll", () => {
  let element1 = document.querySelector(".bottom-limit");
  if (element1 != null) {
    if (
      element1.getBoundingClientRect().top <= window.innerHeight &&
      number_of_count === 0
    ) {
      const photos = document.getElementById("photos");
      const members = document.getElementById("members");
      Reval(photos, members);
      number_of_count = 1;
    }
  }
});
//to count on onClick event
function Reset() {
  number_of_count = 0;
}
