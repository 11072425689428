import React from "react";
import PrimaryInfo from "../../../components/new-registration/PrimaryInfo";
import StudentList from "../../../components/new-registration/StudentList";
import UpdateDetails from "../../../components/new-registration/UpdateDetails";
import PaymentDetails from "../../../components/new-registration/PaymentDetails";
import { Link } from "react-router-dom";

function RegistrationForm() {
  const [page, setPage] = React.useState(0);
  const [academicDetails, setAcademicDetails] = React.useState({
    course: "",
    batch: "",
    batch_id: "",
    course_id: "",
  });
  const [studentData, setStudentData] = React.useState({
    student_id: "",
    admission_no: "",
    name: "",
    registered: false,
  });
  return (
    <>
      <div>
        {page === 0 ? (
          <PrimaryInfo
            setPage={setPage}
            setAcademicDetails={setAcademicDetails}
            academicDetails={academicDetails}
          />
        ) : (
          ""
        )}
        {page === 1 ? (
          <StudentList
            setPage={setPage}
            academicDetails={academicDetails}
            setStudentData={setStudentData}
          />
        ) : (
          ""
        )}
        {page === 2 ? (
          <UpdateDetails
            setPage={setPage}
            studentData={studentData}
            setStudentData={setStudentData}
          />
        ) : (
          ""
        )}
        {page === 3 ? (
          <PaymentDetails studentData={studentData} setPage={setPage} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default RegistrationForm;
