import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import adminService from "../../../service/adminService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ToastContext from "../../../context/ToastContext";

export default function BatchAddDialog({ programmeId, handleBatchAdd }) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const toast = React.useContext(ToastContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    adminService.addBatch(name, programmeId, startDate, endDate).then((res) => {
      console.log(res.data);
      res.data.status === "success"
        ? toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          })
        : toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
      setName("");
      setStartDate(null);
      setEndDate(null);
      handleClose();
      handleBatchAdd();
    });
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <AddCircleOutlineRoundedIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle>Add Batch</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required={true}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              name="name"
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={startDate}
                onChange={(value) => {
                  if (value !== null) value = value.toLocaleDateString("fr-CA");
                  setStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    fullWidth
                    required
                    size="small"
                    variant="outlined"
                  />
                )}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                inputFormat="dd/MM/yyyy"
                onChange={(value) => {
                  if (value !== null) value = value.toLocaleDateString("fr-CA");
                  setEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    fullWidth
                    required
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </LocalizationProvider>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Add</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
