import React from "react";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function UpdateBasicInfo(props) {
  const { alumni, setAlumni, setUpdateData, handleUpdateData } = props;
  const genders = ["Male", "Female", "Other"];

  const handleFormChange = (newVal) => {
    setAlumni({
      ...alumni,
      [newVal.target.name]: newVal.target.value,
    });
    setUpdateData({
      type: "alumni",
      payload: { [newVal.target.name]: newVal.target.value },
    });
  };
  return (
    <>
      <Grid container spacing={2} padding={"1rem"}>
        <Grid item xs={12} sm={5} md={3}>
          <TextField
            value={alumni.admission_no}
            fullWidth
            disabled
            type={"text"}
            id="outlined-basic"
            label="Admission No"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <FormControl required fullWidth size="small">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Programme"
              type={"text"}
              variant="outlined"
              size="small"
              name="programme"
              value={alumni.course}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <FormControl required fullWidth size="small">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Batch"
              type={"text"}
              variant="outlined"
              size="small"
              name="batch"
              value={alumni.batch}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <TextField
            disabled
            fullWidth
            id="outlined-basic"
            label="Email"
            type={"email"}
            variant="outlined"
            size="small"
            name="email"
            value={alumni.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            disabled
            fullWidth
            type={"tel"}
            id="outlined-basic"
            label="Mobile"
            value={alumni.mobile}
            variant="outlined"
            size="small"
            name="mobile"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            type="tel"
            id="outlined-basic"
            label="Whatsapp No"
            variant="outlined"
            size="small"
            name="whatsapp_no"
            value={alumni.whatsapp_no}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={6}>
          <TextField
            required
            fullWidth
            id="outlined-basic"
            label="Name"
            variant="outlined"
            size="small"
            name="name"
            value={alumni.name}
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              inputFormat="dd/MM/yyyy"
              value={alumni.dob}
              onChange={(newVal) => {
                if (newVal !== null)
                  newVal = newVal.toLocaleDateString("fr-CA");
                setAlumni({
                  ...alumni,
                  dob: newVal,
                });
                setUpdateData({
                  type: "alumni",
                  payload: { dob: newVal },
                });
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth required size={"small"} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <FormControl required fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={alumni.gender}
              label="Gender"
              name="gender"
              required
              onChange={handleFormChange}
            >
              {genders.map((value, key) => {
                return (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateBasicInfo;
