import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SchoolIcon from "@mui/icons-material/School";
import { Stack } from "@mui/material";
import React from "react";
import EducationDialog from "../temporary-registration/education/education-popup/EducationDialog";
function UpdateEducation(props) {
  const { alumni, setAlumni, setUpdateData } = props;
  const [educationOpen, setEducationOpen] = React.useState(false);
  const [newEducation, setNewEducation] = React.useState({
    applicant_education: [],
  });
  const [deleteEducation, setDeleteEducation] = React.useState([]);

  React.useEffect(() => {
    setUpdateData({
      type: "add",
      sub_type: "alumni_education",
      payload: newEducation.applicant_education,
    });
  }, [newEducation]);

  const handelDeleteEducation = (education_id) => {
    setAlumni((alumni) => {
      const newList = alumni.alumni_education.filter((value, index) => {
        return value.id !== education_id;
      });
      return {
        ...alumni,
        alumni_education: newList,
      };
    });
    setDeleteEducation((preVal) => [...preVal, education_id]);
  };

  const handleRemoveEducation = (arrayIndex) => {
    const newList = newEducation.applicant_education.filter((value, index) => {
      return index !== arrayIndex;
    });
    setNewEducation((preVal) => ({ ...preVal, applicant_education: newList }));
  };

  React.useEffect(() => {
    setUpdateData({
      type: "delete",
      sub_type: "alumni_education",
      payload: deleteEducation,
    });
  }, [deleteEducation]);

  const handleAddButton = () => {
    setEducationOpen(true);
  };
  const handleEducationDialogClose = () => {
    setEducationOpen(false);
  };
  return (
    <>
      <EducationDialog
        open={educationOpen}
        close={handleEducationDialogClose}
        setData={{ formData: newEducation, setFormData: setNewEducation }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={700}>Education Details</Typography>
        <Button onClick={handleAddButton}>Add New</Button>
      </div>
      <Grid container spacing={2}>
        {alumni.alumni_education.map((row, index) => {
          let from = new Date(row.from);
          from = from.toLocaleDateString("en-US");
          let to;
          if (row.to !== null && row.to !== "null") {
            to = new Date(row.to);
            to = to.toLocaleDateString("en-US");
          } else to = "Present";
          return (
            <Grid item key={index} xs={12} sm={6}>
              <Card>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Avatar variant="rounded" sx={{ width: 60, height: 60 }}>
                    <SchoolIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Stack spacing={0.5} width={"100%"}>
                    <Typography fontWeight={400}>
                      {row.programme}
                      <Typography variant="caption" color="text.secondary">
                        {" " + from + " - " + to}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <AccountBalanceIcon /> {row.institution}
                    </Typography>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      handelDeleteEducation(row.id);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
        {newEducation.applicant_education.map((row, index) => {
          let from = new Date(row.from);
          from = from.toLocaleDateString("en-US");
          let to;
          if (row.to !== null && row.to !== "null") {
            to = new Date(row.to);
            to = to.toLocaleDateString("en-US");
          } else to = "Present";
          return (
            <Grid item key={index} xs={12} sm={6}>
              <Card>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Avatar variant="rounded" sx={{ width: 60, height: 60 }}>
                    <SchoolIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                  <Stack spacing={0.5} width={"100%"}>
                    <Typography fontWeight={400}>
                      {row.programme}
                      <Typography variant="caption" color="text.secondary">
                        {" " + from + " - " + to}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <AccountBalanceIcon /> {row.institution}
                    </Typography>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      handleRemoveEducation(index);
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default UpdateEducation;
