import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField, Grid } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "./EducationDialog.css";
export default function EducationDialog({ setData, open, close }) {
  const [education, setEducation] = React.useState({
    programme: "",
    institution: "",
    from: null,
    to: null,
  });
  const [toState, setToState] = React.useState(false);

  const handleClose = () => {
    close(false);
    setToState(false);
    setEducation({
      programme: "",
      institution: "",
      from: null,
      to: null,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let currentEducation = setData.formData.applicant_education;
    currentEducation.push(education);
    setData.setFormData({
      ...setData.formData,
      applicant_education: currentEducation,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
      <DialogTitle sx={{ mt: 1 }}>Add your Education</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container sx={{ p: 1 }} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="programme"
                variant="outlined"
                label="Programme"
                required
                size="small"
                name="programme"
                value={education.programme}
                onChange={(newVal) => {
                  setEducation((education) => ({
                    ...education,
                    programme: newVal.target.value,
                  }));
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Institution"
                name="institution"
                required
                size="small"
                value={education.institution}
                onChange={(newVal) => {
                  setEducation((education) => ({
                    ...education,
                    institution: newVal.target.value,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  name="from"
                  inputFormat="dd/MM/yyyy"
                  value={education.from}
                  onChange={(newVal) => {
                    if (newVal !== null)
                      newVal = newVal.toLocaleDateString("fr-CA");
                    setEducation((education) => ({
                      ...education,
                      from: newVal,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      required
                      name="from"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "-9px", marginTop: "-8px" }}>
              <input
                type="checkBox"
                label="current"
                name="current"
                className="current-checkbox"
                onChange={(event) => {
                  if (event.target.checked) {
                    setEducation((education) => ({
                      ...education,
                      to: null,
                    }));
                    setToState(true);
                  } else {
                    setToState(false);
                  }
                }}
              />
              <label className="current">
                I'm currently studying this programme
              </label>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  name="to"
                  disabled={toState}
                  inputFormat="dd/MM/yyyy"
                  value={education.to}
                  onChange={(newVal) => {
                    if (newVal !== null)
                      newVal = newVal.toLocaleDateString("fr-CA");
                    setEducation((education) => ({
                      ...education,
                      to: newVal,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      required
                      name="to"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" autoFocus>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
