import React, { useEffect, useState } from 'react'
import './CommitteeDetails.css'
import MemberCard from '../member-card/MemberCard';
import dataService from '../../../service/dataService';

function CommitteeDetails() {
    const [data, setData] = useState();
    useEffect(() => {
        dataService.getCommitteeList().then(res => {
            setData(res.data.data);
        })
    }, []);
    return (
        <div id='committee'>
            <div className='container p-5 mt-3'>
                <div className='row text-center mt-5 mb-3'>
                    <div className='col-md-12'>
                        <h1 className='committee-title'>Our Honorable Committee</h1>
                    </div>
                </div>
                <div className='row d-flex justify-content-center text-center'>
                    {data && data.map(item => {
                        return <MemberCard key={item.id} picture={item.img_url} name={item.name} position={item.position} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default CommitteeDetails