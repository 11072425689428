import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import adminService from "../../../service/adminService";
import MenuItem from "@mui/material/MenuItem";
import ToastContext from "../../../context/ToastContext";

function ProgrammeEditDialog({
  open,
  programmeId,
  handleProgrammeEdit,
  close,
}) {
  const [name, setName] = React.useState([]);
  const [programmeType, setProgrammeType] = React.useState("");
  const toast=React.useContext(ToastContext);

  React.useEffect(() => {
    if (open)
      adminService.getProgramme(programmeId).then((res) => {
        if (typeof res.data === "object") {
          setName(res.data.data.name);
          setProgrammeType(res.data.data.course_type);
        }
      });
  }, [programmeId]);

  const handleClose = () => {
    close();
    setName("");
    setProgrammeType("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    adminService.editProgramme(programmeId, name, programmeType).then((res) => {
      res.data.status === "success"
        ? toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          })
        : toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
      if (typeof res.data === "object") {
        handleProgrammeEdit();
        handleClose();
      }
    });
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Programme</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required={true}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              name="name"
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
            <TextField
              id="outlined-select-currency"
              select
              required
              label="Select"
              fullWidth
              size="small"
              margin="dense"
              variant="outlined"
              value={programmeType}
              onChange={(event) => {
                setProgrammeType(event.target.value);
              }}
            >
              <MenuItem value="Aided">Aided</MenuItem>
              <MenuItem value="SF">SF</MenuItem>
            </TextField>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ProgrammeEditDialog;
