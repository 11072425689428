import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  DialogTitle,
  Grid,
  InputLabel,
  TextareaAutosize,
  FormControl,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function ReturnDialog({
  open,
  close,
  handleSubmit,
  setReturnReason,
}) {
  const [textArea, setTextArea] = React.useState(false);
  const [textAreaVal, setTextAreaVal] = React.useState("");
  const [formControl, setFormControl] = React.useState("");

  const handleClose = () => {
    close();
    setReturnReason("");
    setTextArea(false);
    setTextAreaVal("");
    setFormControl("");
  };

  const handleSelectChange = (event) => {
    if (event.target.value == "Other") {
      setTextArea(true);
      setFormControl(event.target.value);
      setReturnReason(event.target.value);
    } else {
      setTextArea(false);
      setFormControl(event.target.value);
      setReturnReason(event.target.value);
    }
  };
  const textAreaChange = (event) => {
    setTextAreaVal(event.target.value);
    setReturnReason(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"xs"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: { md: "400px" }, // Set your width here
          },
        },
      }}
    >
      <DialogTitle sx={{ mt: "1rem" }}>Reason for Returning Application</DialogTitle>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
          handleClose();
        }}
      >
        <DialogContent>
          <FormControl fullWidth size="small">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel id="select-reason">Reason for Returning Application</InputLabel>
                <Select
                  labelId="select-reason"
                  id="select-reason"
                  fullWidth
                  required
                  label="Reason for Returning Application"
                  value={formControl}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="Wrong membership selected">
                    Wrong Membership selected
                  </MenuItem>
                  <MenuItem value="Given details are not authentic">
                    Given details are not authentic
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  required
                  disabled={!textArea}
                  onChange={textAreaChange}
                  value={textAreaVal}
                  style={{
                    width: "100%",
                  }}
                  minRows={5}
                  maxRows={5}
                ></TextareaAutosize>
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button autoFocus color="primary" variant="contained" type="submit">
            Return Application
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
