import React from 'react'
import ReturnForPaymentTable from '../../../components/membership-requests/return-for-payment/ReturnForPaymentTable'

function ReturnForPayment() {
    return (
        <div className='container-fluid p-4'>
            <ReturnForPaymentTable />
        </div>
    )
}

export default ReturnForPayment