import React from "react";
import { Outlet } from "react-router-dom";
import PreHeaderArea from "../../components/user-home/pre-header-area/PreHeaderArea";
import Navbar from "../../components/user-home/navbar/Navbar";
import Footer from "../../components/user-home/footer/Footer";
import dataService from "../../service/dataService";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import "./Public.css";
export default function Public() {
  // assign email and phone number to variables
  const [alumniInfoData, setAlumniInfoData] = React.useState({
    email: null,
    phone: null,
    name: null,
  });
  React.useEffect(() => {
    dataService.getAlumniContact().then((res) => {
      setAlumniInfoData({
        email: res.data.data.email,
        phone: res.data.data.mobile,
        name: res.data.data.name,
      });
    });
  }, []);

  // to scroll top of the page when click the float button
  const top = () => {
    const button = window.document.getElementById("scroll-top");
    if(button === null) return;
    if (window.pageYOffset < 70) {
      button.style.zIndex = 0;
      button.style.opacity = 0;
    } else {
      button.style.zIndex = 2;
      button.style.opacity = 1;
    }
  };
  window.addEventListener("scroll", top);
  return (
    <>
      <PreHeaderArea alumniInfoData={alumniInfoData} />
      <Navbar />
      <button
        id="scroll-top"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <KeyboardDoubleArrowUpIcon />
      </button>
      <Outlet/>
      <Footer alumniInfoData={alumniInfoData} />
    </>
  );
}

// Function to hide sticky button under footer
const AlbumStickyButtonHideOnFooterScroll = () => {
  const buttons = document.getElementById("album-category-button");
  const footer = document.getElementById("footer-area");
  if (buttons != null && footer != null) {
    if (buttons.offsetTop + 41 >= footer.offsetTop) {
      buttons.style = "z-index:-1";
    }
    if (buttons.offsetTop + 41 <= footer.offsetTop) {
      buttons.style = "z-index:2";
    }
  }
};

window.addEventListener("scroll", AlbumStickyButtonHideOnFooterScroll);
