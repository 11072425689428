//==========================================College Pic========================================
import img_c1 from "../../../assets/img/gallery/college-pic/img-1.jpg";
import img_c2 from "../../../assets/img/gallery/college-pic/img-9.jpg";
import img_c3 from "../../../assets/img/gallery/college-pic/img-12.jpg";
import img_c4 from "../../../assets/img/gallery/college-pic/img-13.jpg";
import img_c5 from "../../../assets/img/gallery/college-pic/img-6.jpg";
import img_c6 from "../../../assets/img/gallery/college-pic/img-14.jpg";
import img_c7 from "../../../assets/img/gallery/college-pic/img-8.jpg";
import img_c8 from "../../../assets/img/gallery/college-pic/img-7.jpg";
import img_c9 from "../../../assets/img/gallery/college-pic/img-5.jpg";
import img_c10 from "../../../assets/img/gallery/college-pic/img-12.jpg";
import img_c11 from "../../../assets/img/gallery/college-pic/img-4.jpg";
import img_c12 from "../../../assets/img/gallery/college-pic/img-2.jpg";
//===================================1995-206 Alumni meetup Pic========================================
import img_u1 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img58.jpg";
import img_u2 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img2.jpg";
import img_u3 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img3.jpg";
import img_u4 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img4.jpg";
import img_u5 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img5.jpg";
import img_u6 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img6.jpg";
import img_u7 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img7.jpg";
import img_u8 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img8.jpg";
import img_u9 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img9.jpg";
import img_u10 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img10.jpg";
import img_u11 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img11.jpg";
import img_u12 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img12.jpg";
import img_u13 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img13.jpg";
import img_u14 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img14.jpg";
import img_u15 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img15.jpg";
import img_u16 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img16.jpg";
import img_u17 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img17.jpg";
import img_u18 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img18.jpg";
import img_u19 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img19.jpg";
import img_u20 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img20.jpg";
import img_u21 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img21.jpg";
import img_u22 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img22.jpg";
import img_u23 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img23.jpg";
import img_u24 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img24.jpg";
import img_u25 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img25.jpg";
import img_u26 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img26.jpg";
import img_u27 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img27.jpg";
import img_u28 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img28.jpg";
import img_u29 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img29.jpg";
import img_u30 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img30.jpg";
import img_u31 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img31.jpg";
import img_u32 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img32.jpg";
import img_u33 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img33.jpg";
import img_u34 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img34.jpg";
import img_u35 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img35.jpg";
import img_u36 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img36.jpg";
import img_u37 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img37.jpg";
import img_u38 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img38.jpg";
import img_u39 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img39.jpg";
import img_u40 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img40.jpg";
import img_u41 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img41.jpg";
import img_u42 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img42.jpg";
import img_u43 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img43.jpg";
import img_u44 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img44.jpg";
import img_u45 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img45.jpg";
import img_u46 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img46.jpg";
import img_u47 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img47.jpg";
import img_u48 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img48.jpg";
import img_u49 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img49.jpg";
import img_u50 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img50.jpg";
import img_u51 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img51.jpg";
import img_u52 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img52.jpg";
import img_u53 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img53.jpg";
import img_u54 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img1.jpg";
import img_u55 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img55.jpg";
import img_u56 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img56.jpg";
import img_u57 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img57.jpg";
import img_u58 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img70.jpg";
import img_u59 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img59.jpg";
import img_u60 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img60.jpg";
import img_u61 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img61.jpg";
import img_u62 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img62.jpg";
import img_u63 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img63.jpg";
import img_u64 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img64.jpg";
import img_u65 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img65.jpg";
import img_u66 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img66.jpg";
import img_u67 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img67.jpg";
import img_u68 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img68.jpg";
import img_u69 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img69.jpg";
import img_u70 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img54.jpg";
import img_u71 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img71.jpg";
import img_u72 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img72.jpg";
import img_u73 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img73.jpg";
import img_u74 from "../../../assets/img/gallery/events/1995-2016-alumni-meet/img74.jpg";
//=======================================Police Meet===========================================
import img_p1 from "../../../assets/img/gallery/events/police-meet/img4.jpg";
import img_p2 from "../../../assets/img/gallery/events/police-meet/img6.jpg";
import img_p3 from "../../../assets/img/gallery/events/police-meet/img2.jpg";
import img_p4 from "../../../assets/img/gallery/events/police-meet/img5.jpg";
import img_p5 from "../../../assets/img/gallery/events/police-meet/img3.jpg";
import img_p6 from "../../../assets/img/gallery/events/police-meet/img7.jpg";
import img_p7 from "../../../assets/img/gallery/events/police-meet/img8.jpg";
import img_p8 from "../../../assets/img/gallery/events/police-meet/img9.jpg";
import img_p9 from "../../../assets/img/gallery/events/police-meet/img10.jpg";
import img_p10 from "../../../assets/img/gallery/events/police-meet/img11.jpg";
import img_p11 from "../../../assets/img/gallery/events/police-meet/img12.jpg";
import img_p12 from "../../../assets/img/gallery/events/police-meet/img13.jpg";
import img_p13 from "../../../assets/img/gallery/events/police-meet/img14.jpg";
import img_p14 from "../../../assets/img/gallery/events/police-meet/img15.jpg";
import img_p15 from "../../../assets/img/gallery/events/police-meet/img16.jpg";
import img_p16 from "../../../assets/img/gallery/events/police-meet/img17.jpg";
import img_p17 from "../../../assets/img/gallery/events/police-meet/img18.jpg";
import img_p18 from "../../../assets/img/gallery/events/police-meet/img19.jpg";
import img_p19 from "../../../assets/img/gallery/events/police-meet/img20.jpg";
import img_p20 from "../../../assets/img/gallery/events/police-meet/img21.jpg";
import img_p21 from "../../../assets/img/gallery/events/police-meet/img22.jpg";
import img_p22 from "../../../assets/img/gallery/events/police-meet/img23.jpg";
import img_p23 from "../../../assets/img/gallery/events/police-meet/img24.jpg";
import img_p24 from "../../../assets/img/gallery/events/police-meet/img25.jpg";
import img_p25 from "../../../assets/img/gallery/events/police-meet/img26.jpg";
import img_p26 from "../../../assets/img/gallery/events/police-meet/img27.jpg";
import img_p27 from "../../../assets/img/gallery/events/police-meet/img28.jpg";
import img_p28 from "../../../assets/img/gallery/events/police-meet/img29.jpg";
import img_p29 from "../../../assets/img/gallery/events/police-meet/img1.jpg";
//================================IC-BALAKRISHNAN===========================================
import img_i1 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img1.jpg";
import img_i2 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img2.jpg";
import img_i3 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img3.jpg";
import img_i4 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img23.jpg";
import img_i5 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img4.jpg";
import img_i6 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img5.jpg";
import img_i7 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img6.jpg";
import img_i8 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img7.jpg";
import img_i9 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img8.jpg";
import img_i10 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img9.jpg";
import img_i11 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img10.jpg";
import img_i12 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img11.jpg";
import img_i13 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img12.jpg";
import img_i14 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img13.jpg";
import img_i15 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img14.jpg";
import img_i16 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img15.jpg";
import img_i17 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img16.jpg";
import img_i18 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img17.jpg";
import img_i19 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img18.jpg";
import img_i20 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img19.jpg";
import img_i21 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img20.jpg";
import img_i22 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img21.jpg";
import img_i23 from "../../../assets/img/gallery/events/inauguration-ic-balakrishnan/img22.jpg";
//====================================Thumbnails================================================
//=======================================Police Meet===========================================
import img_pt1 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img4.jpg";
import img_pt2 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img6.jpg";
import img_pt3 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img2.jpg";
import img_pt4 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img5.jpg";
import img_pt5 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img3.jpg";
import img_pt6 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img7.jpg";
import img_pt7 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img8.jpg";
import img_pt8 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img9.jpg";
import img_pt9 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img10.jpg";
import img_pt10 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img11.jpg";
import img_pt11 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img12.jpg";
import img_pt12 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img13.jpg";
import img_pt13 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img14.jpg";
import img_pt14 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img15.jpg";
import img_pt15 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img16.jpg";
import img_pt16 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img17.jpg";
import img_pt17 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img18.jpg";
import img_pt18 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img19.jpg";
import img_pt19 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img20.jpg";
import img_pt20 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img21.jpg";
import img_pt21 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img22.jpg";
import img_pt22 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img23.jpg";
import img_pt23 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img24.jpg";
import img_pt24 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img25.jpg";
import img_pt25 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img26.jpg";
import img_pt26 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img27.jpg";
import img_pt27 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img28.jpg";
import img_pt28 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img29.jpg";
import img_pt29 from "../../../assets/img/gallery/thumbnail/police-alumni-meet/img1.jpg";
//================================IC-BALAKRISHNAN===========================================
import img_it1 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img1.jpg";
import img_it2 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img2.jpg";
import img_it3 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img3.jpg";
import img_it4 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img23.jpg";
import img_it5 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img4.jpg";
import img_it6 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img5.jpg";
import img_it7 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img6.jpg";
import img_it8 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img7.jpg";
import img_it9 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img8.jpg";
import img_it10 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img9.jpg";
import img_it11 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img10.jpg";
import img_it12 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img11.jpg";
import img_it13 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img12.jpg";
import img_it14 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img13.jpg";
import img_it15 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img14.jpg";
import img_it16 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img15.jpg";
import img_it17 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img16.jpg";
import img_it18 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img17.jpg";
import img_it19 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img18.jpg";
import img_it20 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img19.jpg";
import img_it21 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img20.jpg";
import img_it22 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img21.jpg";
import img_it23 from "../../../assets/img/gallery/thumbnail/inauguration-ic-balakrishnan/img22.jpg";
const size = {
  width: "300vw",
  height: "150vh",
};

export const images = [
  {
    category: "College",
    title: "College Photos",
    description:
      "Alumni association demonstrates  the various stages of its historical milestones. By embracing the festive nature of brotherhood and harmony, the events conducted by Alumni Association triggers the prestige of college.",
    photos: [
      {
        src: img_c1,
        thumbnail: img_c1,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c2,
        thumbnail: img_c2,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
        tags: [{ value: "Main gate", title: "Main gate" }],
        caption: "Boats (Jeshu John - designerspics.com)",
      },

      {
        src: img_c3,
        thumbnail: img_c3,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c4,
        thumbnail: img_c4,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c5,
        thumbnail: img_c5,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c6,
        thumbnail: img_c6,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c7,
        thumbnail: img_c7,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c8,
        thumbnail: img_c8,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c9,
        thumbnail: img_c9,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c10,
        thumbnail: img_c10,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c11,
        thumbnail: img_c11,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_c12,
        thumbnail: img_c12,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
    ],
  },
  {
    category: "Alumni Meet",
    title: "Alumni Meet 1995-2016",
    description:
      "The wide network of alumni gather to enhance their relationship. It helps to strengthen their bondage with college. It imparts and maintains the cultural legacy of the college.",
    photos: [
      {
        src: img_u1,
        thumbnail: img_u1,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u2,
        thumbnail: img_u2,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u3,
        thumbnail: img_u3,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u4,
        thumbnail: img_u4,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u5,
        thumbnail: img_u5,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u6,
        thumbnail: img_u6,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u7,
        thumbnail: img_u7,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u8,
        thumbnail: img_u8,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u9,
        thumbnail: img_u9,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u10,
        thumbnail: img_u10,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u11,
        thumbnail: img_u11,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u12,
        thumbnail: img_u12,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u13,
        thumbnail: img_u13,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u14,
        thumbnail: img_u14,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u15,
        thumbnail: img_u15,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u16,
        thumbnail: img_u16,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u17,
        thumbnail: img_u17,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u18,
        thumbnail: img_u18,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u19,
        thumbnail: img_u19,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u20,
        thumbnail: img_u20,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u21,
        thumbnail: img_u21,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u22,
        thumbnail: img_u22,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u23,
        thumbnail: img_u23,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u24,
        thumbnail: img_u24,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u25,
        thumbnail: img_u25,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u26,
        thumbnail: img_u26,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u27,
        thumbnail: img_u27,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u28,
        thumbnail: img_u28,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u29,
        thumbnail: img_u29,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u30,
        thumbnail: img_u30,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u31,
        thumbnail: img_u31,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u32,
        thumbnail: img_u32,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u33,
        thumbnail: img_u33,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u34,
        thumbnail: img_u34,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u35,
        thumbnail: img_u35,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u36,
        thumbnail: img_u36,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u37,
        thumbnail: img_u37,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u38,
        thumbnail: img_u38,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u39,
        thumbnail: img_u39,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u40,
        thumbnail: img_u40,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u41,
        thumbnail: img_u41,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u42,
        thumbnail: img_u42,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u43,
        thumbnail: img_u43,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u44,
        thumbnail: img_u44,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u45,
        thumbnail: img_u45,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u46,
        thumbnail: img_u46,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u47,
        thumbnail: img_u47,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u48,
        thumbnail: img_u48,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u49,
        thumbnail: img_u49,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u50,
        thumbnail: img_u50,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u51,
        thumbnail: img_u51,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u52,
        thumbnail: img_u52,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u53,
        thumbnail: img_u53,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u54,
        thumbnail: img_u54,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u55,
        thumbnail: img_u55,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u56,
        thumbnail: img_u56,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u57,
        thumbnail: img_u57,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u58,
        thumbnail: img_u58,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u59,
        thumbnail: img_u59,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u60,
        thumbnail: img_u60,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u61,
        thumbnail: img_u61,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u62,
        thumbnail: img_u62,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u63,
        thumbnail: img_u63,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u64,
        thumbnail: img_u64,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u65,
        thumbnail: img_u65,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u66,
        thumbnail: img_u66,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u67,
        thumbnail: img_u67,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u68,
        thumbnail: img_u68,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u69,
        thumbnail: img_u69,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u70,
        thumbnail: img_u70,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u71,
        thumbnail: img_u71,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u72,
        thumbnail: img_u72,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u73,
        thumbnail: img_u73,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_u74,
        thumbnail: img_u74,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
    ],
  },
  {
    category: "Police Meet",
    title: "Police Alumni Meet 29-09-2015",
    description:
      "In its historical walls, Alumini police meet was a remarkable event. An unbeatable strength of police alumni made their active involvement for building a new space among other alumni. Despite the differences among the department and batches, they gather here with a unique sense of comradeship.",
    photos: [
      {
        src: img_p1,
        thumbnail: img_pt1,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p2,
        thumbnail: img_pt2,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p3,
        thumbnail: img_pt3,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p4,
        thumbnail: img_pt4,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p5,
        thumbnail: img_pt5,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p6,
        thumbnail: img_pt6,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p7,
        thumbnail: img_pt7,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p8,
        thumbnail: img_pt8,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p9,
        thumbnail: img_pt9,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p10,
        thumbnail: img_pt10,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p11,
        thumbnail: img_pt11,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p12,
        thumbnail: img_pt12,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p13,
        thumbnail: img_pt13,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p14,
        thumbnail: img_pt14,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p15,
        thumbnail: img_pt15,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p16,
        thumbnail: img_pt16,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p17,
        thumbnail: img_pt17,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p18,
        thumbnail: img_pt18,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p19,
        thumbnail: img_pt19,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p20,
        thumbnail: img_pt20,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p21,
        thumbnail: img_pt21,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p22,
        thumbnail: img_pt22,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p23,
        thumbnail: img_pt23,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p24,
        thumbnail: img_pt24,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p25,
        thumbnail: img_pt25,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p26,
        thumbnail: img_pt26,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p27,
        thumbnail: img_pt27,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p28,
        thumbnail: img_pt28,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_p29,
        thumbnail: img_pt29,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
    ],
  },
  {
    category: "Inauguration",
    title: "Inauguration 04-02-2016",
    description:
      "College Alumni association conducted various inaugural ceremonies in the college. It showcased well known personalities from politics, administration and religion. It made a new path in the footsteps of Alumni activities and ensured the easy access to public space.",
    photos: [
      {
        src: img_i1,
        thumbnail: img_it1,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i2,
        thumbnail: img_it2,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i3,
        thumbnail: img_it3,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i4,
        thumbnail: img_it4,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i5,
        thumbnail: img_it5,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i6,
        thumbnail: img_it6,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i7,
        thumbnail: img_it7,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i8,
        thumbnail: img_it8,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i9,
        thumbnail: img_it9,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i10,
        thumbnail: img_it10,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i11,
        thumbnail: img_it11,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i12,
        thumbnail: img_it12,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i13,
        thumbnail: img_it13,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i14,
        thumbnail: img_it14,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i15,
        thumbnail: img_it15,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i16,
        thumbnail: img_it16,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i17,
        thumbnail: img_it17,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i18,
        thumbnail: img_it18,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i19,
        thumbnail: img_it19,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i20,
        thumbnail: img_it20,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i21,
        thumbnail: img_it21,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i22,
        thumbnail: img_it22,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
      {
        src: img_i23,
        thumbnail: img_it23,
        thumbnailWidth: size.width,
        thumbnailHeight: size.height,
      },
    ],
  },
];
