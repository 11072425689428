import React from "react";
import MembershipTable from "../../../components/membership-list/membership-table/MembershipTable";

export default function AlumniViewList() {
  return (
    <div className="container-fluid p-4">
        <MembershipTable />
    </div>
  );
}
