import React, {
  useEffect,
  useState,
} from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dataService from "../../../service/dataService";
import adminService from "../../../service/adminService";
import country_state_district from "country_state_district";
import ToastContext from "../../../context/ToastContext";
import LoadingContext from "../../../context/LoadingContext";
import AlreadyMemberCard from "../../membership-cards/AlreadyMemberCard";
import BasicCard from "../../membership-cards/BasicCard";
import PremiumCard from "../../membership-cards/PremiumCard";
import LifeTimeCard from "../../membership-cards/LifeTimeCard";
import RejectDialog from "../reject-dialog/RejectDialog";
import ReturnDialog from "../return-payment-dialog/ReturnDialog";

// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
const style = {
  position: "absolute",
  top: "5%",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 10,
  p: 4,
};
function VerificationModal({ open, applicantId, close }) {
  const [batches, setBatches] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [formData, setFormData] = useState({});
  const [applicantData, setApplicantData] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [returnDialog, setReturnDialog] = useState(false);
  const [reason, setReason] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    setIsOpen(false);
    close();
  };

  const [membership, setMembership] = useState({
    alreadyMember: false,
    basicMember: false,
    premiumMember: false,
    lifeTimeMember: false,
  });

  const toast = React.useContext(ToastContext);
  const loading = React.useContext(LoadingContext);

  const countries = country_state_district
    .getAllCountries()
    .map((options) => options.name);
  const states = country_state_district
    .getAllStates()
    .map((options) => options.name);
  const districts = country_state_district
    .getAllDistricts()
    .map((options) => options.name);

  useEffect(() => {
    if (applicantId === 0 || applicantId === null) {
      return;
    }
    if (open) {
      adminService.getAlumniApplicant(applicantId).then((applicant) => {
        loading.incrementLoading();
        dataService
          .getBatches(applicant.data.data.course_id)
          .then((batches) => {
            setBatches(batches.data.data);
            setTimeout(() => {
              setApplicantData(applicant.data.data);
              if (applicant.data.data.membership_amount >= 2600) {
                setMembership({
                  alreadyMember: false,
                  basicMember: true,
                  premiumMember: false,
                  lifeTimeMember: true,
                });
              } else if (applicant.data.data.membership_amount >= 2500) {
                setMembership({
                  alreadyMember: true,
                  basicMember: false,
                  premiumMember: false,
                  lifeTimeMember: true,
                });
              } else if (applicant.data.data.membership_amount >= 600) {
                setMembership({
                  alreadyMember: false,
                  basicMember: true,
                  premiumMember: true,
                  lifeTimeMember: false,
                });
              } else if (applicant.data.data.membership_amount >= 500) {
                setMembership({
                  alreadyMember: true,
                  basicMember: false,
                  premiumMember: true,
                  lifeTimeMember: false,
                });
              } else if (applicant.data.data.membership_amount >= 100) {
                setMembership({
                  alreadyMember: false,
                  basicMember: true,
                  premiumMember: false,
                  lifeTimeMember: false,
                });
              } else if (applicant.data.data.membership_amount >= 0) {
                setMembership({
                  alreadyMember: true,
                  basicMember: false,
                  premiumMember: false,
                  lifeTimeMember: false,
                });
              }
              setIsOpen(true);
              loading.decrementLoading();
            }, 2000);
          })
          .catch((err) => {
            loading.decrementLoading();
            toast.setValue({
              open: true,
              message: err.response.data.message,
              status: "error",
            });
          });
      });
    }
    return () => {
      setFormData({});
      setApplicantData(null);
      setIsApproved(false);
    };
  }, [applicantId, open]);

  useEffect(() => {}, [applicantId, open]);

  useEffect(() => {
    loading.incrementLoading();
    dataService
      .getCourses()
      .then((res) => {
        loading.decrementLoading();
        if (res.data.status !== "success") {
          toast.setValue({
            open: true,
            message: res.data.message,
            status: "error",
          });
          return;
        }
        setProgrammes(res.data.data);
      })
      .catch((err) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: "Error fetching courses",
          status: "error",
        });
      });
  }, []);

  const editApplicant = () => {
    loading.incrementLoading();
    adminService
      .editApplicant(applicantId, formData)
      .then((res) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((err) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          status: "error",
          message: "Something went wrong, please try again",
        });
      });
  };

  const handleReturnForPayment = () => {
    loading.incrementLoading();
    adminService
      .returnForPayment(applicantId, reason)
      .then((res) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: res.data.message,
          status: res.data.status,
        });
        if (res.data.status === "success") handleClose();
      })
      .catch((err) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: "Something went wrong, please try again",
          status: "error",
        });
      });
  };

  const closeReturnDialog = () => {
    loading.decrementLoading();
    setReturnDialog(false);
  };

  const handleReject = () => {
    loading.incrementLoading();
    adminService
      .rejectApplicant(applicantId, reason)
      .then((res) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: res.data.message,
          status: res.data.status,
        });
        if (res.data.status === "success") handleClose();
      })
      .catch((err) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: "Something went wrong, please try again",
          status: "error",
        });
      });
  };

  const closeRejectDialog = () => {
    loading.decrementLoading();
    setRejectDialog(false);
  };

  const handleApprove = async () => {
    loading.incrementLoading();
    await adminService.editApplicant(applicantId, formData);
    adminService
      .approveApplication(applicantId)
      .then((res) => {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          status: res.data.status,
          message: res.data.message,
        });
        if (res.data.status === "success") setIsApproved(true);
      })
      .catch((err) => {
        loading.incrementLoading();
        toast.setValue({
          open: true,
          status: "error",
          message: "Something went wrong, please try again",
        });
      });
  };

  const genders = ["Male", "Female", "Other"];

  const changeVal = (key, value) => {
    setApplicantData({
      ...applicantData,
      [key]: value,
    });
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  return (
    <div>
      <RejectDialog
        open={rejectDialog}
        close={closeRejectDialog}
        handleSubmit={handleReject}
        setRejectReason={setReason}
      />
      <ReturnDialog
        open={returnDialog}
        close={closeReturnDialog}
        handleSubmit={handleReturnForPayment}
        setReturnReason={setReason}
      />
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "scroll",
        }}
        open={isOpen}
        onClose={() => {
          handleClose();
        }}
      >
        <Box sx={style} component="">
          <Button color="inherit" sx={{ float: "right" }} onClick={handleClose}>
            <CloseIcon sx={{ width: 1 }} />
          </Button>
          <form>
            <Typography
              sx={{
                marginBottom: 3,
              }}
              id="modal-modal-title"
              variant="h5"
              component="h2"
            >
              Applicant Details
            </Typography>
            <div style={{ marginBottom: "1rem" }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Primary Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Admission No"
                        variant="outlined"
                        size="small"
                        name="admission_no"
                        value={applicantData ? applicantData.admission_no : ""}
                        onChange={(newVal) => {
                          changeVal("admission_no", newVal.target.value);
                          // setApplicantData({
                          //   ...applicantData,
                          //   admission_no: newVal.target.value
                          // })
                          // setFormData({
                          //   ...formData,
                          //   admission_no: newVal.target.value,
                          // });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={applicantData ? applicantData.name : ""}
                        onChange={(newVal) => {
                          changeVal("name", newVal.target.value);
                          // setFormData({
                          //   ...formData,
                          //   name: newVal.target.value,
                          // });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={applicantData ? applicantData.dob : ""}
                          label="Date of Birth"
                          inputFormat="dd/MM/yyyy"
                          onChange={(newVal) => {
                            newVal = newVal.toLocaleDateString("fr-CA");
                            changeVal("dob", newVal);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              size={"small"}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl required fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={applicantData ? applicantData.gender : ""}
                          label="Gender"
                          name="gender"
                          required
                          onChange={(newVal) => {
                            changeVal("gender", newVal.target.value);
                            // setFormData({
                            //   ...formData,
                            //   gender: newVal.target.value,
                            // });
                          }}
                        >
                          {genders.map((value, key) => {
                            return (
                              <MenuItem key={key} value={value}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl required fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Programme
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={applicantData ? applicantData.course_id : ""}
                          label="Programme"
                          name="programme"
                          onChange={(newVal) => {
                            setApplicantData((preVal) => ({
                              ...preVal,
                              course_id: newVal.target.value,
                            }));
                            dataService
                              .getBatches(newVal.target.value)
                              .then((res) => {
                                setBatches(res.data.data);
                              });
                            // setFormData({
                            //   ...formData,
                            //   course_id: newVal.target.value,
                            // });
                            // dataService
                            //   .getBatches(newVal.target.value)
                            //   .then((res) => {
                            //     setBatches(res.data.data);
                            //   });
                          }}
                        >
                          {programmes.map((value, index) => {
                            return (
                              <MenuItem key={index} value={value.id}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl required fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Batch
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={applicantData ? applicantData.batch_id : ""}
                          label="Batch"
                          name="batch_id"
                          onChange={(newVal) => {
                            changeVal("batch_id", newVal.target.value);
                            // setFormData({
                            //   ...formData,
                            //   batch_id: newVal.target.value,
                            // });
                          }}
                        >
                          {batches.map((value, index) => {
                            return (
                              <MenuItem key={index} value={value.id}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Email"
                        type={"email"}
                        variant="outlined"
                        size="small"
                        name="email"
                        value={applicantData ? applicantData.email : ""}
                        onChange={(newVal) => {
                          changeVal("email", newVal.target.value);
                          // setFormData({
                          //   ...formData,
                          //   email: newVal.target.value,
                          // });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        fullWidth
                        type={"number"}
                        id="outlined-basic"
                        label="Mobile"
                        variant="outlined"
                        size="small"
                        name="mobile"
                        value={applicantData ? applicantData.mobile : ""}
                        onChange={(newVal) => {
                          changeVal("mobile", newVal.target.value);
                          // setFormData({
                          //   ...formData,
                          //   mobile: newVal.target.value,
                          // });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth
                        type="number"
                        id="outlined-basic"
                        label="Whatsapp No"
                        variant="outlined"
                        size="small"
                        name="whatsapp_no"
                        value={applicantData ? applicantData.whatsapp_no : ""}
                        onChange={(newVal) => {
                          changeVal("whatsapp_no", newVal.target.value);
                          // setFormData({
                          //   ...formData,
                          //   whatsapp_no: newVal.target.value,
                          // });
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Communication Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} sx={{ float: "left" }}>
                    <Grid item xs={12}>
                      <Typography> Permanent Address</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Address Line 1"
                        variant="outlined"
                        size="small"
                        value={
                          applicantData ? applicantData.address_line_1 : ""
                        }
                        name="address_line_1"
                        onChange={(newVal) => {
                          changeVal("address_line_1", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   address_line_1: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="outlined-basic"
                        size="small"
                        label="Address Line 2"
                        name="address_line_2"
                        value={
                          applicantData ? applicantData.address_line_2 : ""
                        }
                        onChange={(newVal) => {
                          changeVal("address_line_2", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   address_line_2: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Place"
                        name="place"
                        variant="outlined"
                        size="small"
                        value={applicantData ? applicantData.place : ""}
                        onChange={(newVal) => {
                          changeVal(newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   place: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Post Office"
                        variant="outlined"
                        size="small"
                        name="postoffice"
                        value={applicantData ? applicantData.postoffice : ""}
                        onChange={(newVal) => {
                          changeVal(newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   postoffice: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Pin Code"
                        variant="outlined"
                        size="small"
                        name="pincode"
                        value={applicantData ? applicantData.pincode : ""}
                        onChange={(newVal) => {
                          changeVal("pincode", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   pincode: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        freeSolo
                        name="country"
                        size="small"
                        value={applicantData ? applicantData.country : ""}
                        id="combo-box-demo"
                        options={countries}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            value={applicantData ? applicantData.country : ""}
                            onChange={(newVal) => {
                              changeVal("country", newVal.target.value);
                              // setFormData((formData) => ({
                              //   ...formData,
                              //   country: newVal.target.value,
                              // }));
                            }}
                            label="Country"
                          />
                        )}
                        onSelect={(newVal) => {
                          changeVal("country", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   country: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        freeSolo
                        size="small"
                        id="combo-box-demo"
                        options={states}
                        name="state"
                        value={applicantData ? applicantData.state : ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="state"
                            label="State"
                            required
                            value={applicantData ? applicantData.state : ""}
                            onChange={(newVal) => {
                              changeVal("state", newVal.target.value);
                              // setFormData((formData) => ({
                              //   ...formData,
                              //   state: newVal.target.value,
                              // }));
                            }}
                          />
                        )}
                        onSelect={(newVal) => {
                          changeVal("state", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   state: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        freeSolo
                        name="district"
                        size="small"
                        id="combo-box-demo"
                        value={applicantData ? applicantData.district : ""}
                        options={districts.filter(function onlyUnique(
                          value,
                          index,
                          self
                        ) {
                          return self.indexOf(value) === index;
                        })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            name="district"
                            label="District"
                            value={applicantData ? applicantData.district : ""}
                            onChange={(newVal) => {
                              changeVal("district", newVal.target.value);
                              // setFormData((formData) => ({
                              //   ...formData,
                              //   district: newVal.target.value,
                              // }));
                            }}
                          />
                        )}
                        onSelect={(newVal) => {
                          changeVal("district", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   district: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography> Communication Address</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Address Line 1"
                        variant="outlined"
                        size="small"
                        name="communication_address_line_1"
                        value={
                          applicantData
                            ? applicantData.communication_address_line_1
                            : ""
                        }
                        onChange={(newVal) => {
                          changeVal(
                            "communication_address_line_1",
                            newVal.target.value
                          );
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_address_line_1: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Address Line 2"
                        variant="outlined"
                        size="small"
                        name="communication_address_line_2"
                        value={
                          applicantData
                            ? applicantData.communication_address_line_2
                            : ""
                        }
                        onChange={(newVal) => {
                          changeVal(
                            "communication_address_line_2",
                            newVal.target.value
                          );
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_address_line_2: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Place"
                        variant="outlined"
                        size="small"
                        name="communication_place"
                        value={
                          applicantData ? applicantData.communication_place : ""
                        }
                        onChange={(newVal) => {
                          changeVal("communication_place", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_place: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Post Office"
                        variant="outlined"
                        size="small"
                        name="communication_postoffice"
                        value={
                          applicantData
                            ? applicantData.communication_postoffice
                            : ""
                        }
                        onChange={(newVal) => {
                          changeVal(
                            "communication_postoffice",
                            newVal.target.value
                          );
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_postoffice: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-basic"
                        label="Pin Code"
                        variant="outlined"
                        size="small"
                        name="communication_pincode"
                        value={
                          applicantData
                            ? applicantData.communication_pincode
                            : ""
                        }
                        onChange={(newVal) => {
                          changeVal(
                            "communication_pincode",
                            newVal.target.value
                          );
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_pincode: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        freeSolo
                        name="communication_country"
                        size="small"
                        id="combo-box-demo"
                        options={countries}
                        value={
                          applicantData
                            ? applicantData.communication_country
                            : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            value={
                              applicantData
                                ? applicantData.communication_country
                                : ""
                            }
                            onChange={(newVal) => {
                              changeVal(
                                "communication_country",
                                newVal.target.value
                              );
                              // setFormData((formData) => ({
                              //   ...formData,
                              //   communication_country: newVal.target.value,
                              // }));
                            }}
                            label="Country"
                          />
                        )}
                        onSelect={(newVal) => {
                          changeVal(
                            "communication_country",
                            newVal.target.value
                          );
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_country: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        freeSolo
                        name="communication_state"
                        size="small"
                        id="combo-box-demo"
                        options={states}
                        value={
                          applicantData ? applicantData.communication_state : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            required
                            value={
                              applicantData
                                ? applicantData.communication_state
                                : ""
                            }
                            onChange={(newVal) => {
                              changeVal(
                                "communication_state",
                                newVal.target.value
                              );
                              // setFormData((formData) => ({
                              //   ...formData,
                              //   communication_state: newVal.target.value,
                              // }));
                            }}
                          />
                        )}
                        onSelect={(newVal) => {
                          changeVal("communication_state", newVal.target.value);
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_state: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        freeSolo
                        name="communication_district"
                        size="small"
                        id="combo-box-demo"
                        value={
                          applicantData
                            ? applicantData.communication_district
                            : ""
                        }
                        options={districts.filter(function onlyUnique(
                          value,
                          index,
                          self
                        ) {
                          return self.indexOf(value) === index;
                        })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="District"
                            value={
                              applicantData
                                ? applicantData.communication_district
                                : ""
                            }
                            onChange={(newVal) => {
                              changeVal(
                                "communication_district",
                                newVal.target.value
                              );
                              // setFormData((formData) => ({
                              //   ...formData,
                              //   communication_district: newVal.target.value,
                              // }));
                            }}
                          />
                        )}
                        onSelect={(newVal) => {
                          changeVal(
                            "communication_district",
                            newVal.target.value
                          );
                          // setFormData((formData) => ({
                          //   ...formData,
                          //   communication_district: newVal.target.value,
                          // }));
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Education Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Institution</TableCell>
                            <TableCell align="left">Programme</TableCell>
                            <TableCell align="left">From</TableCell>
                            <TableCell align="left">To</TableCell>
                            {/* <TableCell align="left"></TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {applicantData ? (
                            applicantData.applicant_education.map(
                              (education, key) => (
                                <TableRow
                                  key={key}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {key + 1}
                                  </TableCell>
                                  <TableCell align="left">
                                    {education.institution}
                                  </TableCell>
                                  <TableCell align="left">
                                    {education.programme}
                                  </TableCell>
                                  <TableCell align="left">
                                    {education.from}
                                  </TableCell>
                                  <TableCell align="left">
                                    {education.to === null
                                      ? "Present"
                                      : education.to}
                                  </TableCell>
                                  {/* <TableCell align="left">
                                  <IconButton
                                    onClick={() => {
                                      setFormData((formData) => ({
                                        ...formData,
                                        applicant_education:
                                          formData.applicant_education.filter(
                                            (item, index) => index !== key
                                          ),
                                      }));
                                    }}
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell> */}
                                </TableRow>
                              )
                            )
                          ) : (
                            <TableRow />
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Achievement Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Achievement</TableCell>
                          <TableCell align="left">Date</TableCell>
                          {/* <TableCell align="left"></TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {applicantData ? (
                          applicantData.applicant_achievement.map(
                            (achievement, key) => (
                              <TableRow
                                key={key}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {key + 1}
                                </TableCell>
                                <TableCell align="left">
                                  {achievement.achievement}
                                </TableCell>
                                <TableCell align="left">
                                  {achievement.date}
                                </TableCell>
                                {/* <TableCell align="left">
                                <IconButton
                                  onClick={() => {
                                    setFormData((formData) => ({
                                      ...formData,
                                      applicant_achievements:
                                        formData.applicant_achievements.filter(
                                          (item, index) => index !== key
                                        ),
                                    }));
                                  }}
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell> */}
                              </TableRow>
                            )
                          )
                        ) : (
                          <TableRow />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Occupation Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Position</TableCell>
                          <TableCell align="left">Organization</TableCell>
                          <TableCell align="left">From</TableCell>
                          <TableCell align="left">To</TableCell>
                          {/* <TableCell align="left"></TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {applicantData ? (
                          applicantData.applicant_occupation.map(
                            (occupation, key) => (
                              <TableRow
                                key={key}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {key + 1}
                                </TableCell>
                                <TableCell align="left">
                                  {occupation.position}
                                </TableCell>
                                <TableCell align="left">
                                  {occupation.organization}
                                </TableCell>
                                <TableCell align="left">
                                  {occupation.from}
                                </TableCell>
                                <TableCell align="left">
                                  {occupation.to === null
                                    ? "Present"
                                    : occupation.to}
                                </TableCell>
                                {/* <TableCell align="left">
                                <IconButton
                                  onClick={() => {
                                    setFormData((formData) => ({
                                      ...formData,
                                      applicant_occupation:
                                        formData.applicant_occupation.filter(
                                          (item, index) => index !== key
                                        ),
                                    }));
                                  }}
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell> */}
                              </TableRow>
                            )
                          )
                        ) : (
                          <TableRow />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Membership Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row border-1 text-center pb-3">
                    <div className="col-12 col-md-6 pt-2">
                      <b>Primary Membership</b>
                      <Grid container spacing={2} paddingTop={1}>
                        <Grid item xs={6} md={6}>
                          <AlreadyMemberCard state={membership.alreadyMember} />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <BasicCard state={membership.basicMember} />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="col-12 col-md-6 pt-2">
                      <b>Secondary Membership</b>
                      <Grid container spacing={2} paddingTop={1}>
                        <Grid item xs={6} md={6}>
                          <PremiumCard state={membership.premiumMember} />
                        </Grid>

                        <Grid item xs={6} md={3}>
                          <LifeTimeCard state={membership.lifeTimeMember} />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell align="left">Amount</TableCell>
                          <TableCell align="left">Date</TableCell>
                          <TableCell align="left">Order ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {applicantData ? (
                          applicantData.transaction.map((transaction, key) => (
                            <TableRow
                              key={key}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {key + 1}
                              </TableCell>
                              <TableCell align="left">
                                {transaction.amount}
                              </TableCell>
                              <TableCell align="left">
                                {transaction.date}
                              </TableCell>
                              <TableCell align="left">
                                {transaction.order_id}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ float: "right" }}>
              <LoadingButton
                variant="contained"
                color="error"
                disabled={isApproved}
                loading={loading.isLoading}
                sx={{
                  marginRight: 1,
                }}
                onClick={() => {
                  loading.incrementLoading();
                  setRejectDialog(true);
                }}
              >
                Reject
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={isApproved}
                loading={loading.isLoading}
                onClick={() => {
                  loading.incrementLoading();
                  setReturnDialog(true);
                }}
                sx={{
                  marginRight: 1,
                }}
              >
                Send Payment Link
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="warning"
                disabled={isApproved}
                loading={loading.isLoading}
                sx={{
                  marginRight: 1,
                }}
                onClick={editApplicant}
              >
                Update
              </LoadingButton>
              <LoadingButton
                loading={loading.isLoading}
                color="success"
                variant="contained"
                onClick={handleApprove}
              >
                Approve
              </LoadingButton>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default VerificationModal;
