import React from "react";
import { Link } from "react-router-dom";
import pic from "../../../assets/img/event/event-img.jpg";
import "./NewMembership.css";
import constants from "../../../constants/pages";

//Membership notification card component
function NewMembership() {
  return (
    <section id="upcoming-area" className="p-0 upcoming-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="upcoming-event-wrap">
              <div className="up-event-titile">
                <h4>Alumni Membership</h4>
              </div>
              <div className="upcoming-event-content owl-carousel">
                <div className="single-upcoming-event">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="up-event-thumb">
                        <img
                          src={pic}
                          className="img-fluid"
                          alt="Upcoming Event"
                        ></img>
                        <h4 className="up-event-date">Since 1995</h4>
                      </div>
                    </div>

                    <div className="col-lg-7">
                      <div className="display-table">
                        <div className="display-table-cell">
                          <div className="up-event-text">
                            <h2>Join Us!</h2>
                            <p>
                              We are proud students of this esteemed
                              institution. We inculcate values and morals from
                              this soil. Our hearts are deeply rooted here. Our
                              sense of belonging towards WMOC remains forever.
                              We look forward to having you as a member.
                            </p>

                            <Link
                              to={constants.NEW_REGISTRATION}
                              className="btn btn-brand btn-brand-dark"
                              id="btn-brand"
                            >
                              New Membership
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewMembership;
