import React from 'react'
import BannerAboutUs from '../../../components/about-us/about-us-banner/BannerAboutUs'
import CommitteeDetails from '../../../components/about-us/committe-details/CommitteeDetails'
import HistoryArea from '../../../components/about-us/history-area/HistoryArea'
import MemberStatistics from '../../../components/user-home/total-member-card/MemberStatistics'
import './PageAboutUs.css'
function PageAboutUs() {
    return (
        <div>
            <BannerAboutUs/>
            <HistoryArea/>
            <MemberStatistics/>
            <CommitteeDetails/>
        </div>
    )
}

export default PageAboutUs