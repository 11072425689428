import React from "react";
import pic from "../../../assets/logos/wmologo.ico";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";
import alumniService from "../../../service/alumniService";
import ToastContext from "../../../context/ToastContext";
import Skeleton from "@mui/material/Skeleton";
import "./Footer.css";
import pages from "../../../constants/pages";
import { Link } from "react-router-dom";
import TermsAndPolicy from "../../terms-and-condition/TermsAndPolicy";

// Footer component
function Footer({ alumniInfoData }) {
  const [newsLetterEmail, setNewsLetterEmail] = React.useState("");
  const toast = React.useContext(ToastContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: newsLetterEmail,
    };
    alumniService
      .postNewsLetterEmail(data)
      .then((res) => {
        toast.setValue({
          open: true,
          status:
            res.data.status === "success"
              ? ("success", setNewsLetterEmail(""))
              : "error",
          message: res.data.message,
        });
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          status: "warning",
          message: "Something went wrong",
        });
      });
  };
  const handleChange = (event) => {
    setNewsLetterEmail(event.target.value);
  };

  const footerQuotes =
    "We are proud students of this esteemed institution. We inculcate values and morals from this soil. Our hearts are deeply rooted here. Our sense of belonging towards WMOC remains forever.";
  const alumniQuote =
    "Get in touch with the alluring journey of WMOC. Outpouring memories and experiences can shower in this fruitful platform";
  return (
    <footer id="footer-area">
      <div className="footer-widget section-padding ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-12 ">
              <div className="single-widget-wrap ">
                <div className="widget-body">
                  <div className="footer-about mt-md-5 mb-md-0 p-1">
                    <a
                      href="https://wmocollege.ac.in/"
                      rel="noreferrer"
                      target="_blank"
                      className="p-md-0"
                    >
                      <img
                        src={pic}
                        alt="Logo"
                        className="img-fluid  mb-4"
                        width="100px"
                        style={{
                          borderRadius: "30%",
                          boxShadow: "5px 5px 10rem 0px skyblue",
                        }}
                      />
                    </a>
                    <p className="ps-md-0 mb-0">
                      <i>{footerQuotes ? footerQuotes : "Loading....."}</i>
                      <br />
                      <br />
                    </p>
                    <hr />
                    <div className="col-12 ps-md-0 p-1">
                      <div>
                        <EmailIcon sx={{ float: "left" }} />
                        &nbsp;
                        {alumniInfoData.email != null ? (
                          alumniInfoData.email
                        ) : (
                          <Skeleton
                            animation="wave"
                            width="auto"
                            sx={{ marginLeft: "2rem" }}
                          />
                        )}
                        &nbsp;
                      </div>
                      <div className="pt-2">
                        <CallIcon sx={{ float: "left" }} />
                        &nbsp;
                        {alumniInfoData.phone != null ? (
                          "+91 " + alumniInfoData.phone
                        ) : (
                          <Skeleton
                            animation="wave"
                            width={150}
                            sx={{ marginLeft: "2rem" }}
                          />
                        )}
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12  col-sm-12  pl-md-3 pr-md-3 mt-lg-5 ">
              <div className="single-widget-wrap pt-3 pt-md-1 pt-lg-5">
                <h4 className="widget-title">Get In Touch</h4>
                <div className="widget-body pt-lg-0">
                  <p>{alumniQuote ? alumniQuote : "Loading....."}</p>
                  <div className="newsletter-form">
                    <form
                      id="cbx-subscribe-form"
                      role="search"
                      onSubmit={handleSubmit}
                    >
                      <input
                        type="email"
                        name="email"
                        value={newsLetterEmail}
                        placeholder="Enter Your Email"
                        id="subscribe"
                        required
                        onChange={handleChange}
                      />
                      <IconButton type="submit" sx={{ borderRadius: "0px" }}>
                        <SendIcon style={{ width: "24px", height: "24px" }} />
                      </IconButton>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12  col-sm-12  pl-md-3 pr-md-3 mt-lg-5 ">
              <div className="single-widget-wrap pt-3 pt-md-1 pt-lg-5 mb-0">
                <h4 className="widget-title">Other Links</h4>
                <div className="widget-body ps-lg-2">
                  <ul className="footer-list">
                    <li>
                      <a href="https://wmocollege.ac.in" target={"_blank"}>
                        Official College Website
                      </a>
                    </li>
                    <li>
                      <Link to={pages.HOME}>Home</Link>
                    </li>
                    <li>
                      <Link to={pages.NEW_REGISTRATION}>Membership</Link>
                    </li>
                    <li>
                      <Link to={pages.ABOUT_US}>About Us</Link>
                    </li>
                    <li>
                      <Link to={pages.GALLERY}>Gallery</Link>
                    </li>
                    <li>
                      <TermsAndPolicy textColor='white' />
                    </li>
                    <li>
                      <Link to={pages.ADMIN}>Admin</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="footer-bottom-text">
                <span id="copy-rights">
                  {" "}
                  © 2022 {alumniInfoData.name ? alumniInfoData.name : 'Alumni WMOC'}, All Rights Reserved.
                </span>
                <br />
                <i id="powered-by">
                  Powered by
                  <a
                    href="https://in.linkedin.com/company/djx?trk=public_profile_topcard-current-company"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <b>&nbsp;DJX</b>
                  </a>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
