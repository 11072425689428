import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import IconButton from "@mui/material/IconButton";
import { Button, InputBase, Menu, MenuItem, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  // CheckCircleOutline,
  // Clear,
  FilterList as FilterIcon,
  Search as SearchIcon,
  Delete as DeleteIcon, Print
} from "@mui/icons-material";
import VerificationModal from "../request-verification-model/VerificationModal.js";
import adminService from "../../../service/adminService.js";
import LoadingContext from "../../../context/LoadingContext";
import ToastContext from "../../../context/ToastContext";
import AlertContext from "../../../context/AlertContext.jsx";
import dataService from "../../../service/dataService.js";
import printTable from "../../../utils/printTable.js";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "admission_no",
    numeric: true,
    disablePadding: false,
    label: "Admission No",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "gender",
    numeric: true,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "dob",
    numeric: true,
    disablePadding: false,
    label: "DOB",
  },
  {
    id: "course",
    numeric: true,
    disablePadding: false,
    label: "Programme",
  },
  {
    id: "batch",
    numeric: true,
    disablePadding: false,
    label: "Batch",
  },
  {
    id: "mobile",
    numeric: true,
    disablePadding: false,
    label: "Mobile",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { requestSearch, setTableData, filter, setFilter, rows } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 0, sm: 0 },
        gap: "1rem",
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <>Return For Payment</>
      </Typography>
      <Button
        variant="contained"
        endIcon={<Print />}
        sx={{ background: "#3b5998", paddingLeft: 3, paddingRight: 3 }}
        onClick={() => {
          printTable(rows, headCells, "Return For Payment List");
        }}
      >
        Print
      </Button>
      <div className="d-flex rounded border">
        <InputBase
          style={{
            width: "10rem",
            height: "2.5rem",
            paddingLeft: "1rem",
          }}
          placeholder="Search Name here..."
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => {
            requestSearch(e.target.value);
          }}
        />
        <IconButton type="" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </div>
      <TableFilter
        setTableData={setTableData}
        filter={filter}
        setFilter={setFilter}
      />
    </Toolbar>
  );
};

function ReturnForPaymentTable() {
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const loading = React.useContext(LoadingContext);
  const toast = React.useContext(ToastContext);
  const alert = React.useContext(AlertContext);

  //state and functions to handle verification modal

  const [verificationModal, setVerificationModal] = useState(false);
  const [applicantId, setApplicantId] = useState(0);
  const handleVerificationOpen = () => setVerificationModal(true);
  const handleVerificationClose = () => setVerificationModal(false);

  const [filter, setFilter] = React.useState({
    department_id: 0,
    course_id: 0,
    batch_id: 0,
    membership_type_id: -1,
  });

  const requestSearch = (searchedVal) => {
    searchedVal = searchedVal.toUpperCase();
    let filteredRows = originalRows.filter((row) => {
      for (let x in row) {
        if (row[x] !== null && row[x] !== undefined) {
          let row_value = row[x].toUpperCase();
          let result = row_value.includes(searchedVal);
          if (result) return result;
        }
      }
      return false;
    });
    setRows(filteredRows);
  };

  const setTableData = (filter = {}) => {
    filter = {
      application_status_id: 2,
      ...filter,
    };
    loading.incrementLoading();
    adminService.getMembershipRequests(filter).then((response) => {
      if (response.data.status === "success") {
        setOriginalRows(response.data.data);
        setRows(response.data.data);
        loading.decrementLoading();
      } else {
        toast.setValue({ open: true, ...response.data });
      }
    });
  };

  useEffect(() => {
    setTableData(filter);
  }, [verificationModal]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  let prop = {
    requestSearch: requestSearch,
    setTableData: setTableData,
    filter: filter,
    setFilter: setFilter,
    rows: rows,
  };

  const handleDeleteActionClick = (applicantId) => {
    alert.handleClose();
    loading.incrementLoading();
    adminService.deleteMembershipRequest(applicantId).then((response) => {
      if (response.data.status === "success") {
        toast.setValue({
          open: true,
          message: response.data.message,
          status: "success",
        });
        adminService.getMembershipRequests(filter).then((res) => {
          setOriginalRows(res.data.data);
          setRows(res.data.data);
          loading.decrementLoading();
        });
      } else {
        loading.decrementLoading();
        toast.setValue({
          open: true,
          message: response.data.message,
          status: "error",
        });
      }
    });
  };
  return (
    <>
      <VerificationModal
        open={verificationModal}
        applicantId={applicantId}
        close={handleVerificationClose}
      />
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, padding: 3 }}>
          <EnhancedTableToolbar {...prop} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} size={"medium"}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const dob = new Date(row.dob).toLocaleDateString("en-GB");
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                      >
                        <TableCell align="left" id={labelId}>
                          {row.admission_no}
                        </TableCell>
                        <TableCell align="left" id={labelId}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.gender}</TableCell>
                        <TableCell align="left">{dob}</TableCell>
                        <TableCell align="left">{row.course}</TableCell>
                        <TableCell align="left">{row.batch}</TableCell>
                        <TableCell align="left">{row.mobile}</TableCell>
                        <TableCell align="center" sx={{ padding: 1 }}>
                          <IconButton
                            onClick={() => {
                              setApplicantId(row.id);
                              handleVerificationOpen();
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              alert.setValue({
                                open: true,
                                status: "warning",
                                message:
                                  "Are you sure you want to delete this request?",
                                response: () => {
                                  handleDeleteActionClick(row.id);
                                },
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ marginTop: "20px" }}
          />
        </Paper>
      </Box>
    </>
  );
}

function TableFilter({ setTableData, filter, setFilter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [departments, setDepartments] = React.useState([]);
  const [programmes, setProgrammes] = React.useState([]);
  const [batches, setBatches] = React.useState([]);
  const [membershipTypes, setMembershipTypes] = React.useState([]);

  React.useEffect(() => {
    dataService.getDepartments().then((res) => {
      if (res.data.status === "success") setDepartments(res.data.data);
    });
    dataService.getCourses().then((res) => {
      if (res.data.status === "success") setProgrammes(res.data.data);
    });
    dataService.getMembershipTypes().then((res) => {
      if (res.data.status === "success") setMembershipTypes(res.data.data);
    });
  }, []);

  //Function to control open and clos event of filter
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Function to handle changes on selection filed
  const handleFetch = (event) => {
    handleClose();
    setTableData(filter);
  };

  const handleChange = (event) => {
    const name = event.target.name,
      value = event.target.value;
    if (name === "department_id") {
      setFilter((prevVal) => ({
        ...prevVal,
        [name]: value,
        course_id: 0,
        batch_id: 0,
      }));
      setBatches([]);
      adminService.getProgrammes(value).then((res) => {
        if (res.data.status === "success") {
          setProgrammes(res.data.data);
        }
      });
    }
    if (name === "course_id") {
      setFilter((prevVal) => ({ ...prevVal, [name]: value, batch_id: 0 }));
      adminService.getBatches(value).then((res) => {
        if (res.data.status === "success") {
          setBatches(res.data.data);
        }
      });
    }
    if (name === "batch_id") {
      setFilter((prevVal) => ({ ...prevVal, [name]: value }));
    }
    if (name === "membership_type_id") {
      setFilter((prevVal) => ({ ...prevVal, [name]: value }));
    }
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <FilterIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disableRipple>
          <TextField
            id="department_id"
            name="department_id"
            select
            label="Department"
            value={filter.department_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="0">All</MenuItem>
            {departments.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <TextField
            id="course_id"
            name="course_id"
            defaultValue={0}
            select
            label="Course"
            value={filter.course_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="0" selected>
              All
            </MenuItem>
            {programmes.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <TextField
            id="batch_id"
            name="batch_id"
            select
            label="Batch"
            value={filter.batch_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="0">All</MenuItem>
            {batches.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <TextField
            id="membership_type_id"
            name="membership_type_id"
            select
            label="Membership Type"
            defaultValue="-1"
            value={filter.membership_type_id}
            onChange={handleChange}
            variant="standard"
            sx={{ width: "150px" }}
          >
            <MenuItem value="-1" selected>
              All
            </MenuItem>
            {membershipTypes.map((values, index) => (
              <MenuItem key={index} value={values.id}>
                {values.name}
              </MenuItem>
            ))}
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <Button
            variant="contained"
            onClick={handleFetch}
            color="primary"
            fullWidth
          >
            Apply
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ReturnForPaymentTable;
