import React from 'react'

function PaymentPrompt({ paymentInfo, paymentFailureHandler }) {



  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorpay = async () => {

    // loading.setLoading(true)

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    // if (!result) {
    //   alert("Server error. Are you online?");
    //   return;
    // }

    // Getting the order details back

    const options = {
      ...paymentInfo,
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment-failed', paymentFailureHandler);
    paymentObject.open();
  }

  React.useEffect(() => {
    displayRazorpay();
  }, []);
  return (
    <></>
  )
}

export default PaymentPrompt