import React from 'react'
import ToastContext from '../../context/ToastContext'
import registrationService from '../../service/registrationService'
import { Grid, Card, Typography, TextField, Tooltip, Button } from '@mui/material'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

function UpdateDetails({ setPage, studentData, setStudentData }) {


  const toast = React.useContext(ToastContext)

  const handleNext = (e) => {
    e.preventDefault()
    registrationService.updateStudentContactDetails({
      student_id: studentData.id,
      email: studentData.email,
      mobile: studentData.mobile
    }).then((res) => {
      if (res.data.status === 'success')
        setPage(3);
      else
        toast.setValue({
          open: true,
          status: res.data.status,
          message: res.data.message
        })
    }).catch((err) => {
      toast.setValue({
        open: true,
        status: 'error',
        message: 'Something went wrong, please try again'
      })
    })
  }

  const updateContact = (e) => {
    setStudentData((prevVal) => ({ ...prevVal, [e.target.name]: e.target.value }))
  }
  
  React.useEffect(() => {
    window.scroll({top:0, left:0, behavior: 'smooth'});
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height={'80vh'}
      // marginTop={10}
    >
      <Card sx={{ paddingY: "2rem", width: '20rem' }} className="shadow">
        <form onSubmit={handleNext}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
            width={'100%'}
          >
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Contact Details</Typography>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                label="Admission No"
                variant="standard"
                value={studentData.admission_no}
                required
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                label="Name"
                variant="standard"
                value={studentData.name}
                required
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                label="Email"
                size="small"
                name='email'
                type='email'
                value={studentData.email}
                onChange={updateContact}
                fullWidth
                required
              />
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                label="Mobile"
                size="small"
                type='number'
                name='mobile'
                value={studentData.mobile}
                onChange={updateContact}
                fullWidth
                required
              />
            </Grid>
            <Grid item>
              <Tooltip title="Pervious page">
                <Button
                  sx={{ color: "black" }}
                  onClick={() => {
                    setPage(1);
                  }}
                >
                  <KeyboardDoubleArrowLeftIcon fontSize="large" />
                  Previous
                </Button>
              </Tooltip>
              <Tooltip title="next">
                <Button
                  type="submit"
                  sx={{ color: "black" }}
                >
                  Next
                  <DoubleArrowIcon fontSize="large" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
}

export default UpdateDetails