import React from 'react'
import './BannerAboutUs.css'

function BannerAboutUs() {
    return (

        <section id='about-us-banner'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-lg-8 m-auto text-center">
                        <div className='text-white'>
                            <h1 style={{fontWeight:700, fontSize:60}}>About Us</h1>
                            <p>Alumni Needs enables you to harness the power of your alumni network. Whatever may be the need</p>
                            <a href="#history-area" className="btn btn-brand smooth-scroll me-2">Events</a>  
                            <a href="#committee" className="btn btn-brand-rev smooth-scroll">Committee</a> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default BannerAboutUs