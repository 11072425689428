import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField, Grid } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function OccupationDialog({ setData, open, close }) {
  const [occupation, setOccupation] = React.useState({
    position: "",
    organization: "",
    from: null,
    to: null,
  });
  const [toState, setToState] = React.useState(false);

  const handleClose = () => {
    close(false);
    setToState(false);
    setOccupation({
      position: "",
      organization: "",
      from: null,
      to: null,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let currentOccupation = setData.formData.applicant_occupation;
    currentOccupation.push(occupation);
    setData.setFormData({
      ...setData.formData,
      applicant_occupation: currentOccupation,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
      <DialogTitle>Add your Occupation</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container sx={{ p: 1 }} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="position"
                variant="outlined"
                label="Position"
                required
                size="small"
                name="position"
                value={occupation.position}
                onChange={(newVal) => {
                  setOccupation((occupation) => ({
                    ...occupation,
                    position: newVal.target.value,
                  }));
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Organization"
                name="organization"
                required
                size="small"
                value={occupation.organization}
                onChange={(newVal) => {
                  setOccupation((occupation) => ({
                    ...occupation,
                    organization: newVal.target.value,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  name="from"
                  inputFormat="dd/MM/yyyy"
                  value={occupation.from}
                  onChange={(newVal) => {
                    if (newVal !== null)
                      newVal = newVal.toLocaleDateString("fr-CA");
                    setOccupation((occupation) => ({
                      ...occupation,
                      from: newVal,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      required
                      name="from"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "-9px", marginTop: "-8px" }}>
              <input
                type="checkBox"
                label="current"
                name="current"
                className="current-checkbox"
                onChange={(event) => {
                  if (event.target.checked) {
                    setOccupation((occupation) => ({
                      ...occupation,
                      to: null,
                    }));
                    setToState(true);
                  } else {
                    setToState(false);
                  }
                }}
              />
              <label className="current">
                I'm currently working on this position
              </label>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  name="to"
                  disabled={toState}
                  inputFormat="dd/MM/yyyy"
                  value={occupation.to}
                  onChange={(newVal) => {
                    if (newVal !== null)
                      newVal = newVal.toLocaleDateString("fr-CA");
                    setOccupation((occupation) => ({
                      ...occupation,
                      to: newVal,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      id="to"
                      required
                      name="to"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" autoFocus>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
