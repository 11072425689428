import React from "react";
import TransactionTable from "../../../components/transaction-list/transaction-table/TransactionTable"

export default function TransactionList() {
  return (
    <div className="container-fluid">
      <div className="p-md-3">
        <TransactionTable />
      </div>
    </div>
  );
}
