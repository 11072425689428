import { Button, Tooltip } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import pages from '../../constants/pages';

function ButtonAlreadyMember() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(pages.TEMP_REGISTRATION);
  }
  return (
    <Tooltip title="Clicking this will redirect you to new membership page">
      <Button variant="text" onClick={handleClick}
       sx={{ width: 'auto' }}
      >
        Already Member ?
      </Button>
    </Tooltip>
  )
}

export default ButtonAlreadyMember