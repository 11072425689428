import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import adminService from "../../../service/adminService";
import ToastContext from "../../../context/ToastContext";
import LoadingContext from "../../../context/LoadingContext";

export default function DepartmentEditDialog({
  open,
  departmentId,
  handleDepartmentEdit,
  close,
}) {
  const [name, setName] = React.useState("");
  const toast = React.useContext(ToastContext);
  const loading = React.useContext(LoadingContext);

  React.useEffect(() => {
    if (open)
      adminService.getDepartment(departmentId).then((res) => {
        if (typeof res.data === "object") setName(res.data.data.name);
      });
  }, [departmentId]);

  const handleClose = () => {
    close();
    setName("");
    handleDepartmentEdit();
  };

  const handleSubmit = (event) => {
    loading.incrementLoading();
    handleClose();
    event.preventDefault();
    adminService.editDepartment(departmentId, name).then((res) => {
      res.data.status === "success"
        ? toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          })
        : toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
      if (typeof res.data === "object") {
        loading.decrementLoading();
      }
    });
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit department</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required={true}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
