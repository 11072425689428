import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { images } from "./photos";
import "./Album.css";
import HomeGallery from "../../../components/user-home/our-gallery/HomeGallery";
export default function Album() {
  const [displayImg, setDisplayImg] = React.useState([]);
  const [info, setInfo] = React.useState({
    heading: "",
    description: "",
  });
  const [activeButton, setActiveButton] = React.useState(0);

  // const loadAllImages = async () => {
  //   setDisplayImg([]);
  //   setInfo({
  //     heading: "",
  //     description: "",
  //   });
  //   images.map((image) => {
  //     return setDisplayImg((prevImages) => prevImages.concat(...image.photos));
  //   });
  // };

  // React.useEffect(() => {
  // loadAllImages();
  // }, []);
  React.useEffect(() => {
    setDisplayImg(images[activeButton].photos);
    window.scrollTo(0, 0);
    setInfo({
      heading: images[activeButton].title,
      description: images[activeButton].description,
    });
  }, [activeButton]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        marginTop={0.5}
        className="category-buttons"
        id="album-category-button"
      >
        <Typography variant="h4" textAlign="center" marginTop={2}>
          {/* GALLERY */}
        </Typography>
        {/* <Grid item>
          <Button
            variant={activeButton === 0 ? "contained" : ""}
            style={{
              backgroundColor: activeButton === 0 ? "#3b5998" : "#ffff",
            }}
            onClick={() => {
              setActiveButton(0);
              loadAllImages();
              window.scrollTo(0, 0);
            }}
          >
            ALL
          </Button>
        </Grid> */}
        {images.map((value, index) => {
          return (
            <Grid item key={index}>
              <Button
                variant={activeButton === index ? "contained" : ""}
                style={{
                  backgroundColor: activeButton === index ? "#3b5998" : "#ffff",
                }}
                onClick={() => {
                  setActiveButton(index);
                }}
              >
                {value.category}
              </Button>
            </Grid>
          );
        })}
      </Grid>
      <Grid container className="gallery-container">
        <Grid container className="gallery-info">
          <Grid item>
            <Typography variant="h4" className="photos-heading">
              {info.heading}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className="photos-description">
              {info.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ maxWidth: "100%", paddingTop: 1 }}>
          <HomeGallery displayImages={displayImg} home={false} />
        </Grid>
      </Grid>
    </>
  );
}
