import React from "react";
import NewMembership from "../../../components/user-home/new-membership/NewMembership";
import OurResponsibilities from "../../../components/user-home/our-responsibilites/OurResponsibilities";
import MissionAndVision from "../../../components/user-home/mission-and-vision/MissionAndVision";
import MemberStatistics from "../../../components/user-home/total-member-card/MemberStatistics";
import HomeGallery from "../../../components/user-home/our-gallery/HomeGallery";
import { images } from "../../public/album/photos";
import Banner from "../../../components/user-home/banner/Banner";
import "./Home.css";
export default function Home() {
  return (
    <>
      <Banner />
      <NewMembership />
      <MissionAndVision />
      <OurResponsibilities />
      <MemberStatistics />
      <HomeGallery displayImages={images[0].photos} home={true} />
    </>
  );
}
