import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, IconButton } from "@mui/material";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import adminService from "../../../service/adminService";

import DepartmentAddDialog from "../../../components/form-control/department-add-dialog/DepartmentAddDialog";
import DepartmentEditDialog from "../../../components/form-control/department-edit-dialog/DepartmentEditDialog";
import ProgrammeAddDialog from "../../../components/form-control/programme-add-dialog/ProgrammeAddDialog";
import ProgrammeEditDialog from "../../../components/form-control/programme-edit-dialog/ProgrammeEditDialog";
import BatchEditDialog from "../../../components/form-control/batch-edit-dialog/BatchEditDialog";
import BatchAddDialog from "../../../components/form-control/batch-add-dialog/BatchAddDialog";

import storageService from "../../../service/storageService";
import AlertContext from "../../../context/AlertContext";
import "./FormControl.css";
import ToastContext from "../../../context/ToastContext";
import LoadingContext from "../../../context/LoadingContext";

const DepartmentsColumns = [
  { id: "name", label: "Departments", minWidth: 170 },
];
const ProgramsColumns = [{ id: "name", label: "Programmes", minWidth: 170 }];
const BatchColumns = [{ id: "name", label: "Batch", minWidth: 170 }];

export default function FormControl() {
  const page = 0;
  const rowsPerPage = 10;
  const [departmentRows, setDepartmentRows] = React.useState([]);
  const [programRows, setProgramRows] = React.useState([]);
  const [batchRows, setBatchRows] = React.useState([]);

  const [departmentId, setDepartmentId] = React.useState(null);
  const [programmeId, setProgrammeId] = React.useState(null);

  //Alert useContext import
  const alert = React.useContext(AlertContext);

  //Toast useContext import
  const toast = React.useContext(ToastContext);
  const loading = React.useContext(LoadingContext);

  //states and functions for department,course,batch edit dialog
  const [openDepartmentEditDialog, setDepartmentEditDialog] = React.useState({
    open: false,
    departmentId: null,
  });
  const [openProgrammeEditDialog, setProgrammeEditDialog] = React.useState({
    open: false,
    programmeId: null,
  });
  const [openBatchEditDialog, setBatchEditDialog] = React.useState({
    open: false,
    batchId: null,
  });

  const closeDepartmentEditDialog = () => {
    setDepartmentEditDialog((prevState) => ({
      ...prevState,
      open: false,
      departmentId: null,
    }));
  };

  const closeProgrammeEditDialog = () => {
    setProgrammeEditDialog((prevState) => ({
      ...prevState,
      open: false,
      programmeId: null,
    }));
  };
  const closeBatchEditDialog = () => {
    setBatchEditDialog((prevState) => ({
      ...prevState,
      open: false,
      batchId: null,
    }));
  };
  const handleDepartmentDelete = (departmentId) => {
    loading.incrementLoading();
    adminService
      .deleteDepartment(departmentId)
      .then((res) => {
        if (res.data.status === "success") {
          adminService
            .getDepartments()
            .then((res) => {
              setDepartmentRows(res.data.data);
              loading.decrementLoading();
            })
            .catch((err) => {
              toast.setValue({
                open: true,
                message: "Error in fetching departments",
                status: "error",
              });
              loading.decrementLoading();
            });
          toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          });
        } else {
          toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
          loading.decrementLoading();
        }
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          status: "error",
          message: "Something went wrong, please try again ",
        });
        loading.decrementLoading();
      });
  };
  const handleProgrammeDelete = (programmeId) => {
    adminService
      .deleteProgramme(programmeId)
      .then((res) => {
        if (res.data.status === "success") {
          adminService.getProgrammes(departmentId).then((res) => {
            setProgramRows(res.data.data);
          });
          toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          });
        } else {
          toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          status: "error",
          message: "Something went wrong, please try again ",
        });
      });
  };
  const handleBatchDelete = (batchId) => {
    adminService
      .deleteBatch(batchId)
      .then((res) => {
        if (res.data.status === "success") {
          adminService.getBatches(programmeId).then((res) => {
            setBatchRows(res.data.data);
          });
          toast.setValue({
            open: true,
            status: "success",
            message: res.data.message,
          });
        } else {
          toast.setValue({
            open: true,
            status: "error",
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          status: "error",
          message: "Something went wrong, please try again ",
        });
      });
  };
  useEffect(() => {
    loading.incrementLoading();
    adminService
      .getDepartments()
      .then((res) => {
        setDepartmentRows(res.data.data);
        loading.decrementLoading();
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          status: "error",
          message: "Something went wrong, please try again ",
        });
        loading.decrementLoading();
      });
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: "5rem" }}
      spacing={2}
    >
      <Grid item md={3.5} xs={10}>
        <DepartmentEditDialog
          {...openDepartmentEditDialog}
          handleDepartmentEdit={() => {
            adminService.getDepartments().then((res) => {
              setDepartmentRows(res.data.data);
            });
          }}
          close={closeDepartmentEditDialog}
        />
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ height: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {DepartmentsColumns.map((column, id) => (
                    <TableCell
                      key={id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          height: "2.4rem",
                          alignItems: "center",
                        }}
                      >
                        <Grid item>
                          <b>{column.label}</b>
                        </Grid>
                        <Grid item>
                          {storageService.getUserType() === "admin" ? (
                            <DepartmentAddDialog
                              handleDepartmentAdd={() => {
                                adminService.getDepartments().then((res) => {
                                  setDepartmentRows(res.data.data);
                                });
                              }}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {departmentRows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, code) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={code}
                        sx={{ cursor: "pointer" }}
                      >
                        {DepartmentsColumns.map((column, id) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              className={
                                row.id === departmentId ? "row-selected" : ""
                              }
                              key={column.id}
                              align={column.align}
                              onClick={() => {
                                adminService
                                  .getProgrammes(row.id)
                                  .then((res) => {
                                    setDepartmentId(row.id);
                                    setProgramRows(res.data.data);
                                    setBatchRows([]);
                                    setProgrammeId(null);
                                  });
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    onClick={() => {
                                      setDepartmentEditDialog((value) => ({
                                        ...value,
                                        open: true,
                                        departmentId: row.id,
                                      }));
                                    }}
                                  >
                                    <ModeEditRoundedIcon />
                                  </IconButton>
                                  {storageService.getUserType() === "admin" ? (
                                    <IconButton
                                      onClick={() => {
                                        alert.setValue({
                                          open: true,
                                          status: "warning",
                                          message: "Are you sure ?",
                                          title: `Delete`,
                                          response: () => {
                                            alert.handleClose();
                                            handleDepartmentDelete(row.id);
                                          },
                                        });
                                      }}
                                    >
                                      <DeleteRoundedIcon />
                                    </IconButton>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={3.5} xs={10}>
        <ProgrammeEditDialog
          {...openProgrammeEditDialog}
          handleProgrammeEdit={() => {
            adminService.getProgrammes(departmentId).then((res) => {
              setProgramRows(res.data.data);
            });
          }}
          close={closeProgrammeEditDialog}
        />
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ height: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {ProgramsColumns.map((column, id) => (
                    <TableCell
                      key={id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          height: "2.4rem",
                          alignItems: "center",
                        }}
                      >
                        <Grid item>
                          <b>{column.label}</b>
                        </Grid>
                        <Grid item>
                          {departmentId !== null ? (
                            <ProgrammeAddDialog
                              departmentId={departmentId}
                              handleProgrammeAdd={() => {
                                adminService
                                  .getProgrammes(departmentId)
                                  .then((res) => {
                                    setProgramRows(res.data.data);
                                  });
                              }}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {programRows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, code) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={code}
                        sx={{ cursor: "pointer" }}
                      >
                        {ProgramsColumns.map((column, id) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              className={
                                row.id === programmeId ? "row-selected" : ""
                              }
                              key={column.id}
                              align={column.align}
                              onClick={() => {
                                setProgrammeId(row.id);
                                adminService.getBatches(row.id).then((res) => {
                                  setBatchRows(res.data.data);
                                });
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                >
                                  {value}
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    onClick={() => {
                                      setProgrammeEditDialog((value) => ({
                                        ...value,
                                        open: true,
                                        programmeId: row.id,
                                      }));
                                    }}
                                  >
                                    <ModeEditRoundedIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      alert.setValue({
                                        open: true,
                                        status: "warning",
                                        message: "Are you sure ?",
                                        title: `Delete`,
                                        response: () => {
                                          handleProgrammeDelete(row.id);
                                          alert.handleClose();
                                        },
                                      });
                                    }}
                                  >
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item md={3.5} xs={10}>
        <BatchEditDialog
          {...openBatchEditDialog}
          handleBatchEdit={() => {
            adminService.getBatches(programmeId).then((res) => {
              setBatchRows(res.data.data);
            });
          }}
          close={closeBatchEditDialog}
        />
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ height: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {BatchColumns.map((column, id) => (
                    <TableCell
                      key={id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          height: "2.4rem",
                          alignItems: "center",
                        }}
                      >
                        <Grid item>
                          <b>{column.label}</b>
                        </Grid>
                        <Grid item>
                          {programmeId !== null ? (
                            <BatchAddDialog
                              programmeId={programmeId}
                              handleBatchAdd={() => {
                                adminService
                                  .getBatches(programmeId)
                                  .then((res) => {
                                    setBatchRows(res.data.data);
                                  });
                              }}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {batchRows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, code) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={code}>
                        {BatchColumns.map((column, id) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                >
                                  {value}
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    onClick={() => {
                                      setBatchEditDialog((value) => ({
                                        ...value,
                                        open: true,
                                        batchId: row.id,
                                      }));
                                    }}
                                  >
                                    <ModeEditRoundedIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      alert.setValue({
                                        open: true,
                                        status: "warning",
                                        message: "Are you sure ?",
                                        title: `Delete`,
                                        response: () => {
                                          handleBatchDelete(row.id);
                                          alert.handleClose();
                                        },
                                      });
                                    }}
                                  >
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
