// import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import * as React from "react";
// import AlertContext from "../../context/AlertContext";
import CardDashboard from "../card-dashboard/CardDashboard";
import pages from '../../constants/pages.js';
import { ListAlt, AssignmentInd, CurrencyRupeeRounded, Tune, ManageAccounts, CurrencyExchange } from '@mui/icons-material';

export default function AdminDashboard() {
  const [cards, setCards] = React.useState([]);   //to set cards in the dashboard
  const iconSx = { fontSize: '6.5rem', paddingTop: '1rem' };
  React.useEffect(() => {
    setCards([{             //setting cards in the dashboard
      id: 'alumniList',
      title: "Alumni List",
      description: "Structured data of Alumni Members",
      disable: false,
      path: pages.admin.ALUMNI_LIST,
      icon: <ListAlt sx={iconSx} />
    }, {
      id: 'requestsList',
      title: "Membership Applications",
      description: "Incoming Alumni Membership Requests",
      disable: false,
      path: pages.admin.MEMBER_REQUESTS,
      icon: <AssignmentInd sx={iconSx} />
    }, {
      id: 'transactions',
      title: "Transactions",
      description: "Structured data of Transactions done",
      disable: false,
      path: pages.admin.TRANSACTIONS_LIST,
      icon: <CurrencyRupeeRounded sx={iconSx} />
    }, {
      id: 'formControl',
      title: "Form Control",
      description: "Dept, Program, Batch form control",
      disable: false,
      path: pages.admin.FORM_CONTROL,
      icon: <Tune sx={iconSx} />
    }, {
      id: 'profileManagement',
      title: "Profile Management",
      description: "Edit Alumni association profile",
      disable: false,
      path: pages.admin.ALUMNI_PROFILE,
      icon: <ManageAccounts sx={iconSx} />
    }, {
      id: 'returnForPayment',
      title: "Return For Payment",
      description: "Applications that have returned for payment",
      disable: false,
      path: pages.admin.RETURN_FOR_PAYMENT,
      icon: <CurrencyExchange sx={iconSx} />
    },])
  }, []);
  //need to implement map rendering

  //using of alert context 
  // const alert = React.useContext(AlertContext);
  return (
    <div>

      {/* To open the alert box on button click
       <Button
        variant="outlined"
        onClick={
          // handleClickOpen
          () => {
            alert.setValue({
              open: true,
              status: "success",
              message: "Payment validated successfully, please wait for admin approval",
              title:'Application Submitted Successfully',
              response:()=>{
                alert.handleClose()
              }
            });
          }
        }
      >
        Open alert dialog
      </Button> */}
      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        direction={"row"}
        gap={2}
        padding={2}
      >
        {
          cards && cards.map((card) => {
            return (
              <Grid item xs={12} sm={6.5} md={4.5} lg={3.5} key={card.id}>
                <CardDashboard
                  title={card.title}
                  description={card.description}
                  disable={card.disable}
                  path={card.path}
                  icon={card.icon}
                />
              </Grid>
            )
          })
        }
      </Grid>
      {/* <div className="row d-flex align-content-center justify-content-center pt-3 gap-4 gap-sm-2 gap-md-1 gap-lg-0">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <CardDashboard
            key={"formControl"}
            title={"Form Control"}
            description={"Dept, Program, Batch form control"}
            disable={false}
            path={"/admin/form-control"}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <CardDashboard
            key={"profileManagement"}
            title={"Profile Management"}
            description={"Edit Alumni association profile"}
            disable={false}
            path={"/admin/edit-alumni-profile"}
          />
        </div>
      </div> */}
    </div>
  );
}
