import React from "react";
import { useSearchParams } from "react-router-dom";
import UpdateBasicInfo from "../../../components/update-profile/UpdateBasicInfo";
import UpdateAddressInfo from "../../../components/update-profile/UpdateAddressInfo";
import UpdateEducation from "../../../components/update-profile/UpdateEducation";
import UpdateOccupation from "../../../components/update-profile/UpdateOccupation";
import UpdateAchievements from "../../../components/update-profile/UpdateAchievements";
import alumniService from "../../../service/alumniService";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
// import FullScreenLoading from "../../../components/full-screen-loading/FullScreenLoading";
import { LoadingButton } from "@mui/lab";
import LoadingContext from "../../../context/LoadingContext";
import AlertContext from "../../../context/AlertContext";
import ToastContext from "../../../context/ToastContext";
import { useNavigate } from "react-router-dom";

const updateDataReducer = (state, action) => {
  switch (action.type) {
    case "alumni":
      return { ...state, alumni: { ...state.alumni, ...action.payload } };
    case "add":
      return {
        ...state,
        [action.sub_type]: {
          ...state[action.sub_type],
          add: action.payload,
        },
      };
    case "edit":
      return {
        ...state,
        [action.sub_type]: {
          ...state[action.sub_type],
          edit: action.payload,
        },
      };
    case "delete":
      return {
        ...state,
        [action.sub_type]: {
          ...state[action.sub_type],
          delete: action.payload,
        },
      };
    case "clear":
      return {
        alumni: {},
        alumni_education: {},
        alumni_achievements: {},
        alumni_occupation: {},
      };
  }
};

function EditAlumniProfile() {
  const [searchParams] = useSearchParams(); // to get url params
  const [alumni, setAlumni] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [updateData, setUpdateData] = React.useReducer(updateDataReducer, {
    alumni: {},
    alumni_education: {},
    alumni_achievements: {},
    alumni_occupation: {},
  });

  const navigate = useNavigate();

  const loading = React.useContext(LoadingContext);
  const alert = React.useContext(AlertContext);
  const toast = React.useContext(ToastContext);

  const token = searchParams.get("token") ? searchParams.get("token") : "";

  React.useEffect(() => {
    loading.incrementLoading();
    if (page === 0) {
      alumniService
        .getAlumniProfile(token)
        .then((res) => {
          if (res.data.status === "success") {
            setAlumni(res.data.data);
          } else {
            toast.setValue({
              open: true,
              message: res.data.message,
              status: res.data.status,
            });
          }
          loading.decrementLoading();
        })
        .then(() => {
          nextPage();
        });
    }
  }, []);

  React.useEffect(() => {
    if (page !== 0) {
      loading.incrementLoading();
      alumniService.getAlumniProfile(token).then((res) => {
        if (res.data.status === "success") {
          setAlumni(res.data.data);
        } else {
          toast.setValue({
            open: true,
            message: res.data.message,
            status: res.data.status,
          });
        }
        loading.decrementLoading();
      });
    }
  }, [page]);

  const nextPage = () => {
    setPage((no) => no + 1);
    if (page === 5) {
      alert.setValue({
        open: true,
        status: "success",
        message: "Profile Updated",
        response: () => {
          navigate("/");
          alert.handleClose();
        },
      });
    }
  };
  const prevPage = () => {
    setPage((no) => no - 1);
  };

  const handleUpdateData = () => {
    loading.incrementLoading();
    alumniService
      .updateAlumniProfile(token, updateData)
      .then((res) => {
        setUpdateData({ type: "clear" });
        loading.decrementLoading();
        nextPage();
      })
      .catch((err) => {
        toast.setValue({
          open: true,
          status: "error",
          message: "Something went wrong, please try again",
        });
        loading.decrementLoading();
      });
  };

  const props = {
    handleUpdateData,
    setAlumni,
    alumni,
    nextPage,
    prevPage,
    setUpdateData,
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "2rem",
        flexDirection: "column",
        height: "auto",
        minHeight: "100vh",
        justifyContent: "center",
      }}
    >
      {/* <FullScreenLoading /> */}
      <Card
        variant="elevation"
        elevation={5}
        sx={{
          width: { md: "65%", xs: "100%" },
        }}
      >
        <CardContent sx={{ backgroundColor: "#fff" }}>
          <Typography variant="h5" paddingY={"1rem"} align={"center"}>
            Update Profile
          </Typography>
          {page === 1 ? <UpdateBasicInfo {...props} /> : null}
          {page === 2 ? <UpdateAddressInfo {...props} /> : null}
          {page === 3 ? <UpdateEducation {...props} /> : null}
          {page === 4 ? <UpdateAchievements {...props} /> : null}
          {page === 5 ? <UpdateOccupation {...props} /> : null}
          {/* {page === 6
            ? alert.setValue({
                open: true,
                status: "success",
                message: "Profile Update",
              })
            : null} */}
        </CardContent>
        <CardActions sx={{ float: "right" }}>
          {page > 1 ? <Button onClick={prevPage}>Previous</Button> : null}
          {page < 6 ? (
            <LoadingButton
              loading={loading.isLoading}
              variant="contained"
              onClick={handleUpdateData}
            >
              Next
            </LoadingButton>
          ) : null}
        </CardActions>
      </Card>
    </div>
  );
}

export default EditAlumniProfile;
