import React, { useEffect } from 'react'
import AdminLogin from '../../../components/admin-login/AdminLogin'
import storageService from '../../../service/storageService'
import './PageAdminLogin.css'
import { useNavigate } from 'react-router-dom'

function PageAdminLogin() {
  const navigate = useNavigate()

  useEffect(() => {

    const user = storageService.getUser()
    if (user) {
      navigate('/admin')
    }
  }, [])
  return (
    <div className='adminLogin vh-100'>
      <div className="container-fluid h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <AdminLogin userType={'admin'} />
        </div>
      </div>
    </div>
  )
}

export default PageAdminLogin