import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function Toast({ open, message, status, close }) {
    return (
        <Snackbar open={open} autoHideDuration={2500} onClose={close}>
            <Alert onClose={close} severity={status} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default Toast