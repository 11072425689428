import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import successIcon from "../../assets/alert-dialog/success.gif";
import warningIcon from "../../assets/alert-dialog/warning.gif";
import infoIcon from "../../assets/alert-dialog/info.gif";
import errorIcon from "../../assets/alert-dialog/error.gif";
export default function AlertDialog({
  status,
  title,
  message,
  open,
  close,
  response,
  isCancellable
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: { md: "400px" }, // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {status === "success" ? (
            <img src={successIcon} width="100px" height="100px" />
          ) : (
            ""
          )}
          {status === "warning" ? (
            <img src={warningIcon} width="100px" height="100px" />
          ) : (
            ""
          )}
          {status === "info" ? (
            <img src={infoIcon} width="80px" height="80px" />
          ) : (
            ""
          )}
          {status === "error" ? (
            <img src={errorIcon} width="100px" height="80px" />
          ) : (
            ""
          )}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
            {title === null ? status : title}
          </Typography>
          <DialogContentText sx={{ textAlign: "center" }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(isCancellable) ?
            <Button onClick={close}>Close</Button>
            : null}
          <Button onClick={response} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
