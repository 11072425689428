import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import AdminNavbar from '../../components/admin-navbar/AdminNavbar'
import { useNavigate } from 'react-router-dom'
import storageService from '../../service/storageService'

function Admin() {

    const navigate = useNavigate()

    useEffect(() => {
        const user = storageService.getUser()
        // console.log('hello');
        if (!user) {
            navigate('/admin/login')
        }
    })

    return (
        <div style={{ position: 'relative',marginTop:'3rem'}}>
            {(storageService.getUser()) ? <AdminNavbar /> : null}
            <Outlet />
        </div >
    )
}

export default Admin